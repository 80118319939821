<script setup lang="ts">

import { Logger } from '@/logger';
import UserService from '@/service/userService';
import { useNewsStore } from '@/stores/news';
import { NImage, NCarousel, NEllipsis, useMessage } from 'naive-ui'
import { onMounted, ref } from 'vue';




const message = useMessage()
const newsState = useNewsStore()

const fetchNewsData = async () => {
    try {
        if (newsState.loading === false) return // already loaded
        const result = await UserService.getNews()
        newsState.setNews(result.slice(0, 5))
        Logger.debug('news result', result)
    } catch (e:any) {
        message.error(e.message)
    }
}

onMounted(() => {
    fetchNewsData()
})



</script>

<template>
    <div class="three news-carousel">
        <n-carousel
            autoplay
            direction="vertical"
            dot-placement="right"
            style="width: 100%; height: 289px"
        >
            <div class="news-item-wrap" v-for="item in newsState.news" :index="item.id">
                <div class="img-wrap">
                    <div class="post-tag-wrap">
                        <span v-for="tag in item.tag.split(',')" class="post-tag">{{tag}}</span>
                    </div>
                    <!-- <n-image
                        object-fit="cover"
                        src="https://07akioni.oss-cn-beijing.aliyuncs.com/07akioni.jpeg"
                        preview-disabled
                    /> -->
                    <!-- <img src="https://via.placeholder.com/405x230" /> -->
                    <div class="cover-img" :style="`background-image: url('${item.coverImg.split(',')[0]}');`" ></div>
                </div>
                <div class="post-title">
                    <a :href="item.link" target="_blank" class="new-link">
                        <n-ellipsis style="width: 100%">
                            {{item.title}}
                        </n-ellipsis>
                    </a>
                </div>
            </div>
        </n-carousel>
    </div>
</template>

<style scoped lang="scss">
.three {
    .news-item-wrap {
        height: 100%;
    }
    .cover-img {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        width: 405px;
        height: 230px;
    }
    .img-wrap {
        position: relative;
        height: 80%;
        .post-tag-wrap {
            position: absolute;
            top: 20px;
            left: 20px;
            display: flex;
        }
        .post-tag {
            user-select: none;
            background: rgba(255, 255, 255, 0.8);
            border-radius: 10px;
            display: block;
            padding: 10px 15px;
            color: #408CFF;
            margin-right: 5px;
        }
    }
    .post-title {
        height: 20%;
        font-size: 20px;
        text-align: center;
        text-decoration: none;
        color: #333333;
        a {
            height: 100%;
            align-items: center;
            display: flex;
            padding: 10px;
            text-decoration: none;
            color: #333333;
        }
    }
}
</style>
