import { getApiBase } from "@/config"

export default class HttpService {
    private static baseUrl = getApiBase()
    private static token = ""

    private static getToken() {
        if (HttpService.token !== "") {
            return HttpService.token
        }
        const tokenFromLocalDb = window.localStorage.getItem("token")
        if (tokenFromLocalDb && tokenFromLocalDb !== "") {
            HttpService.token = tokenFromLocalDb
        }
        return HttpService.token
    }

    public static resetToken() {
        HttpService.token = ""
    }

    public static resetTokenFromLocalDB() {
        window.localStorage.removeItem("token")
    }

    public static handleTokenExpire(jsonResult: any) {
        if (jsonResult.code === 401) {
            HttpService.resetToken()
            HttpService.resetTokenFromLocalDB()
            window.setTimeout(() => window.location.reload(), 1000)
        }
        return jsonResult
    }

    public static get(url: string, customBase: string = "") {
        const concatUrl = `${customBase ? customBase : HttpService.baseUrl}${url}`
        return fetch(concatUrl, {
            headers: {
                'Content-Type': 'application/json',
                'token': HttpService.getToken()
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
          })
        .then(response => response.json())
        .then(HttpService.handleTokenExpire)
    }

    public static post(url: string, data: any, customBase: string = "") {
        const concatUrl = `${customBase ? customBase : HttpService.baseUrl}${url}`
        return fetch(concatUrl, {
            headers: {
                'Content-Type': 'application/json',
                'token': HttpService.getToken()
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            body: JSON.stringify(data) // body data type must match "Content-Type" header
          })
          .then(response => response.json())
          .then(HttpService.handleTokenExpire)
    }
}