import { onMounted, onUnmounted, ref } from "vue";
import UserService from "@/service/userService";
import { getShortUUID } from "@/utils";
import { getApiBase, parseWechatQueryResult } from "@/config";
import { useRouter } from "vue-router";
import useLoading from "../common/useLoading";

export default function useWechatLogin() {
  const { loading, startLoading, endLoading } = useLoading();
  const result = ref(false); // 是否操作成功
  const router = useRouter();
  const shortUUID = getShortUUID();
  const wechatCodeImg =
    getApiBase() +
    "app/user/generateCodeA?wechatActionType=login&rid=" +
    shortUUID;
  let intervalId: any;

  const loadImgByLink = (link: string) => {
    startLoading();
    var img = document.createElement("img");
    img.src = link;
    img.style.display = "none";
    img.onload = () => {
      endLoading();
    };
    window.document?.getElementById("body")?.appendChild(img);
  };

  const queryWx = async () => {
    const res = await UserService.getScanStatusByRid(shortUUID);
    if (!res.startsWith("success=true")) {
      // cancel if user go to another route
      // if (window.location.href.includes('login')) {
      //   window.setTimeout(queryWx, 1000)
      // }
    } else {
      const result = parseWechatQueryResult(res);
      const token = result.token;

      if (token != "") {
        // auto login
        UserService.loginByToken(token);
        // redirect
        router.push({
          name: "dashboard",
        });
      } else {
        // 用户未注册
        window.$message?.info("您还未注册, 请先填写信息完成注册");
        // todo: 跳转用户到注册页面
        router.push({
          name: "Register",
          state: {
            unionId: result.unionId,
            userImg: result.userImg,
            userName: result.userName,
          },
        });
      }
    }
  };

  const startQuery = () => {
    intervalId = setInterval(queryWx, 1000);
  };
  const stopQuery = () => {
    clearInterval(intervalId);
  };

  onMounted(async () => {
    loadImgByLink(wechatCodeImg);
    await startQuery();
  });

  onUnmounted(() => {
    stopQuery();
  });

  return {
    loading,
    wechatCodeImg,
    result, // 操作结果
    startQuery,
    stopQuery,
  };
}
