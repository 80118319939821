<script lang="ts" setup>
import { onMounted, ref } from "vue";
import { NButton, useMessage, NGrid, NGi, FormRules, FormInst, NInput } from "naive-ui";
import UserService from "@/service/userService";
import { useRouter, RouterLink } from "vue-router";
import { getShortUUID } from "@/utils";
import { getApiBase, parseWechatQueryResult } from "@/config";
import { Logger } from "@/logger";
import SnLoginMarketing from "./SnLoginMarketing.vue";

import { useThemeVars } from "naive-ui";
import useWechatLogin from "@/hooks/business/useWechatLogin";
const themeVars = useThemeVars();
/**
 * State
 */
const loginRule: FormRules = {
  account: {
    required: true,
    message: "请输入正确的账号",
    trigger: "blur",
    pattern: /....../,
  },
  password: {
    required: true,
    message: "请输入正确的密码",
    trigger: "blur",
  },
};
const formValue = ref({
  account: "",
  password: "",
});
const formRef = ref<FormInst | null>(null);

/**
 * Hooks
 */
const { loading, wechatCodeImg } = useWechatLogin();
const loginLoading = ref(false);
const router = useRouter();

onMounted(() => {
  // login first message maybe
  const state = router.options.history.state
  if (state.message && state.message !== '') {
    window.$message?.info(state.message as string)
    Logger.debug('from', state.fromUrl)
  }
})

/** Handler */
const onRegisterClick = (e: any) => {
  e.preventDefault()
  // redirect
  router.push({
    name: "Register",
    state: {...router.options.history.state}
  })
}

const handleValidateClick = (e: any) => {
  console.log("form value", formValue.value);
  console.log("click");
  e.preventDefault();
  formRef.value?.validate((errors) => {
    console.log("validate result");
    if (!errors) {
      handleLogin();
    } else {
      console.log(errors);
    }
  });
};

const handleLogin = async () => {
  try {
    loginLoading.value = true;
    // login
    await UserService.login(formValue.value.account, formValue.value.password);
    loginLoading.value = false;
    window.$message?.success("登录成功");
    if (router.options.history.state.fromUrl) {
      // redirect if needed // todo: use ?redirect=xxx query
      router.push({
        path: router.options.history.state.fromUrl as string,
        query: router.options.history.state.query as any,
      });
    } else {
      // to dashboard
      router.push({
        name: "dashboard",
      });
    }
  } catch (e: any) {
    window.$message?.error(e.message);
    loginLoading.value = false;
  }
};
</script>

<template>
  <div class="snLogin__form">
    <n-tabs
      class="card-tabs"
      default-value="code"
      size="large"
      animated
      style="margin: 0 -4px"
      pane-style="padding-left: 4px; padding-right: 4px; box-sizing: border-box;"
    >
      <n-tab-pane name="code" tab="扫码登录">
        <div class="snLogin__code">
          <n-skeleton
            v-if="loading"
            height="240px"
            width="240px"
            style="margin: 0 auto"
          />
          <n-image
            v-else
            width="240"
            height="240"
            :src="wechatCodeImg"
            preview-disabled
          />
        </div>
        <div class="snLogin__codeDes" style="text-align: center">
          {{
            loading
              ? "努力加载中..."
              : "请用微信扫上方二维码，扫码后在手机上点击“确认登陆”即可 "
          }}
        </div>
      </n-tab-pane>
      <n-tab-pane name="password" tab="密码登录">
        <n-form
          ref="formRef"
          :show-label="false"
          :model="formValue"
          :rules="loginRule"
        >
          <n-form-item-row path="account">
            <n-input
              v-model:value="formValue.account"
              placeholder="手机号/邮箱"
              @keyup.enter.native="handleValidateClick"
            />
          </n-form-item-row>
          <n-form-item-row path="password">
            <n-input
              v-model:value="formValue.password"
              type="password"
              @keyup.enter.native="handleValidateClick"
              placeholder="请输入登陆密码"
            />
          </n-form-item-row>
        </n-form>
        <div
          class="snLogin__actions"
          :style="{ color: themeVars.primaryColor }"
        >
          <a @click="onRegisterClick" href="/register" class="snLogin__registerBtn">
            注册账号
          </a>
          <RouterLink to="/forget" class="snlogin__forgetPassBtn">
            忘记密码
          </RouterLink>
        </div>
        <n-button
          type="primary"
          :color="themeVars.primaryColor"
          block
          strong
          :disable="loginLoading"
          :loading="loginLoading"
          @keyup.enter.native="handleValidateClick"
          @click="handleValidateClick"
        >
          登录
        </n-button>
      </n-tab-pane>
    </n-tabs>
  </div>
</template>

<style lang="scss" scoped>
@import "./SnOverwriteFormStyle.scss";

.snLogin__form {
  width: 550px;
  background: #ffffff;
  box-shadow: 0px 15px 50px rgb(12 51 109 / 6%);
  border-radius: 10px;
  height: 520px;
}
.snLogin__codeDes {
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  width: 196px;
  margin: 0 auto;
}
.snLogin__code {
  text-align: center;
  margin-bottom: 22px;
}
.snLogin__actions {
  text-align: right;
  margin-bottom: 50px;
}
.snLogin__registerBtn {
  margin-right: 20px;
}
</style>
