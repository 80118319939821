import { computed, ref } from "vue";
import useLoading from "../common/useLoading";

export default function useImgPreload(src: string) {
  const { loading, startLoading, endLoading } = useLoading(true);
  const imgUrl = ref('')

  // startLoading();
  // var xhr = new XMLHttpRequest();
  // xhr.open("GET", src);
  // xhr.responseType = "blob";
  // xhr.onload = (response: any) => {
  //   const urlCreator = window.URL || window.webkitURL;
  //   const imageUrl = urlCreator.createObjectURL(response);
  //   imgUrl.value = imageUrl
  //   endLoading()
  // };
  // xhr.send();

  /*
   below is deprecated as some image do not support cache headers
   */
  const img = document.createElement("img");
  img.style.display = "none";
  img.onload = () => {
    endLoading();
  };
  img.onerror = () => {
    endLoading();
  }
  img.onabort = () => {
    endLoading();
  }
  img.src = src; // auto download when append
  window.document?.getElementById("body")?.appendChild(img);

  return {
    loading,
    imgUrl
  };
}
