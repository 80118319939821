<script lang="ts" setup>

import NoCrmInit from "@/views/crm/NoCrmInit.vue";
import {useWebsiteStore} from "@/stores/website";
import {useRouter} from "vue-router";
import FieldMapping from "@/views/crm/FieldMapping.vue";
import {computed, ref, ComputedRef} from "vue";
import CrmService, {IMappingConfig} from "@/service/CrmService";
import {useCrmStore} from "@/stores/crm";
import {NModal, useMessage} from 'naive-ui'
import {getSiteLetterByDomain} from '@/util/common';
const updating = ref(false)

const websiteStore = useWebsiteStore()
const router = useRouter()
const currentSelectedSiteId = ref<number | null>(null)
const showModal = ref(false)
const message = useMessage()

const crmStore = useCrmStore()

const onAddNewClick = () => {
  router.push('/crm/new')
}

const onUpdateClick = async () => {
  updating.value = true
  try {
    await CrmService.updateMapping(crmStore.initSiteId, crmStore.initPlatformToken, crmStore.configModel)
    message.success("更新成功")
    websiteStore.updateMapping(crmStore.initSiteId as number, crmStore.configModel);
    showModal.value = false
    currentSelectedSiteId.value = null
    crmStore.reset()
  } catch (e: any) {
    message.error(e.message)
  }
  updating.value = false
}

const onModalUpdate = (isShow: boolean) => {
  console.log('is show', isShow)
  if (isShow === false) {
    currentSelectedSiteId.value = null
    crmStore.reset()
  }
}

const onMappingClick = (blogId: number, initKey: string | undefined, tenant: string | undefined) => {
  currentSelectedSiteId.value = blogId
  showModal.value = true
  crmStore.initSiteId = blogId
  crmStore.initPlatformToken = initKey as string
  crmStore.initCrmTenant = tenant as string
}

const mappingForCurrentSeleted: ComputedRef<IMappingConfig[]> | ComputedRef<null> = computed(() => {
  if (currentSelectedSiteId.value === null) return null;
  const match = websiteStore.getValidIntegrations().filter((item) => item.blogId === currentSelectedSiteId.value)
  return JSON.parse(match[0].initConfig as string)
})

const scrollMaxHeight = computed(() => {
  return window.innerHeight - 150;
})

</script>

<template>
  <NoCrmInit v-if="websiteStore.getValidIntegrations().length === 0"></NoCrmInit>
  <template v-else>
    <div class="add-new-button-wrap">
      <n-button @click="onAddNewClick" type="primary">新增</n-button>
    </div>
    <div class="init-wrap">
      <n-grid x-gap="12" :cols="4">
        <n-gi v-for="item in websiteStore.getValidIntegrations()" :key="item.blogId">
          <div class="init-item">
            <div class="favicon-wrap">
              <n-image
                  width="80"
                  v-if="item.customfavicon || item.favicon"
                  :src="item.customfavicon || item.favicon"
                  preview-disabled
              />
              <span v-else class="site-letter" style="font-size: 50px; user-select: none; font-weight: bold; color: #408cff;">{{getSiteLetterByDomain(item.domain)}}</span>
            </div>
            <div class="website-name-wrap">
              <h2>{{item.name}}</h2>
            </div>
            <div class="init-arrow-wrap">
              {{ item.customDomain || item.domain }} -> {{ item.initTenantCompany }}
            </div>
            <div class="cta-wrap">
              <div class="detail-btn-wrap">
                <n-popover trigger="hover">
                  <template #trigger>
                    <n-button >
                      查看详情
                    </n-button>
                  </template>
                  <span>该功能暂未开放</span>
                </n-popover>
              </div>
              <div class="mapping-btn-map">
                <n-button @click="onMappingClick(item.blogId, item.initKey, item.initTenant)">
                  字段映射
                </n-button>
              </div>
            </div>
          </div>
        </n-gi>
      </n-grid>
      <template v-if="currentSelectedSiteId !== null">
        <n-modal v-model:show="showModal" @update:show="onModalUpdate">
            <div class="field-update-form-wrap">
              <n-scrollbar :style="`max-height: ${scrollMaxHeight}px`">
                <FieldMapping :config="mappingForCurrentSeleted" />
              </n-scrollbar>
              <n-button type="primary" v-if="crmStore.configModel.length > 0" @click="onUpdateClick" :loading="updating">更新</n-button>
            </div>
        </n-modal>
      </template>
    </div>
  </template>
</template>

<style lang="scss" scoped>
.field-update-form-wrap {
  background: white;
  padding: 20px;
  width: 650px;
}
.init-wrap {
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  border: 1px solid #eaedf9;
}
.add-new-button-wrap {
  text-align: right;
  margin-bottom: 10px;
  padding-top: 10px;
}
.init-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.favicon-wrap {
  border-radius: 50%;
  overflow: hidden;
  height: 80px;
}

.cta-wrap {
  display: flex;
  /* justify-content: space-evenly; */
  /* width: 100%; */
  padding: 15px 0;
}
.detail-btn-wrap {
  margin-right: 10px;
}
.init-arrow-wrap {
  padding-top: 10px;
}
.website-name-wrap {
  padding-top: 10px;
  font-weight: bold;
}
.init-item {
  border: 1px solid #ebedf9;
  border-radius: 10px;
  padding: 20px 0;
}
</style>
