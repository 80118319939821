import { getWpBase } from "@/config"
import HttpService from "./httpService"
import UserService from "@/service/userService";

export interface ICrmField {
    field: string,
    label: string,
    required: boolean
}

export interface IMappingConfig {
    fromField: null | string,
    toField: string
}

export interface IWebsiteField {
    field: string,
    label: string,
    isSystemField: boolean, // special field like @ip etc
    disabled: boolean
}

export default class CrmService {

    public static crmBaseLink = 'https://crmapi.msupernova.com';

    public static async setupIntegration(siteId: string | number | null, tenantId: string, platformKey: string, mappingConfig: IMappingConfig[], tenantCompanyName: string) {
        const url = "app/crm/init"
        const res = await HttpService.post(`${url}?siteId=${siteId}&tenantId=${tenantId}&platformKey=${platformKey}&tenantCompanyName=${tenantCompanyName}`, mappingConfig)
        if (res.code !== 0) {
            throw new Error(res.msg)
        }
        return res.data
    }

    public static async updateMapping(siteId: string | number | null, platformKey: string, mappingConfig: IMappingConfig[]) {
        const url = "app/crm/updateInit"
        const res = await HttpService.post(`${url}?siteId=${siteId}&platformKey=${platformKey}`, mappingConfig)
        if (res.code !== 0) {
            throw new Error(res.msg)
        }
        return res.data
    }


    public static async authCrm(email: string, password: string) {
        // const mockResult = {
        //     tenantCompanyName: '星际种子',
        //     tenantPlan: '基本套餐',
        //     tenantValueTill: '2022-12-12 12:22:33',
        //     token: 'fijwrofjoerjfooo',
        //     tenant: 'ffjeirjfoejrfojerogj'
        // }
        const url = `${CrmService.crmBaseLink}/api/uni/getPlatformKey`

        const result:any = await CrmService.fetchJson(url, {
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              email: email,
              password: password
            }),
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
        })

        if (result.error !== '') {
            throw new Error(result.error)
        }

        console.log('json result', result)
        return result.data;
    }

    public static fetchJson(url: string, data: any) {
        return new Promise((resolve, reject) => {
            fetch(url, data)
                .then(response => response.json()
                .then(jsonRes => resolve(jsonRes)))
        })
    }

    public static async getCrmFields(tenant: string, token: string): Promise<ICrmField[]> {
        const mockData : ICrmField[] = [
            {
                field: 'fjiwejfif',
                label: '客户',
                required: true
            },{
                field: 'fjiwejffi',
                label: '客户来源',
                required: true
            },{
                field: 'fjiwejfssi',
                label: '客户行业',
                required: false
            }
        ]

        const url = `${CrmService.crmBaseLink}/tenant/${tenant}/api/tenant/getCrmFields`

        const result:any = await CrmService.fetchJson(url, {
            headers: {
                'Content-Type': 'application/json',
            },
            // body: JSON.stringify({
            //     email: email,
            //     password: password
            // }),
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
        })

        if (result.error !== '') {
            throw new Error(result.error)
        }

        console.log('crm fields', result)
        const data:ICrmField[] = result.data.map((item: any) => ({
            ...item[0],
            field: item[0]['field'],
            label: item[0]['name'],
            required: item[0]['is_null'] === 1
        }))
        return data;

        // return CrmService.returnMockData<ICrmField[]>(mockData)
    }

    public static async getWebsiteFields(websiteId: number): Promise<IWebsiteField[]> {
        const result:any = await UserService.leadsPlatform(websiteId)
        console.log('result: parsing website fields', result)

        let data: IWebsiteField[] = [{
            field: '表单字段',
            label: '表单字段',
            isSystemField: false,
            disabled: true
        }]
        const keysCache: string[] = []

        for (const item of result.data) {
            for (const fieldRow of item['form']['formData']) {
                if (!keysCache.includes(fieldRow['label'])) {
                    data.push({
                        field: fieldRow['label'],
                        label: fieldRow['label'],
                        isSystemField: false,
                        disabled: false
                    })
                    keysCache.push(fieldRow['label'])
                }
            }
        }

        data.push({
            field: '系统字段',
            label: '系统字段',
            isSystemField: false,
            disabled: true
        })

        data = data.concat([{
            field: '@source_url',
            label: '来源页面',
            isSystemField: false,
            disabled: false
        }, {
            field: '@device',
            label: '设备',
            isSystemField: false,
            disabled: false
        },{
            field: '@browser',
            label: '浏览器',
            isSystemField: false,
            disabled: false
        },{
            field: '@os',
            label: '操作系统',
            isSystemField: false,
            disabled: false
        }, {
            field: '@country',
            label: '国家',
            isSystemField: false,
            disabled: false
        },{
            field: '@ip',
            label: 'IP地址',
            isSystemField: false,
            disabled: false
        }])

        data.push({
            field: '固定字符',
            label: '固定字符',
            isSystemField: false,
            disabled: true
        })

        data = data.concat([{
            field: '官网',
            label: '官网',
            isSystemField: false,
            disabled: false
        }, {
            field: '网站',
            label: '网站',
            isSystemField: false,
            disabled: false
        }])

        // todo: append more fields
        return data


        // return CrmService.returnMockData<IWebsiteField[]>(mockData)
    }

    public static returnMockData<T>(data: T): Promise<T> {
        return new Promise((resolve, reject) => {
            window.setTimeout(() => resolve(data), 1000)
        })
    }
}
