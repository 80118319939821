<script setup lang="ts">

import { Logger } from '@/logger';
import UserService from '@/service/userService';
import { useWebsiteStore } from '@/stores/website';
import { useMessage, NPopover } from 'naive-ui'
import { computed, onMounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import {getSiteLetterByDomain} from '@/util/common';


/**
 * State
 */
const websiteState = useWebsiteStore()

const isCreating = computed(() => {
    Logger.debug('route', 'router.currentRoute.value')
    Logger.debug('route', router.currentRoute.value)
    return router.currentRoute.value.fullPath.startsWith("/create")
})

/**
 * Hooks
 */
const message = useMessage()
const router = useRouter()
const route = useRoute()

const goTo = (routerName: string) => {
    router.push({
        name: routerName
    })
}

const fetchUserWebsites = async () => {
    try {
        const websites = await UserService.getMyWebsites()
        websiteState.setWebsites(websites)

        const numberOfSites = websites.length
        const dom = window.document.querySelector(".site-wrap .ps") as HTMLElement
        if (dom !== null) {
            dom.style.height= `${numberOfSites * 70}px`
        }

    } catch (e: any) {
        message.error(e.message)
    }
}

const onSiteSwitch = (sid: number) => {
    router.push({
        name: "siteDashboard",
        params: {
            sid,
        }
    })
}

const isCopyView = ref<boolean>(false)

onMounted(() => {
    fetchUserWebsites()
    if (window.document.location.pathname.startsWith('/copy')) {
        isCopyView.value = true
    } else {
        isCopyView.value = false
    }
})

watch(() => route.name, () => {
    if (window.document.location.pathname.startsWith('/copy')) {
        isCopyView.value = true
    } else {
        isCopyView.value = false
    }
});

</script>

<template>
    <aside class="sidebar">
        <a href="#" class="platform-logo" @click.prevent="goTo('dashboard')">
            <img src="https://st-img-md.oss-cn-guangzhou.aliyuncs.com/超新星logo.jpg" />
        </a>
        <div class="site-wrap">
            <perfect-scrollbar ref="scrollbar">
                <!-- <a href="#" class="site-item active">
                  <img src="https://st-img-md.oss-cn-guangzhou.aliyuncs.com/Vector (3).png" alt="">
                </a>-->

                <template v-if="websiteState.loading">
                    <!-- <n-skeleton round height="88px" width="88px" style="margin: 0 auto; margin-bottom: 50px;"/>
                  <n-skeleton round height="88px" width="88px" style="margin: 0 auto; margin-bottom: 50px"/>
                    <n-skeleton round height="88px" width="88px" style="margin: 0 auto; margin-bottom: 50px"/>-->
                </template>
                <template v-else>
                    <n-popover
                        :delay="500"
                        :duration="0"
                        placement="right"
                        trigger="hover"
                        v-for="(item, index) in websiteState.websites"
                        :key="index"
                    >
                        <template #trigger>
                            <div
                                class="site-item pointer"
                                :class="(item.blogId === websiteState.currentWebsiteId && !isCreating && !isCopyView) ? 'active' : ''"
                                @click="onSiteSwitch(item.blogId)"
                            >
                                <!-- <img
                                    src="https://st-img-md.oss-cn-guangzhou.aliyuncs.com/Vector (3).png"
                                /> -->
                                <img
                                    v-if="item.customfavicon || item.favicon"
                                    style="max-width: 30px;" :src="item.customfavicon || item.favicon"
                                />
                                <span v-else class="site-letter" style="font-size: 20px; user-select: none; font-weight: bold; color: #408cff;">{{getSiteLetterByDomain(item.domain)}}</span>
                            </div>
                        </template>
                        <div class="site-popover-wrap">
                            <div class="sitename">{{ item.name }}</div>
                            <div class="siteurl">{{ item.customDomain ? `https://${item.customDomain}` : item.siteurl }}</div>
                        </div>
                    </n-popover>
                </template>
            </perfect-scrollbar>
        </div>
        <a href="#" :class="`add-site-wrap ${isCreating ? 'active' : ''}`" @click.prevent="goTo('create')">
            <div class="add-site-img-wrap">
                <img src="https://st-img-md.oss-cn-guangzhou.aliyuncs.com/Vector (2).png" />
            </div>
        </a>
    </aside>
</template>

<style scoped lang="scss">

.sidebar :deep(.ps) {
    .ps__thumb-y {
        width: 5px;
    }
    .ps__rail-y {
        width: 5px;
    }
    .ps__rail-y:hover {
        background-color: #d7e5ff;
    }
    .ps__rail-y.ps--clicking {
        background-color: #d7e5ff;
    }
    .ps__thumb-y {
        background-color: #408cff;
        right: 0px;
    }

    .ps__rail-y.ps--clicking .ps__thumb-y {
        background-color: #408cff;
    }
}
.sidebar {
    flex-shrink: 0;
    flex-grow: 0;
    background-color: white;
    width: 70px;
    border-right: 1px solid rgb(228 228 228 / 50%);
    border-right: 0;

    .platform-logo {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 60px;
        border-bottom: 1px solid #5276d021;
        img {
            max-width: 35px;
        }
    }
    .site-wrap {
        padding: 30px 0px;
        padding-bottom: 15px;
        .ps {
            max-height: 45vh;
            border-bottom: 1px solid #edf4ff;
        }
    }
    .site-item {
        width: 40px;
        height: 40px;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
        margin-left: auto;
        margin-right: auto;
        border: 2px solid #edf4ff;
        overflow: hidden;
        transition: all .3s;
        cursor: pointer;
        &.active, &:hover {
            background: rgba(75, 147, 254, 0.1);
            border: 2px solid #408cff61;
        }
    }
    .add-site-wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        .add-site-img-wrap {
            border-radius: 15px;
            transition: all .3s;
        }
        &.active, &:hover {
            .add-site-img-wrap {
                background-color: #e8f1fe;
                border-radius: 15px;
            }
        }

        .add-site-img-wrap {
            width: 50px;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: 28px;
                height: auto;
            }
        }

    }


}
</style>
