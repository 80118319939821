import { ITemplateInfo } from '@/service/siteService'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { ref } from 'vue'

export const useTemplateStore = defineStore('template', () => {
    const defaultTemplateList: ITemplateInfo[] = []

    /**
     * Reative Objects
     */
    const templates = ref<ITemplateInfo[]>(defaultTemplateList)
    const templateCates = ref<any>()
    const loading = ref(true)

    /**
     * Actions
     */
    const setTemplates = (payload: ITemplateInfo[]) => {
        templates.value = payload
        loading.value = false
    }

    const setTemplateCates = (payload: any) => {
        templateCates.value = payload
    }

    /**
     * Expose State API
     */
    return {
        loading,
        templates,
        templateCates,
        setTemplates,
        setTemplateCates
    }
})
