import { acceptHMRUpdate, defineStore } from 'pinia'
import { ref } from 'vue'

export const useNotificationStore = defineStore('notification', () => {

    /**
     * Reative Objects
     */
    const notification = ref<any[]>([])
    const loading = ref(true)

    /**
     * Actions
     */
    const setNotification = (payload: any[]) => {
        notification.value = payload
        loading.value = false
    }

    /**
     * Expose State API
     */
    return {
        loading,
        notification,
        setNotification
    }
})
