  <script lang="ts" setup>
import { onMounted, reactive, ref, toRefs } from "vue";
import {
  NButton,
  useMessage,
  NGrid,
  NGi,
  FormRules,
  FormInst,
  NSpace,
} from "naive-ui";
import UserService from "@/service/userService";
import { useRouter, RouterLink } from "vue-router";
import { getShortUUID } from "@/utils";
import { getApiBase, parseWechatQueryResult } from "@/config";
import { Logger } from "@/logger";
import SnLoginMarketing from "./SnLoginMarketing.vue";
import useSmsCode from "@/hooks/business/useSmsCode";
import { formRules, getConfirmPwdRule } from "@/util/form";

import { useThemeVars } from "naive-ui";
const themeVars = useThemeVars();
/**
 * State
 */
const { label, isCounting, loading: smsLoading, getSmsCode } = useSmsCode();
const loadingForReset = ref(false);
const resetSuccess = ref(false);
const router = useRouter();

const goBack = () => {
  router.push("/login");
};

const formValue = reactive({
  phone: "",
  phoneCode: "",
  password: "",
  confirmPwd: "",
});
const forgetRule: FormRules = {
  phone: formRules.phone,
  phoneCode: formRules.code,
  password: formRules.pwd,
  confirmPwd: getConfirmPwdRule(toRefs(formValue).password),
};

const formRef = ref<FormInst | null>(null);
const handleSmsCode = () => {
  getSmsCode(formValue.phone);
};

/**
 * Hooks
 */

/** Handler */
const handleReset = async () => {
  try {
    // reset
    loadingForReset.value = true;
    const result = await UserService.resetPass(
      formValue.phone,
      formValue.phoneCode,
      formValue.password
    );
    loadingForReset.value = false;

    resetSuccess.value = true;
    // delay one second to show reset success msg
    window.setTimeout(async () => {
      await UserService.login(formValue.phone, formValue.password);
      window.$message?.success("登录成功");
      // redirect
      router.push({
        name: "dashboard",
      });
      // todo: auto login
    }, 1000);
  } catch (e: any) {
    window.$message?.error(e.message);
    loadingForReset.value = false;
  }
};

function handleSubmit(e: any) {
  if (!formRef.value) return;
  e.preventDefault();

  formRef.value.validate((errors) => {
    if (!errors) {
      handleReset();
    } else {
      // window.$message?.error("验证失败!");
    }
  });
}
</script>

<template>
  <div class="snForget__form">
    <n-tabs
      class="card-tabs"
      default-value="forget"
      size="large"
      animated
      style="margin: 0 -4px"
      pane-style="padding-left: 4px; padding-right: 4px; box-sizing: border-box;"
    >
      <n-tab-pane name="forget" tab="忘记密码">
        <template v-if="!resetSuccess">
          <n-form
            ref="formRef"
            :show-label="false"
            :model="formValue"
            :rules="forgetRule"
          >
            <n-form-item path="phone">
              <n-input
                v-model:value="formValue.phone"
                placeholder="手机号码"
                @keyup.enter.native="handleSubmit"
              />
            </n-form-item>
            <n-form-item path="phoneCode">
              <div class="flex-y-center w-full">
                <n-input
                  v-model:value="formValue.phoneCode"
                  placeholder="验证码"
                  @keyup.enter.native="handleSubmit"
                />
                <div class="w-18px"></div>
                <n-button
                  size="large"
                  :disabled="isCounting"
                  :loading="smsLoading"
                  @click="handleSmsCode"
                >
                  {{ label }}
                </n-button>
              </div>
            </n-form-item>
            <n-form-item path="password">
              <n-input
                type="password"
                show-password-on="mousedown"
                v-model:value="formValue.password"
                @keyup.enter.native="handleSubmit"
                placeholder="密码"
              />
            </n-form-item>
            <n-form-item path="confirmPwd">
              <n-input
                type="password"
                show-password-on="mousedown"
                @keyup.enter.native="handleSubmit"
                v-model:value="formValue.confirmPwd"
                placeholder="确认密码"
              />
            </n-form-item>
          </n-form>
          <n-space
            class="snForget__ctaWrap"
            :vertical="true"
            :size="18"
            justify="center"
          >
            <n-button
              type="primary"
              size="large"
              :block="true"
              :round="true"
              :loading="loadingForReset"
              :disabled="loadingForReset"
              @keyup.enter.native="handleSubmit"
              @click="handleSubmit"
              >确认</n-button
            >
            <n-button text @click="goBack" :disabled="loadingForReset">
              &lt; 返回登录入口</n-button
            >
          </n-space>
        </template>
        <template v-else>
          <div class="reset-succuss" v-if="resetSuccess">
            <div class="reset-img-wrap">
              <img
                src="https://st-img-md.oss-cn-guangzhou.aliyuncs.com/20220209135634.png"
                alt=""
              />
            </div>
            <div class="reset-msg">密码修改成功！自动跳转中...</div>
          </div>
        </template>
      </n-tab-pane>
    </n-tabs>
  </div>
</template>

<style lang="scss" scoped>
@import "./SnOverwriteFormStyle.scss";
.reset-succuss {
  text-align: center;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 40px;
  .reset-img-wrap {
    margin: 0 auto;
  }
  .reset-msg {
    padding-top: 20px;
  }
}

.snForget__form {
  width: 550px;
  background: #ffffff;
  box-shadow: 0px 15px 50px rgb(12 51 109 / 6%);
  border-radius: 10px;
  height: 520px;
}

.snForget__ctaWrap {
  text-align: center;
}

.flex-y-center.w-full {
  display: flex;
  width: 100%;
}

.w-18px {
  margin-right: 18px;
}
</style>
