import {
  createRouter,
  createWebHistory,
  RouteLocationNormalized,
  RouteRecordRaw,
} from "vue-router";
import Home from "@/views/Home.vue";
import WpLogin from "@/views/WpLogin.vue";
import Invitation from "@/views/Invitation.vue";
import Register from "@/views/Register.vue";
import Forget from "@/views/Forget.vue";
import Dashboard from "@/views/Dashboard.vue";
import Leads from "@/views/Leads.vue";
import CreateSite from "@/views/CreateSite.vue";
import New from "@/views/New.vue";
import Copy from "@/views/Copy.vue";
import NProgress from "nprogress";
import LoginView from "@/views/loginView/LoginView.vue";
import Login from "@/views/loginView/Login.vue";
import RegisterView from "@/views/loginView/RegisterView.vue";
import ForgetView from "@/views/loginView/ForgetView.vue";
import IndexView from "@/views/loginView/index.vue";
import Learning from "@/views/learning.vue";
import Academy from "@/views/Academy.vue";
import CourseDetail from "@/views/CourseDetail.vue";
import CrmIndex from "@/views/crm/CrmIndex.vue";
import CrmNew from "@/views/crm/CrmNew.vue";
import NoCrmInit from "@/views/crm/NoCrmInit.vue";
import CrmPanel from "@/views/crm/CrmPanel.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/invitation/:token",
    name: "Invitation",
    component: Invitation,
  },
  {
    path: "/snlogin/:siteId",
    name: "WpLogin",
    component: WpLogin,
    beforeEnter: (to, from, next) => {
      // let token = Vue.cookie.get('token')
      const token = localStorage.getItem("token");
      if (!token || token === "") {
        next({ name: "Login" });
      }
      next();
    },
  },
  {
    path: "/login-parent",
    // name: "Login",
    component: IndexView,
    // component: Login,
    beforeEnter: () => {
      const token = localStorage.getItem("token");
      if (token) {
        router.push({
          name: "dashboard",
        });
      }
    },
    children: [
      {
        path: `/login-parent2`,
        redirect: `/login`,
        props: (to: RouteLocationNormalized): Record<string, any> => {
          if (to.path === `/login`) {
            return {
              viewName: "snLogin",
            };
          } else if (to.path === `/forget`) {
            return {
              viewName: "snForget",
            };
          }
          return {};
        },
        component: LoginView,
        children: [
          {
            name: "Login",
            path: `/login`,
            component: Login,
          },
          {
            name: "Forget",
            path: `/forget`,
            component: ForgetView,
          },
        ],
      },
      {
        name: "Register",
        path: `/register`,
        component: RegisterView,
      },
    ],
  },
  // {
  //   path: "/register",
  //   name: "Register",
  //   component: Register,
  //   beforeEnter: () => {
  //     const token = localStorage.getItem("token")
  //     if (token && token !== "") {
  //       router.push({
  //         name: "Home"
  //       })
  //     }
  //   }
  // },
  // {
  //   path: "/forget",
  //   name: "Forget",
  //   component: Forget,
  //   beforeEnter: () => {
  //     const token = localStorage.getItem("token")
  //     if (token && token !== "") {
  //       router.push({
  //         name: "Home"
  //       })
  //     }
  //   }
  // },
  {
    path: "/",
    name: "Home",
    component: Home,
    beforeEnter: (to, from, next) => {
      // let token = Vue.cookie.get('token')
      const token = localStorage.getItem("token");
      if (!token || token === "") {
        // because if current page is login, msg in state will not show
        if (from.name === "Login") {
          window.$message?.info("请先登录");
        }
        router.push({
          name: "Login",
          state: {
            message: "请先登录",
            fromUrl: to.fullPath,
            query: to.query,
          },
        });
        // next({ name: 'Login' })
      }
      next();
    },
    children: [
      {
        path: "/contentful",
        name: "Copy",
        component: Copy,
      },
      {
        path: "/sacademy",
        name: "Academy",
        component: Academy,
      },
      {
        path: 'crm',
        name: 'Crm',
        component: CrmIndex,
        children: [
          {
            path: '',
            name: 'CrmPanel',
            component: CrmPanel
          },
          {
            path: 'new',
            name: 'CrmNew',
            component: CrmNew
          }
        ]
      },
      {
        path: "/sacademy/:courseId",
        name: "CourseDetail",
        props: true,
        component: CourseDetail,
      },
      {
        path: "/sacademy/:courseId/:lessonId",
        name: "Learning",
        props: true,
        component: Learning,
        meta: {
          notes: "课程详情页",
        },
      },
      {
        path: "/new",
        name: "New",
        component: New,
      },
      {
        path: "/create",
        name: "create",
        component: CreateSite,
      },
      {
        path: "/create/:step",
        name: "createStep",
        component: CreateSite,
      },
      {
        path: "/profile",
        name: "Profile",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "profile" */ "@/views/Profile.vue"),
      },
      {
        path: "/order-management",
        name: "OrderManagement",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "order" */ "@/views/OrderManagement.vue"),
      },
      {
        path: "/payment-success",
        name: "PaymentSuccess",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "paymentsuccess" */ "@/views/PaymentSuccess.vue"
          ),
      },
      {
        path: "/site/:sid",
        name: "siteDashboard",
        component: Dashboard,
      },
      {
        path: "/leads/:sid",
        name: "leads",
        component: Leads,
      },
      {
        path: "/",
        name: "dashboard",
        component: Dashboard,
      },
    ],
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/About.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/About.vue"),
    name: "404",
    meta: { title: "404未找到" },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(() => {
  NProgress.start();
});
router.afterEach((to, from, err) => {
  NProgress.done();
});

export default router;
