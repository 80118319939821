import HttpService from "./httpService"

export default class ProuductService {
    public static async getAllProducts() {
        const url = "app/products/list"
        const res = await HttpService.get(url)
        if (res.code !== 0) {
            throw new Error(res.msg) 
        }
        return res.data
    }
}