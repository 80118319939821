import { acceptHMRUpdate, defineStore } from 'pinia'
import { ref } from 'vue'
import * as Sentry from "@sentry/vue";

interface IUser {
    name: string,
    avatar: string,
    email: string,
    phone: string,
}

export const useUserStore = defineStore('user', () => {
    const defaultUser: IUser = {
        name: "Guest",
        avatar: "https://st-img-md.oss-cn-guangzhou.aliyuncs.com/超新星logo.jpg",
        email: "",
        phone: ""
    }

    /**
     * Reative Objects
     */
    const user = ref<IUser>(defaultUser)
    const isWechatBind = ref(false)
    const loading = ref(true)

    /**
     * Actions
     */
    const setUser = (payload: IUser) => {
        user.value = payload
        loading.value = false
        Sentry.configureScope((scope: any) => {
            scope.setUser({
              ...payload  
            })
        })
    }
    const resetForLogout = () => {
        user.value = defaultUser
        loading.value = true
        Sentry.configureScope((scope: any) => {
            scope.setUser(null)
        })
    }
    const setIsWechatBind = (isBind: boolean) => {
        isWechatBind.value = isBind
    }
    const getLoading = () => {
        return loading.value
    }
    const getIsWechatBind = () => {
        return isWechatBind.value
    }

    /**
     * Expose State API
     */
    return {
        user,
        loading,
        getLoading,
        isWechatBind,
        getIsWechatBind,
        setIsWechatBind,
        setUser,
        resetForLogout
    }
})
