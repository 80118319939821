<script lang="ts" setup>
import { computed, onMounted, ref, watch } from "vue";
import { NButton, useMessage, NGrid, NGi, FormRules, FormInst } from "naive-ui";
import UserService from "@/service/userService";
import { useRouter, RouterLink, useRoute } from "vue-router";
import { getShortUUID } from "@/utils";
import { getApiBase, parseWechatQueryResult } from "@/config";
import { Logger } from "@/logger";
import SnLoginMarketing from "./SnLoginMarketing.vue";
import LoginView from "@/views/loginView/LoginView.vue";
import RegisterView from "@/views/loginView/RegisterView.vue";
import ForgetView from "@/views/loginView/ForgetView.vue";
import { useThemeVars } from "naive-ui";
import useMarketing from "@/hooks/business/useMarketing";
const themeVars = useThemeVars();
const route = useRoute();
/**
 * State
 */
const { marketingOjb, refreshMaketing } = useMarketing();
const loginRule: FormRules = {
  account: {
    required: true,
    message: "请输入正确的账号",
    trigger: "blur",
    pattern: /....../,
  },
  password: {
    required: true,
    message: "请输入正确的密码",
    trigger: "blur",
  },
};
const formValue = ref({
  account: "",
  password: "",
});
const formRef = ref<FormInst | null>(null);

/**
 * Hooks
 */

/** Handler */
const handleValidateClick = (e: MouseEvent) => {
  console.log("form value", formValue.value);
  console.log("click");
  e.preventDefault();
  formRef.value?.validate((errors) => {
    console.log("validate result");
    if (!errors) {
      console.log("## success ##");
    } else {
      console.log(errors);
    }
  });
};

const currentRouteName: any = ref("LoginView");
watch(
  () => route.name,
  () => {
    console.log("route change", route.name);
    currentRouteName.value = route.name;
  }
);
onMounted(() => {
  refreshMaketing();
  currentRouteName.value = route.name;
});
const renderComponent = computed(() => {
  switch (currentRouteName.value) {
    case "LoginView":
      return LoginView;
    case "RegisterView":
      return RegisterView;
    case "ForgetView":
      return ForgetView;
    default:
      return LoginView;
  }
});
</script>

<template>
  <header class="snHeader">
    <div class="snHeader__logo">
      <router-link to="/login">
        <img
          src="https://st-img-md.oss-cn-guangzhou.aliyuncs.com/SuperNova Logo 2020-02.png"
          alt=""
        />
      </router-link>
    </div>
  </header>
  <section
    class="snMain"
    :style="
      marketingOjb.backgroundImg !== ''
        ? `background-image: url(${marketingOjb.backgroundImg});`
        : ''
    "
  >
    <router-view></router-view>
    <!-- <component :is="renderComponent" /> -->
  </section>
</template>

<style lang="scss" scoped>
.snHeader {
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  box-shadow: 0px 15px 15px rgb(60 137 255 / 5%);
}

.snMain {
  //   background-image: url(https://st-img-md.oss-cn-guangzhou.aliyuncs.com/20220906163147.png);
  background-position: center;
  background-size: cover;
  min-height: calc(100% - 70px);
}
</style>
