<script setup lang="ts">
import useImgPreload from "@/hooks/business/useImgPreload";
import { NSkeleton, NImage } from "naive-ui";

interface Props {
  width: string;
  height: string;
  src: string;
}

const props = defineProps<Props>();
const { loading } = useImgPreload(props.src);
</script>

<template>
  <n-skeleton
    v-if="loading"
    :height="props.height + 'px'"
    :width="props.width + 'px'"
    style="margin: 0 auto"
  />
  <n-image
      v-else
      :height="props.height"
      :width="props.width"
      :src="props.src"
      preview-disabled
  />
</template>
