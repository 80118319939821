<template>
  <div class="snLogin__marketing">
    <h2 class="snLogin__marketingTitle">
      <n-skeleton v-if="loading" width="66%" height="56px" :sharp="false" />
      <template v-else>{{ marketingOjb.title }}</template>
    </h2>
    <div class="snLogin__marketingDes">
      <n-skeleton v-if="loading" width="90%" height="50px" :sharp="false" />
      <template  v-else>{{ marketingOjb.des }}</template>
    </div>
    <template v-if="loading">
      <n-skeleton v-if="loading" width="30%" height="50px" :sharp="false" />
    </template>
    <template v-else>
      <template v-if="marketingOjb.externalLink">
        <a :href="marketingOjb.ctaLink" target="__blank" class="snLogin__marketingBtn">
          <span class="cat-txt">
            {{ marketingOjb.ctaText }}
          </span>
          <span class="cat-icon">
            >
          </span>
        </a>
      </template>
      <template v-else>
        <router-link :to="marketingOjb.ctaLink" class="snLogin__marketingBtn">
          <div class="cat-txt">
            {{ marketingOjb.ctaText }}
          </div>
          <span class="cat-icon">
            >
          </span>
        </router-link>
      </template>
    </template>
  </div>
</template>

<script lang="ts" setup>
import { NSkeleton } from 'naive-ui'
import useMarketing from '@/hooks/business/useMarketing';
import { RouterLink } from 'vue-router';
import { onMounted } from 'vue';

/** State */
const { marketingOjb, loading, refreshMaketing } = useMarketing();

onMounted(() => {
  refreshMaketing()
})

</script>

<style lang="scss" scoped>
.snLogin__marketingBtn {
  display: flex;
  align-items: center;
  width: fit-content;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0px 15px 15px rgb(153 167 188 / 20%);
  margin-top: 25px;
  transition: all .3s;
  &:hover {
    transform: translateY(5px);
  }
}

.cat-txt {
  height: 50px;
  display: inline-block;
  line-height: 50px;
  padding: 0 30px;
  background: #EDF4FF;
  color: #3E8BFF;
  font-size: 14px;
}

.cat-icon {
  display: inline-block;
  height: 50px;
  line-height: 50px;
  background: #3E8BFF;
  width: 50px;
  color: white;
  text-align: center;
  font-size: 18px;
}

.snLogin__marketing {
  width: 420px;
  margin-right: 115px;
}

.snLogin__marketingTitle {
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 56px;
  color: #333333;
  padding-top: 75px;
  margin-top: 0;
  margin-bottom: 0;
}

.snLogin__marketingDes {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: #666666;
  padding-top: 15px;
  padding-bottom: 25px;
}
</style>
