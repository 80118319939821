<script lang="ts" setup>
import { useWebsiteStore } from "@/stores/website";
import SideBar from "@/components/SideBar.vue";


const websiteState = useWebsiteStore()

</script>

<template>
  <div class="home" id="dashboard">
    <side-bar />
    <router-view />
  </div>
</template>

<style lang="scss">
#dashboard {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  flex-shrink: 1;
  flex-grow: 1;
  width: 100%;
  height: 100%;
}
.main-container {
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  flex-grow: 1;
  width: calc(100% - 100px);
  background-color: rgb(237 244 255 / 70%);
  .main-stage {
    max-width: 1400px;
    padding: 0 50px;
    padding-bottom: 90px;
    margin: 0 auto;
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto 1fr 1fr;
    gap: 30px 40px;
    grid-template-areas:
      "one two three"
      "four four five"
      "four four six";
      padding-top: 50px;

    & > div {
      background: #fcfdff;
      border-radius: 20px;
      overflow: hidden;
    }
    & > a {
      background: #fcfdff;
      border-radius: 20px;
      display: block;
      text-decoration: none;
    }
    .one {
      grid-area: one;
    }
    .two {
      grid-area: two;
    }
    .three {
      grid-area: three;
    }
    .five {
      grid-area: five;
    }
    .six {
      grid-area: six;
    }
    .four {
      grid-area: four;
    }
  }
}
</style>
