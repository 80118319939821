<script lang="ts" setup>
import { onMounted, ref } from "vue";
import {NButton, useMessage, NGrid, NGi, FormRules, FormInst} from "naive-ui";
import UserService from "@/service/userService";
import { useRouter, RouterLink } from "vue-router";
import { getShortUUID } from "@/utils";
import { getApiBase, parseWechatQueryResult } from '@/config';
import { Logger } from "@/logger";
import SnLoginMarketing from './SnLoginMarketing.vue'

import { useThemeVars } from 'naive-ui'
const themeVars = useThemeVars()
/**
 * State
 */

const props = defineProps({
  'viewName': {
    require: false,
    default:''
  }
})

/**
 * Hooks
 */


/** Handler */


</script>

<template>
    <div :class="props.viewName">
      <SnLoginMarketing />
      <router-view></router-view>
    </div>
</template>

<style lang="scss" scoped>

.snLogin, .snForget {
  margin: 0px auto;
  width: 1085px;
  display: flex;
  padding-top: 170px;
  padding-bottom: 170px;
}


</style>
