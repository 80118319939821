<script lang="ts" setup>
import {onMounted, watchEffect} from "vue";
import { useWebsiteStore } from "@/stores/website";
import { useRoute, useRouter } from "vue-router";
import NoWebsite from "@/components/NoWebsite.vue";
import TopBar from "@/components/TopBar.vue";
import StageLoading from "@/components/StageLoading.vue";
import SysNotification from "@/components/SysNotification.vue";
import MoreSetting from "@/components/MoreSetting.vue";
import NewsCarousel from "@/components/NewsCarousel.vue";
import BriefReport from "@/components/BriefReport.vue";
import SiteDetail from "@/components/SiteDetail.vue";
import BindDomain from "@/components/BindDomain.vue";
import SiteService from "@/service/siteService";
import ProuductService from "@/service/productService";
import { useTemplateStore } from "@/stores/template";
import { useProductStore } from "@/stores/product";
import { useCreateStore } from "@/stores/create";

const websiteState = useWebsiteStore();
const router = useRouter();
const route = useRoute();

const templateState = useTemplateStore();
const productState = useProductStore();
const createState = useCreateStore();

const fetchTemplateData = async () => {
  if (templateState.loading === false) return; // already loaded
  const result = await SiteService.getTemplateData();
  templateState.setTemplateCates(result.categorys);
  templateState.setTemplates(result.templates);
};

const fetchProductData = async () => {
  if (productState.loading === false) return; // means product already loaded
  const products = await ProuductService.getAllProducts();
  productState.setProducts(products);
  const mainProduct = productState.getWebsiteProduct();
  createState.orderPricingOjb.totalPrice = mainProduct.unitPrice;
  createState.orderPricingOjb.finalTotalPrice = mainProduct.unitPrice;
  createState.totalPrice = mainProduct.unitPrice;
  createState.finalTotalPrice = mainProduct.unitPrice;
};

onMounted(() => {
  const sid = route.params.sid;
  if (sid) {
    websiteState.setCurrentWebsite(Number(sid));
  }

  // init data for later use
  fetchTemplateData();
  fetchProductData();
});

watchEffect(() => {
  const sid = route.params.sid;
  if (sid) {
    websiteState.setCurrentWebsite(Number(sid));
  }
});
</script>

<template>
  <div class="main-container">
    <top-bar />
    <perfect-scrollbar>
      <template v-if="websiteState.loading">
        <stage-loading />
      </template>
      <template
        v-else-if="
          websiteState.loading === false && websiteState.websites.length === 0
        "
      >
        <no-website></no-website>
      </template>
      <template v-else>
        <div class="main-stage">
          <site-detail />
          <bind-domain />
          <news-carousel />
          <more-setting />
          <sys-notification />
          <brief-report />
        </div>
      </template>
    </perfect-scrollbar>
  </div>
</template>

<style scoped lang="scss"></style>
