import { serialize } from "@/utils"
import { resolve } from "path"
import HttpService from "./httpService"

export default class ContentService {
    public static async getTax() {
        return new Promise((resolve, reject) => {
            const result = {
                tone: [],
                text_type: [],
                page_type: [],
                block_type: [],
                industry: []
            }
    
            const tone = 'https://copywrite.msupernova.com/wp-json/wp/v2/tone'
            const text_type = 'https://copywrite.msupernova.com/wp-json/wp/v2/text_type'
            const page_type = 'https://copywrite.msupernova.com/wp-json/wp/v2/page_type'
            const block_type = 'https://copywrite.msupernova.com/wp-json/wp/v2/block_type'
            const industry = 'https://copywrite.msupernova.com/wp-json/wp/v2/industry'
    
            const taxPromise = [tone, text_type, page_type, block_type, industry]
                                    .map(link => fetch(link).then(res => res.json()))

            Promise.all(taxPromise)
                .then((values) => {
                    const transformItem = (item: any) =>  ({
                            count: item.count,
                            name: item.name.replace('&amp;', '&'),
                            id: item.id  
                    })
                    result['tone'] = values[0].map(transformItem)
                    result['text_type'] = values[1].map(transformItem)
                    result['page_type'] = values[2].map(transformItem)
                    result['block_type'] = values[3].map(transformItem)
                    result['industry'] = values[4].map(transformItem)
                    resolve(result)
                }).catch(e => {
                    reject('获取数据出错')
                })
    
        })
    }
    public static async list(request: any) {
        return new Promise((resolve, reject) => {
            const link = `https://copywrite.msupernova.com/wp-json/sn/v1/content?${serialize(request)}`
            fetch(link).then(res => res.json())
                        .then(data => {
                            // transform data maybe
                            resolve(data)
                        }).catch(e => {
                            throw new Error('请求出错')
                        })
        })
    }
}