<script setup lang="ts">
import { computed, ref, watchEffect } from 'vue';
import { NDrawer, NDrawerContent, NPopover, NEllipsis, NPagination, NUpload, NButton, useMessage, NCheckbox, NPopconfirm, NSpin, NInput, NCheckboxGroup, NSpace } from 'naive-ui';
import { useWebsiteStore } from '@/stores/website';
import SiteService from '@/service/siteService';
import UserService from '@/service/userService';
import { getApiBase } from '@/config';
import router from '@/router';
import { useUserStore } from '@/stores/user';
import { useProductStore } from '@/stores/product';
import { currencyFilter } from '@/filters';

const active = ref(false)
const showAdvancedSetting = ref(false)
const fetchingLogs = ref(false)
const showSiteExtend = ref(false)
const showSysLog = ref(false)
const sysLogCurPage = ref(1)
const sysLogTotalPage = ref(1)
const sysLogCurrentItems = ref<any>([])
const editingSiteName = ref(false)
const siteName = ref("")
const siteNameUpdating = ref(false)
const websiteState = useWebsiteStore()
const userState = useUserStore()
const productState = useProductStore()
const imageUploading = ref(false)
const isSiteNameNotChanged = computed(() => websiteState.getCurrentWebsiteName() === siteName.value)
const myRef = ref(null);
const message = useMessage()
const usersForCurrentSite = ref()
const grantSupportAccess = ref(false) // true is already grant
const grantMarketingAccess = ref(false)
const supportGrantUpdating = ref(false)
const marketingGrantUpdating = ref(false)
const invitationForCurrentSite = ref()
const updatingInviteId = ref([0]) // 标志正在操作的 inviteId ajax loading
const showAddingStaffForm = ref(false)
const newStaffEmail = ref("")
const newStaffPermissions = ref([])
const sendingNewStaffInvite = ref(false)
const isEditingStuffPermission = ref(false)
const editingStaffwId = ref(0)
const editingStaffPermissions = ref([""])
const isUpdatingStaffPermit = ref(false)
const removingStaffIds = ref([0])

const onShowSysLogClick = () => {
    showSysLog.value = true
    fetchSysLog()
}

watchEffect(() => {
    if (editingStaffwId.value === 0) return
    const targetStaffUser = usersForCurrentSite.value.filter((user: any) => user.wpId === editingStaffwId.value)[0]
    editingStaffPermissions.value = Object.keys(targetStaffUser.caps)
})

const editStaffUserPermit = (wid: number) => {
    isEditingStuffPermission.value = true
    editingStaffwId.value = wid
}

const shouldDisableEditingStaffConfirmBtn = computed(() => {
    if (editingStaffwId.value === 0) return true
    const targetStaffUser = usersForCurrentSite.value.filter((user: any) => user.wpId === editingStaffwId.value)[0]
    const oldPermissions = Object.keys(targetStaffUser.caps)
    // 长度不同，一定修改过
    if (oldPermissions.length != editingStaffPermissions.value.length) return false
    // 长度一样，看看内容
    for (const permit of oldPermissions) {
        // 有一项不同
        if (!editingStaffPermissions.value.includes(permit)) {
            return false
        }
    }
    return true
})

const cancelEditingStaffUser = () => {
    isEditingStuffPermission.value = false
    editingStaffwId.value = 0
}

const cancelEditing = () => {
    editingSiteName.value = false
    siteName.value = websiteState.getCurrentWebsiteName()
}

const retreatInviteById = async (inviteId: number) => {
    updatingInviteId.value = [...updatingInviteId.value, inviteId]
    try {
        await SiteService.retreatInvitationById(inviteId, websiteState.currentWebsiteId)
        invitationForCurrentSite.value = invitationForCurrentSite.value.filter((invitation: any) => invitation.id != inviteId)
        // on success, update invitation list
    } catch (e: any) {
        message.error(e.message)
    }
    updatingInviteId.value = updatingInviteId.value.filter(id => id != inviteId)
}

const removeStaffUserById = async (wpId: number, wpEmail: string) => {
    removingStaffIds.value = [...removingStaffIds.value, wpId]
    try {
        await SiteService.removeStaffById(websiteState.currentWebsiteId, wpId, wpEmail)
        usersForCurrentSite.value = usersForCurrentSite.value.filter((user: any) => user.wpId != wpId)
        // on success, update invitation list
        invitationForCurrentSite.value = invitationForCurrentSite.value.filter((invitation: any) => invitation.toEmail != wpEmail)
    } catch (e: any) {
        message.error(e.message)
    }
    removingStaffIds.value = removingStaffIds.value.filter(id => id != wpId)
}

const updateSiteName = async () => {
    if (isSiteNameNotChanged.value) return

    siteNameUpdating.value = true
    try {
        await SiteService.updateSiteName(siteName.value, websiteState.currentWebsiteId)
        message.success("修改成功")
        editingSiteName.value = false
        websiteState.updateWebsiteName(siteName.value)
        // userState.user.name = nickName.value
    } catch (e: any) {
        message.error(e.message)
    }
    siteNameUpdating.value = false
}

const editingStaffUser = computed(() => {
    return usersForCurrentSite.value.filter((user: any) => user.wpId === editingStaffwId.value)[0]
})

const activate = () => {
    active.value = true
}

const listSiteUser = async () => {
    const users = await SiteService.listSiteUser(websiteState.currentWebsiteId)
    usersForCurrentSite.value = users
     // is support already grant?
    const support = usersForCurrentSite.value.filter((user: any) => user.wpLogin === 'support')
    if (support.length > 0) {
        grantSupportAccess.value = true
    } else {
        grantSupportAccess.value = false
    }

    // is marketing already grant?
    const marketing = usersForCurrentSite.value.filter((user: any) => user.wpLogin === 'marketing')
    if (marketing.length > 0) {
        grantMarketingAccess.value = true
    }else {
        grantMarketingAccess.value = false
    }
}

// actually this return admin to be compatible with older settings
const siteAdmins = computed(() => {
    // user list not loaded
    if (!usersForCurrentSite.value) {
        return {}
    }

    // compatible with old site, if only one user, he must be admin
    const leftOwners = usersForCurrentSite.value.filter((user: any) => user.wpLogin !== 'support' && user.wpLogin !== 'marketing')
    if (leftOwners.length === 1) return leftOwners

    // filter
    const posibleOwners = usersForCurrentSite.value.filter((user: any) => {
        if (user.role.includes('administrator') && user.wpLogin !== 'support' && user.wpLogin !== 'marketing') {
            return true
        } else {
            return false
        }
    })

    return posibleOwners
})

const staffAccounts = computed(() => {
    return usersForCurrentSite.value.filter((user: any) => user.role && user.role.includes('staff'))
})

const onGrantSupportToggle = async (checked: boolean) => {
    supportGrantUpdating.value = true
    try {
        await SiteService.toggleServiceGrant(websiteState.currentWebsiteId, 'support')
        message.info("操作成功")
    } catch (e: any) {
        message.error("操作失败，请刷新页面重试或联系管理员")
    }
    supportGrantUpdating.value = false
}

const onGrantMarketingToggle = async (checked: boolean) => {
    marketingGrantUpdating.value = true
    try {
        await SiteService.toggleServiceGrant(websiteState.currentWebsiteId, 'marketing')
        message.info("操作成功")
    } catch (e: any) {
        message.error("操作失败，请刷新页面重试或联系管理员")
    }
    marketingGrantUpdating.value = false
}

const uploadLinkForCustomFavicon = computed(() => {
    return `${getApiBase()}app/user/updateSiteFavicon?blogId=${websiteState.currentWebsiteId}`
})

const getUploadToken = () => {
    return {
        token: window.localStorage.getItem("token") || ""
    }
}

const onFileFinish = (option: any) => {
    const resJson = JSON.parse(option.event.currentTarget.response)
    websiteState.updateFavicon(resJson.url)
    imageUploading.value = false
    return undefined
}
const onBeforeUpload = () => {
    imageUploading.value = true
}
const onUploadErr = (file: any | undefined) => {
    imageUploading.value = false
    message.error("图片上传出错")
    return undefined;
}

const fetchInvitationForCurrentSite = async () => {
    const invites = await SiteService.listInvitationForSite(websiteState.currentWebsiteId)
    invitationForCurrentSite.value = invites
}

const shouldDisableSendInviteBtn = computed(() => {
    if (sendingNewStaffInvite.value) return true

    // validate email
    if (!/^\S+@\S+\.\S+$/.test(newStaffEmail.value)) {
        return true
    }

    return false
})

const onUpdateStaffPermitClick = async () => {
    const newPermissons = [...editingStaffPermissions.value]
    isUpdatingStaffPermit.value = true
    try {
        await SiteService.updateUserPermissions(websiteState.currentWebsiteId, editingStaffwId.value, newPermissons)
        message.success("操作成功")
        // todo: update state maybe
        // const targetStaffUser = usersForCurrentSite.value.filter((user: any) => user.wpId === editingStaffwId.value)[0]
        for(let i = 0; i < usersForCurrentSite.value.length; i++) {
            const currentStaffUserOfLoop = usersForCurrentSite.value[i]
            if (currentStaffUserOfLoop.wpId === editingStaffwId.value) {
                // todo: this is the target user to update
                const newCaps: any = {}
                newPermissons.forEach((key: string) => {
                    newCaps[key] = true
                })
                currentStaffUserOfLoop.caps = newCaps
            }
        }
        cancelEditingStaffUser()
    } catch (e: any) {
        message.error(e.message)
    }
    isUpdatingStaffPermit.value = false
}

const onSendInviteClick = async () => {
    sendingNewStaffInvite.value = true
    try {
        const inviteIdInStr = await SiteService.inviteUser(websiteState.currentWebsiteId, newStaffEmail.value, [...newStaffPermissions.value])
        message.success("添加成功，请提醒您的员工查看邮件")
        showAddingStaffForm.value = false
        // update invite
        invitationForCurrentSite.value.push({
                id: Number(inviteIdInStr),
                toEmail: newStaffEmail.value,
                status: "PENDING"
            })
        //reset invite form
        newStaffEmail.value = ""
        newStaffPermissions.value = []
    } catch (e: any) {
        // use error msg provided by backend
        message.error(e.message)
    }
    sendingNewStaffInvite.value = false
}

const remainingStuffNum = computed(() => {
    return 5 - invitationForCurrentSite.value.length
})

const pendingInvitations = computed(() => {
    return invitationForCurrentSite.value.filter((invitation: any) => invitation.status == "PENDING")
})

const listSiteStaff = async () => {

}

const getSiteLetterByDomain = (domain: string) => {
    return domain.slice(0,2).toUpperCase()
}

const fetchSysLog = async (pageToFetch: number = 1) => {
    fetchingLogs.value = true
    try {
        const pageRes = await UserService.getLogsBySite(websiteState.currentWebsiteId, pageToFetch, 10)
        sysLogTotalPage.value = pageRes.totalPage
        sysLogCurrentItems.value = pageRes.list
    } catch(e: any) {
        message.error(e.message)
    }
    fetchingLogs.value = false
}

const onExtendSiteClick = () => {
    router.push({
        name: "create",
        state: {
            pid: 275 // 275 is extend site product id, wont't change so it's  hard coded
        }
    })
}

const onExtendAdsClick = () => {
    router.push({
        name: "create",
        state: {
            pid: 273 // 273 is ads service product is, hard coded on purpose
        }
    })
}

watchEffect(() => {
    fetchSysLog(sysLogCurPage.value)
})

watchEffect(() => {
    siteName.value = websiteState.getCurrentWebsiteName()
    // Check permission first, below call below for site owner
    if (websiteState.isOwnerForCurrentSite()) {
        listSiteUser()
        fetchInvitationForCurrentSite()
        listSiteStaff()
    }
})

</script>

<template>
    <div class="five more-settings" v-if="websiteState.isOwnerForCurrentSite()">
        <h2 class="setting-title">更多设置</h2>
        <div class="setting-btn-wrap">
            <a href="#" class="btn setting-btn" @click="activate">权限管理</a>
            <a href="#" class="btn setting-btn" @click="showSiteExtend=true">续费管理</a>
            <a href="#" class="btn setting-btn" @click="onShowSysLogClick">系统日志</a>
            <a href="#" class="btn setting-btn" @click="showAdvancedSetting=true">高级设置</a>
        </div>
    </div>

    <div class="five more-settings no-permission" v-else>
        <div class="no-permit-tag">无权限</div>
        <h2 class="setting-title">更多设置</h2>
        <div class="setting-btn-wrap">
            <a class="btn setting-btn">权限管理</a>
            <a class="btn setting-btn">续费管理</a>
            <a class="btn setting-btn">系统日志</a>
            <a class="btn setting-btn">高级设置</a>
        </div>
    </div>

    <n-drawer class="snSideSetting" v-model:show="active" :width="502" placement="right">
        <n-drawer-content>
            <div class="drawer-inner">
                <div class="drawer-header">
                    <h2 class="drawer-title">权限管理</h2>
                    <p class="drawer-des">您可添加或删减平台管理员</p>
                </div>
                <div class="drawer-main">
                    <div class="users-card">
                        <div class="owner-wrap">
                            <div class="admin-row" v-for="(item, index) in siteAdmins" :key="index">
                                <div class="user-info">
                                    <div class="avatar-wrap">
                                        <img :src="item.avatar || 'https://st-img-md.oss-cn-guangzhou.aliyuncs.com/%E8%B6%85%E6%96%B0%E6%98%9Flogo.jpg'" class="site-user-avatar" >
                                    </div>
                                    <div class="user-name">{{item.wpNickName}}</div>
                                </div>
                                <div class="user-tag">
                                    <span>{{userState.user.phone === item.wpLogin ? "所有者" : "管理员"}}</span>
                                </div>
                            </div>
                            <template v-if="showAddingStaffForm">
                                    <div class="section-break"></div>
                                     <div class="section-des">添加员工账号(还有{{remainingStuffNum}}个名额)</div>
                                     <div class="new-staff-email-wrap">
                                        <n-input size="large" v-model:value="newStaffEmail" type="text" placeholder="请输入 Email" />
                                     </div>
                                     <div class="permission-select">
                                         <n-checkbox-group v-model:value="newStaffPermissions">
                                            <n-space item-style="display: flex;">
                                                <n-checkbox size="large" value="sn_product" label="产品管理" />
                                                <n-checkbox size="large" value="sn_page" label="页面管理" />
                                                <n-checkbox size="large" value="sn_post" label="文章管理" />
                                                <n-checkbox size="large" value="sn_lead" label="询盘管理" />
                                                <n-checkbox size="large" value="sn_setting" label="设置管理" />
                                            </n-space>
                                        </n-checkbox-group>
                                     </div>
                                     <div class="invite-btn-wrap">
                                         <n-space>
                                            <n-button size="large" @click="onSendInviteClick" :loading="sendingNewStaffInvite" :disabled="shouldDisableSendInviteBtn" type="primary">发送邀请</n-button>
                                            <n-button size="large" type="tertiary" @click="showAddingStaffForm = false" :disabled="sendingNewStaffInvite">返回</n-button>
                                         </n-space>
                                     </div>
                            </template>
                            <template v-if="!showAddingStaffForm && !isEditingStuffPermission">
                                <div class="section-break"></div>
                                <div class="section-des" style="padding: 5px 0;">一键授权</div>
                                <div class="grant-service-wrap">
                                    <n-checkbox :disabled="supportGrantUpdating" v-model:checked="grantSupportAccess" @update:checked="onGrantSupportToggle">
                                        授权客户成功团队访问
                                    </n-checkbox>
                                </div>
                                <div class="grant-service-wrap">
                                    <n-checkbox :disabled="marketingGrantUpdating" v-model:checked="grantMarketingAccess" @update:checked="onGrantMarketingToggle">
                                        授权谷歌运营团队访问
                                    </n-checkbox>
                                </div>
                                <div class="section-break" v-if="pendingInvitations.length > 0"></div>
                                <div class="section-des" v-if="pendingInvitations.length > 0">待确认邀请</div>
                                <div class="admin-row pending-row" v-for="(item) in pendingInvitations" :key="item.id">
                                    <div class="user-email">{{item.toEmail}}</div>
                                    <div class="operation-wrap">
                                        <div v-if="updatingInviteId.includes(item.id)">
                                            <n-spin size="small" />
                                        </div>
                                        <n-popconfirm
                                            v-else
                                            negative-text="取消"
                                            positive-text="确认"
                                            @positive-click="retreatInviteById(item.id)"
                                        >
                                            <template #trigger>
                                            <div class="pointer retreat user-tag">
                                                撤消邀请
                                            </div>
                                            </template>
                                            是否确认撤销?
                                        </n-popconfirm>

                                    </div>
                                </div>
                                <div class="section-break"></div>
                                <div class="section-des add-staff">员工账号(还有{{remainingStuffNum}}个名额)</div>
                                <div class="admin-row staff-row" v-for="item in staffAccounts" :key="item.id">
                                    <div class="user-info">
                                        <div class="avatar-wrap">
                                            <img v-if="item.avatar && item.avatar !== ''" :src="item.avatar" class="site-user-avatar" >
                                            <span class="userletter" v-else>{{item.wpNickName.slice(0,2).toUpperCase()}}</span>
                                        </div>
                                        <div class="user-name">
                                            <n-ellipsis style="max-width: 150px">
                                                 {{item.wpNickName}}
                                            </n-ellipsis>
                                        </div>
                                    </div>
                                    <div class="operation-tag">
                                        <span v-if="!removingStaffIds.includes(item.wpId)" class="user-tag edit-permit pointer" @click="editStaffUserPermit(item.wpId)">权限编辑</span>
                                        <div v-if="removingStaffIds.includes(item.wpId)">
                                            <n-spin size="small" />
                                        </div>
                                        <n-popconfirm
                                            v-else
                                            negative-text="取消"
                                            positive-text="确认"
                                            @positive-click="removeStaffUserById(item.wpId, item.wpEmail)"
                                        >
                                            <template #trigger>
                                            <div class="user-tag remove-staff pointer">
                                                移除
                                            </div>
                                            </template>
                                            是否确认移除用户?
                                        </n-popconfirm>
                                        <!-- <span class="user-tag remove-staff pointer"></span> -->
                                    </div>
                                </div>
                                <div class="admin-row staff-row add-staff-wrap">
                                    <n-button type="primary" size="large" block @click="showAddingStaffForm = true">添加员工</n-button>
                                </div>
                            </template>
                            <template v-if="!showAddingStaffForm && isEditingStuffPermission">
                                <div class="section-break"></div>
                                <div class="section-des">正在编辑用户权限</div>
                                <div class="admin-row staff-row editing">
                                    <div class="user-info">
                                        <div class="avatar-wrap">
                                            <img v-if="editingStaffUser.avatar && editingStaffUser.avatar !== ''" :src="editingStaffUser.avatar" class="site-user-avatar" >
                                            <span class="userletter" v-else>{{editingStaffUser.wpNickName.slice(0,2).toUpperCase()}}</span>
                                        </div>
                                        <div class="user-name">
                                            <!-- <n-ellipsis style="max-width: 150px"> -->
                                                 {{editingStaffUser.wpNickName}}
                                            <!-- </n-ellipsis> -->
                                        </div>
                                    </div>
                                    <div class="operation-tag">
                                        <!-- <span class="user-tag edit-permit pointer">权限编辑</span>
                                        <span class="user-tag remove-staff pointer">移除</span> -->
                                    </div>
                                </div>
                                <div class="permission-select">
                                         <n-checkbox-group v-model:value="editingStaffPermissions">
                                            <n-space item-style="display: flex;">
                                            <n-checkbox size="large" value="sn_product" label="产品管理" />
                                            <n-checkbox size="large" value="sn_page" label="页面管理" />
                                            <n-checkbox size="large" value="sn_post" label="文章管理" />
                                            <n-checkbox size="large" value="sn_lead" label="询盘管理" />
                                            <n-checkbox size="large" value="sn_setting" label="设置管理" />
                                            </n-space>
                                        </n-checkbox-group>
                                     </div>
                                     <div class="invite-btn-wrap">
                                         <n-space>
                                            <n-button type="primary" size="large" @click="onUpdateStaffPermitClick" :loading="isUpdatingStaffPermit" :disabled="shouldDisableEditingStaffConfirmBtn">确定</n-button>
                                            <n-button size="large" type="tertiary" @click="cancelEditingStaffUser" :disabled="isUpdatingStaffPermit">取消</n-button>
                                         </n-space>
                                     </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </n-drawer-content>
      </n-drawer>

    <n-drawer v-model:show="showAdvancedSetting" :width="502" placement="right">
        <n-drawer-content>
            <div class="drawer-inner">
                <div class="drawer-header">
                    <h2 class="drawer-title">高级设置</h2>
                    <p class="drawer-des">来编辑一下您的站点信息吧</p>
                </div>
                <div class="drawer-main">
                    <div class="site-setting-form">
                        <div class="row">
                            <div class="row-label">后台名称</div>
                            <div class="row-content" v-if="editingSiteName">
                                <input type="text" @keyup.enter.native="updateSiteName()" v-model="siteName" />
                                <n-button type="primary" :disabled="isSiteNameNotChanged" class="btn save saveSiteName" @keyup.enter.native="updateSiteName()" :loading="siteNameUpdating" @click="updateSiteName">保存</n-button>
                                <n-button type="primary" class="btn cancel" @click="cancelEditing">取消</n-button>
                            </div>
                            <div class="row-content" v-else>
                                <div class="label nickName">
                                    <span class="nickName-name">{{siteName}}</span>
                                    <span class="nickName-edit" @click="editingSiteName = true">编辑</span>
                                </div>
                            </div>
                        </div>
                        <div class="row site-favicon">
                            <div class="row-label">站点图标</div>
                            <div class="row-content">
                                <div class="site-icon-wrap">
                                    <img v-if="websiteState.getCurrentSiteFavicon() && websiteState.getCurrentSiteFavicon() != ''" style="max-width: 32px;" :src="websiteState.getCurrentSiteFavicon()"  alt="">
                                    <span v-else class="site-letter" style="font-size: 35px; user-select: none; font-weight: bold; color: #408cff;">{{getSiteLetterByDomain(websiteState.getCurrentSitePureDomain())}}</span>
                                </div>
                                <div class="site-upload-wrap">
                                    <n-upload
                                        ref="myRef"
                                        accept=".jpg,.jpge,.png,.ico,jpeg"
                                        :action="uploadLinkForCustomFavicon"
                                        :headers="getUploadToken()"
                                        :show-file-list="false"
                                        :max="100"
                                        :on-finish="onFileFinish"
                                        :on-error="onUploadErr"
                                        :on-beforeUpload="onBeforeUpload"
                                    >
                                        <n-button type="primary" round size="large" :loading="imageUploading">更换</n-button>
                                    </n-upload>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </n-drawer-content>
    </n-drawer>

    <n-drawer v-model:show="showSysLog" :width="502" placement="right">
        <n-drawer-content>
            <div class="drawer-inner">
                <div class="drawer-header">
                    <h2 class="drawer-title">系统日志</h2>
                    <p class="drawer-des">来看看最近都发生了些什么吧</p>
                </div>
                <div class="drawer-main">
                    <div class="site-setting-form sys-log" v-if="sysLogCurrentItems.length > 0">
                        <div class="log-row log-header">
                            <div class="column date">时间</div>
                            <div class="column event">事件</div>
                        </div>
                        <div class="spin-wrap" v-if="fetchingLogs">
                            <n-spin size="large"/>
                        </div>
                        <div class="log-row-main-wrap" v-else>
                            <div class="log-row" v-for="(item) in sysLogCurrentItems" :key="item.id">
                                <div class="column date">
                                    {{item.createDate}}
                                </div>
                                <div class="column event">
                                    <n-popover trigger="hover">
                                        <template #trigger>
                                            {{item.operation}}
                                        </template>
                                        <span>{{item.message}}</span>
                                    </n-popover>
                                </div>
                            </div>
                        </div>
                        <div class="pagination-wrap">
                            <n-pagination v-model:page="sysLogCurPage" :page-count="sysLogTotalPage" />
                        </div>
                    </div>
                    <div class="site-setting-form" v-else>
                        暂无日志
                    </div>
                </div>
            </div>
        </n-drawer-content>
    </n-drawer>

    <n-drawer v-model:show="showSiteExtend" :width="502" placement="right">
        <n-drawer-content>
            <div class="drawer-inner">
                <div class="drawer-header">
                    <h2 class="drawer-title">续费管理</h2>
                    <p class="drawer-des">想跟你作战每一天，请注意服务续费哦～</p>
                </div>
                <div class="drawer-main">
                    <div class="site-setting-form site-extend-wrap">
                        <div class="extend-header">
                            <span class="label set">超新星快速建站</span>
                            <span class="expire">(有效至：{{websiteState.getCurrentWebsiteActiveUntil() || "未知"}})</span>
                        </div>
                        <div class="extend-des">
                            <div class="set-name">
                                一年服务期
                            </div>
                            <div class="set-price">
                                {{currencyFilter(productState.getExtendProduct().unitPrice, '¥', 0)}}
                            </div>
                        </div>
                        <div class="extend-cta-wrap">
<!--                            <a class="extent-btn btn pointer" @click="onExtendSiteClick">立即续费</a>-->
                            <n-button type="primary" @click="onExtendSiteClick" size="large" block>立即续费</n-button>
                        </div>
                    </div>
                    <div class="site-setting-form site-extend-wrap ads-extend-wrap">
                        <div class="extend-header">
                            <span class="label set">谷歌广告投放</span>
                            <span class="expire" v-if="websiteState.getCurrentWebsiteAdsActiveUntil() !== ''">(有效至：{{websiteState.getCurrentWebsiteAdsActiveUntil() || "未知"}})</span>
                            <span class="expire" v-else>(未开通)</span>
                        </div>
                        <div class="extend-des">
                            <div class="set-name">
                                {{productState.getAdsProduct().serviceMonth}}个月服务期
                            </div>
                            <div class="set-price">
                                {{currencyFilter(productState.getAdsProduct().unitPrice, '¥', 0)}}
                            </div>
                        </div>
                        <div class="extend-cta-wrap">
                            <n-button type="primary" size="large" block @click="onExtendAdsClick">
                                {{websiteState.getCurrentWebsiteAdsActiveUntil() !== '' ? "立即续费" : "立即开通"}}
                            </n-button>
                        </div>
                    </div>
                </div>
            </div>
        </n-drawer-content>
    </n-drawer>
</template>

<style scoped lang="scss">
@import '@/styles/global-vars.scss';


:deep(.n-drawer-body) {
    background-color: $color4;
}
.ads-extend-wrap {
    margin-top: 20px;
}
.extend-header {
    border-bottom: 1px solid rgba(51, 51, 51, 0.1);
    padding: 10px 0;
    color: #333333;
    font-size: 16px;
    .label.set {
        font-weight: bold;
        color: #333333;
    }
    .expire {
        font-size: 14px;
        color: #999999;
        padding-left: 5px;
    }
}

.extend-des {
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
}

.extent-btn {
    display: block;
    background: #3E8BFF;
    border-radius: 10px;
    color: white;
    text-align: center;
    font-size: 16px;
    padding: 10px;
    margin-top: 10px;
}
// 权限管理

.grant-service-wrap {
    width: 50%;
    display: inline-block;
}
.section-des.add-staff {
    padding-bottom: 10px;
}
.add-staff-wrap {
    margin-top: 15px;
}
.spin-wrap {
    display: flex;
    justify-content: center;
}
.log-row {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    color: #666666;
    font-size: 14px;
    .column.event {
        width: 16%;
    }
}

.pagination-wrap {
    display: flex;
    justify-content: flex-end;
    padding: 15px 0;
}

.log-row.log-header {
    color: #333333;
    font-size: 16px;
    font-weight: bolder;
    border-bottom: 1px solid rgba(51, 51, 51, 0.1);
    margin-bottom: 15px;
}

.more-settings.no-permission {
    position: relative;
    .btn.setting-btn {
        cursor: not-allowed;
        color: gray;
        &:hover {
            background: #e7f1ff;
        }
    }
    .no-permit-tag {
        position: absolute;
        right: 0;
        top: 0;
        padding: 10px;
        background-color: #e7f1ff;
        color: #858586;
        user-select: none;
    }
}
.userletter {
    background: #e7f1ff;
    display: block;
    padding: 5px;
    border-radius: 10px;
    color: #2e62b1;
    font-weight: bold;
    width: 50px;
    text-align: center;
    font-size: 22px;
    user-select: none;
}
.pointer.retreat.user-tag {
    background: #F7EBED;
    color: #EB6E68;
}
.permission-select {
    padding: 15px 5px;
}
.new-staff-email-wrap {
    padding: 10px 0 0 0;
}
.staff-row {
    .operation-tag {
        display: flex;
        .edit-permit {
            margin-right: 10px;
        }
        .remove-staff {
            background: #F7EBED;
            color: #EB6E68;
        }
    }
}

.users-card {
    background: #FCFDFF;
    box-shadow: 0px 15px 30px rgb(60 137 255 / 5%);
    border-radius: 20px;
    padding: 20px 25px;

    .user-tag {
        background: #E8F0FF;
        border-radius: 10px;
        display: block;
        font-weight: 600;
        font-size: 16px;
        color: #408CFF;
        padding: 10px;
        user-select: none;
    }
    .site-user-avatar {
        max-width: 50px;
        border-radius: 50%;
    }
    .section-break {
        height: 1px;
        background: rgba(51, 51, 51, 0.1);
        margin-top: 15px;
        margin-bottom: 15px;
    }
    .admin-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0px;

        .user-info {
            display: flex;
            align-items: center;
        }

        .user-name {
            margin-left: 20px;
        }
    }

}

.saveSiteName {
    margin-right: 5px;
}
.row-label {
    flex-shrink: 0;
}
.label.nickName {
    padding: 10px 15px;
    font-size: 20px;
}
.row-content {
    display: flex;
    align-items: center;
    input {
            width: 60%!important;
            all: unset;
            border: none;
            background-image: none;
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;
            background: rgba(239, 243, 245, 0.7);
            border-radius: 10px;
            font-size: 20px;
            padding: 10px 15px;
            color: #262c39;
            margin-right: 15px;
        }
}
.nickName {
    .nickName-edit {
        visibility: hidden;
        padding-left: 5px;
        font-size: 14px;
        cursor: pointer;
    }
    &:hover {
        .nickName-edit {
            visibility: visible;
        }
    }
}
.row.site-favicon {
    .row-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .site-icon-wrap {
            margin-right: 20px;
            background: rgba(75, 147, 254, 0.1);
            border-radius: 20px;
            width: 70px;
            height: 70px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}
.drawer-header {
    padding-top: 10px;
    text-align: center;
    margin-bottom: 20px;
    .drawer-title {
        font-size: 25px;
        font-weight: 600;
    }
    .drawer-des {
        color: #999999;
        font-size: 16px;
    }
    .row-label {
        color: #333333;
        font-weight: 600;
    }
}
.site-setting-form {
    background: #FCFDFF;
    box-shadow: 0px 15px 30px rgb(60 137 255 / 5%);
    border-radius: 20px;
    padding: 30px 30px;
    .row {
        display: flex;
        align-items: center;
        .row-content {
            flex: 1;
            padding-left: 15px;
        }
        &:first-child {
            margin-bottom: 20px;
        }
    }
}

.more-settings,
.sys-notification {
    padding: 20px 30px !important;
}
.setting-title {
    font-size: 24px;
    color: #333333;
    font-weight: 600;
}
.setting-btn-wrap {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    grid-row-gap: 15px;
    grid-column-gap: 10px;
    padding-top: 25px;
}
.setting-btn {
    background: #e8f0ff;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: #3e8bff;
    font-size: 18px;
    padding: 15px 20px;
    transition: all 0.3s;
    &:hover {
        background: #3e8bff;
        color: white;
    }
}
</style>
