<script setup lang="ts">
import HttpService from '@/service/httpService';
import { useUserStore } from '@/stores/user';
import { useWebsiteStore } from '@/stores/website';
import { useMessage, NIcon } from 'naive-ui';
import { useRouter } from 'vue-router';
import { onBeforeUnmount, onMounted, ref } from 'vue';
import UserService from '@/service/userService';
import { NSkeleton, NTooltip } from 'naive-ui'
import { Logger } from '@/logger';
import {RouterLink} from 'vue-router';

const props = defineProps({
    useBrandTitle: {
        type: Boolean,
        default: false
    },
    useCopyTitle: {
        type: Boolean,
        default: false
    },
    customTitle: {
        type: String,
        default: ''
    }
})

/**
 * State
 */
const websiteState = useWebsiteStore()
const userState = useUserStore()
const router = useRouter()
const message = useMessage()
const isShowUserInfoDropDown = ref(false)


/**
 * Handler
 */
const handleLogout = () => {
    Logger.debug("logout", "logging user out")
    window.localStorage.setItem("token", "")
    userState.resetForLogout()
    websiteState.resetForLogout()
    HttpService.resetToken()
    message.info("已退出登录")
    router.push({
        name: "Login"
    })
}

const toggleUserInfoDropDown = () => {
    console.log('click')
    isShowUserInfoDropDown.value = !isShowUserInfoDropDown.value
}

const fetchUserData = async () => {
    try {
        if (userState.loading === false) return // already loaded
        const userInfo = await UserService.getUserInfo()
        userState.setUser({
            name: userInfo.nickname || userInfo.username,
            avatar: userInfo.avatarUrl ? userInfo.avatarUrl : "https://st-img-md.oss-cn-guangzhou.aliyuncs.com/%E8%B6%85%E6%96%B0%E6%98%9Flogo.jpg",
            email: userInfo.email,
            phone: userInfo.mobile
        })
        if (userInfo.unionId && userInfo.unionId !== "") {
            userState.setIsWechatBind(true)
        }
    } catch (e: any) {
        message.error(e.message)
    }
}

const goTo = (routerName: string) => {
    router.push({
        name: routerName
    })
}

const bodyClickHandler = (event: any) => {
    const ignoreClickOnMeElement = document.getElementById('user-dropdown');
    if (ignoreClickOnMeElement === null) return
    var isClickInsideElement = ignoreClickOnMeElement.contains(event.target as Node);
    if (!isClickInsideElement) {
        //Do something click is outside specified element
        isShowUserInfoDropDown.value = false
    }
}

onBeforeUnmount(() => {
    document.removeEventListener('click', bodyClickHandler)
})

onMounted(() => {
    document.addEventListener('click', bodyClickHandler)
    fetchUserData()
})

</script>

<template>
    <div class="top-bar">
        <h1 class="site-name">
            <template v-if="websiteState.loading">
                <n-skeleton text style="width: 30%;" />
            </template>
            <template
                v-else-if="websiteState.loading === false && websiteState.websites.length === 0"
            >外贸独立站营销系统</template>
            <template v-else-if="props.useCopyTitle">
                超新星文案库
            </template>
            <template v-else-if="props.customTitle !== ''">
                {{ props.customTitle }}
            </template>
            <template v-else>{{ props.useBrandTitle ? "外贸独立站营销系统" : websiteState.getCurrentWebsiteName() }}</template>
        </h1>
        <div class="user-info-wrap">
            <template v-if="userState.loading">
                <n-skeleton style="margin-right: 20px;" :width="150" :sharp="false" size="large" />
                <n-skeleton circle size="large" class="avatar-loading" />
            </template>
            <template v-else>
                <div class="action-buttons-wrap">
                  <n-tooltip trigger="hover" placement="bottom" >
                    <template #trigger>
                      <div class="action-icon-wrap">
                        <router-link to="/crm">
                          <n-icon color="#b9b9b9" class="action-icon-svg-wrap" size="24">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32"><path d="M19 24H4L3.997 8.906l11.434 7.916a1 1 0 0 0 1.138 0L28 8.91V18h2V8a2.003 2.003 0 0 0-2-2H4a2.002 2.002 0 0 0-2 2v16a2.003 2.003 0 0 0 2 2h15zm6.799-16L16 14.784L6.201 8z" fill="currentColor"></path><circle cx="26" cy="24" r="4" fill="currentColor"></circle></svg>
                          </n-icon>
                        </router-link>
                      </div>
                    </template>
                    CRM对接
                  </n-tooltip>
                    <n-tooltip trigger="hover" placement="bottom" >
                        <template #trigger>
                            <div class="action-icon-wrap">
                                <router-link to="/contentful">
                                    <n-icon color="#b9b9b9" class="action-icon-svg-wrap" size="24">
<!--                                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24"><path d="M21 11h-3.17l2.54-2.54a.996.996 0 0 0 0-1.41c-.39-.39-1.03-.39-1.42 0L15 11h-2V9l3.95-3.95c.39-.39.39-1.03 0-1.42a.996.996 0 0 0-1.41 0L13 6.17V3c0-.55-.45-1-1-1s-1 .45-1 1v3.17L8.46 3.63a.996.996 0 0 0-1.41 0c-.39.39-.39 1.03 0 1.42L11 9v2H9L5.05 7.05c-.39-.39-1.03-.39-1.42 0a.996.996 0 0 0 0 1.41L6.17 11H3c-.55 0-1 .45-1 1s.45 1 1 1h3.17l-2.54 2.54a.996.996 0 0 0 0 1.41c.39.39 1.03.39 1.42 0L9 13h2v2l-3.95 3.95c-.39.39-.39 1.03 0 1.42c.39.39 1.02.39 1.41 0L11 17.83V21c0 .55.45 1 1 1s1-.45 1-1v-3.17l2.54 2.54c.39.39 1.02.39 1.41 0c.39-.39.39-1.03 0-1.42L13 15v-2h2l3.95 3.95c.39.39 1.03.39 1.42 0a.996.996 0 0 0 0-1.41L17.83 13H21c.55 0 1-.45 1-1s-.45-1-1-1z" fill="currentColor"></path></svg>-->
                                      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32"><path d="M22 26h-2v-8.24l-3.23 3.88a1 1 0 0 1-1.54 0L12 17.76V26h-2V15a1 1 0 0 1 .66-.94a1 1 0 0 1 1.11.3L16 19.44l4.23-5.08a1 1 0 0 1 1.11-.3A1 1 0 0 1 22 15z" fill="currentColor"></path><path d="M4.16 14.65l-3-1.75a.762.762 0 1 0-.76 1.32l3 1.78a.76.76 0 1 0 .76-1.31z" fill="currentColor"></path><path d="M8.29 10.52a.73.73 0 0 0 1 .27a.75.75 0 0 0 .28-1l-1.74-3a.762.762 0 1 0-1.32.76z" fill="currentColor"></path><path d="M16 9a.76.76 0 0 0 .76-.76V4.76a.76.76 0 1 0-1.52 0v3.49A.76.76 0 0 0 16 9z" fill="currentColor"></path><path d="M22.68 10.79a.75.75 0 0 0 .37.11a.76.76 0 0 0 .66-.38l1.75-3a.762.762 0 0 0-1.32-.76l-1.74 3a.75.75 0 0 0 .28 1.03z" fill="currentColor"></path><path d="M31.9 13.18a.76.76 0 0 0-1-.28l-3 1.75a.76.76 0 0 0 .7 1.35l3-1.74a.77.77 0 0 0 .3-1.08z" fill="currentColor"></path></svg></n-icon>
                                </router-link>
                            </div>
                        </template>
                        文案库
                    </n-tooltip>
                    <n-tooltip trigger="hover" placement="bottom" >
                        <template #trigger>
                            <div class="action-icon-wrap">
                              <router-link to="/sacademy">
                                <n-icon color="#b9b9b9" class="action-icon-svg-wrap" size="24">
<!--                                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512"><path d="M202.24 74C166.11 56.75 115.61 48.3 48 48a31.36 31.36 0 0 0-17.92 5.33A32 32 0 0 0 16 79.9V366c0 19.34 13.76 33.93 32 33.93c71.07 0 142.36 6.64 185.06 47a4.11 4.11 0 0 0 6.94-3V106.82a15.89 15.89 0 0 0-5.46-12A143 143 0 0 0 202.24 74z" fill="currentColor"></path><path d="M481.92 53.3A31.33 31.33 0 0 0 464 48c-67.61.3-118.11 8.71-154.24 26a143.31 143.31 0 0 0-32.31 20.78a15.93 15.93 0 0 0-5.45 12v337.13a3.93 3.93 0 0 0 6.68 2.81c25.67-25.5 70.72-46.82 185.36-46.81a32 32 0 0 0 32-32v-288a32 32 0 0 0-14.12-26.61z" fill="currentColor"></path></svg>                         -->
                                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32"><path d="M27 24a2.96 2.96 0 0 0-1.285.3l-4.3-4.3H18v2h2.586l3.715 3.715A2.966 2.966 0 0 0 24 27a3 3 0 1 0 3-3zm0 4a1 1 0 1 1 1-1a1 1 0 0 1-1 1z" fill="currentColor"></path><path d="M27 13a2.995 2.995 0 0 0-2.816 2H18v2h6.184A2.995 2.995 0 1 0 27 13zm0 4a1 1 0 1 1 1-1a1 1 0 0 1-1 1z" fill="currentColor"></path><path d="M27 2a3.003 3.003 0 0 0-3 3a2.966 2.966 0 0 0 .348 1.373L20.596 10H18v2h3.404l4.4-4.252A2.999 2.999 0 1 0 27 2zm0 4a1 1 0 1 1 1-1a1 1 0 0 1-1 1z" fill="currentColor"></path><path d="M18 6h2V4h-2a3.976 3.976 0 0 0-3 1.382A3.976 3.976 0 0 0 12 4h-1a9.01 9.01 0 0 0-9 9v6a9.01 9.01 0 0 0 9 9h1a3.976 3.976 0 0 0 3-1.382A3.976 3.976 0 0 0 18 28h2v-2h-2a2.002 2.002 0 0 1-2-2V8a2.002 2.002 0 0 1 2-2zm-6 20h-1a7.005 7.005 0 0 1-6.92-6H6v-2H4v-4h3a3.003 3.003 0 0 0 3-3V9H8v2a1 1 0 0 1-1 1H4.08A7.005 7.005 0 0 1 11 6h1a2.002 2.002 0 0 1 2 2v4h-2v2h2v4h-2a3.003 3.003 0 0 0-3 3v2h2v-2a1 1 0 0 1 1-1h2v4a2.002 2.002 0 0 1-2 2z" fill="currentColor"></path></svg>
                                </n-icon>
                              </router-link>
                            </div>
                        </template>
                        营销学堂
                    </n-tooltip>
                </div>
                <div class="avatar-wrap" id="user-dropdown">
                    <img :src="userState.user.avatar" class="avatar" @click="toggleUserInfoDropDown" />
                    <div class="dropdown-wrap">
                    <div class="dropdown-popup" v-show="isShowUserInfoDropDown">
                        <ul class="dropdown-menu">
                            <li class="user-detail-wrap">
                                <div class="user-detail-avatar-wrap">
                                    <div class="user-detail-avatar-round-wrap">
                                        <img :src="userState.user.avatar" class="avatar" />
                                    </div>
                                </div>
                                <div class="user-detail-info-wrap">
                                    <div class="user-detail-wrap-name"><span>{{ userState.user.name }}</span></div>
                                    <div class="user-detail-wrap-email"><span>{{ userState.user.email }}</span></div>
                                </div>
                            </li>
                            <li class="dropdown-menu-item" @click="goTo('OrderManagement')">
                                <span class="icon-wrap">
                                    <img src="https://sn-platform-app.oss-cn-guangzhou.aliyuncs.com/static/%E8%AE%A2%E5%8D%95%E7%AE%A1%E7%90%86.png" />
                                </span>
                                <span class="menu-text-wrap">订单管理</span>
                            </li>
                            <li class="dropdown-menu-item" @click="goTo('Profile')">
                                <span class="icon-wrap">
                                    <img src="https://sn-platform-app.oss-cn-guangzhou.aliyuncs.com/static/%E4%B8%AA%E4%BA%BA%E4%BF%A1%E6%81%AF.png" />
                                </span>
                                <span class="menu-text-wrap">个人资料</span>
                            </li>
                            <a href="https://msupernova.com/resources/" target="_blank" class="dropdown-menu-item">
                                <span class="icon-wrap">
                                    <img src="https://sn-platform-app.oss-cn-guangzhou.aliyuncs.com/static/%E5%B8%AE%E5%8A%A9%E4%B8%AD%E5%BF%83.png" />
                                </span>
                                <span class="menu-text-wrap help">帮助中心</span>
                            </a>
                            <li class="dropdown-menu-item login" @click="handleLogout">
                                <span class="menu-text-wrap">退出登录</span>
                            </li>
                        </ul>
                    </div>
                </div>
                </div>
            </template>
        </div>
    </div>
</template>

<style scoped lang="scss">
@import '@/styles/global-vars.scss';
.action-icon-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    .action-icon-svg-wrap {
        cursor: pointer;
        &:hover {
            color: #2160b5!important;
        }
    }
}
.action-buttons-wrap {
    display: flex;
    align-items: center;
    margin-right: 15px;
}
.avatar {
    max-width: 35px;
    border-radius: 50%;
    width: 35px;
    height: 35px;
}
.user-detail-wrap {
    display: flex;
    padding-bottom: 14px;
    border-bottom: 1px solid rgb(213 214 216 / 50%);
    margin-bottom: 15px;
    .user-detail-info-wrap {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.user-detail-info-wrap > div {
    height: 100%;
    line-height: 1;
    display: flex;
    align-items: center;
    & > span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 180px;
        user-select: text;
    }
}

.user-detail-wrap-email {
    color: rgba(50,58,69,.5);
}
.user-detail-avatar-round-wrap {
    padding: 4px;
    background: linear-gradient(180deg,rgba(50,58,69,.1),rgba(50,58,69,.13),rgba(50,58,69,.23)) no-repeat padding-box;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 10px;
}
}
#user-dropdown {
    background: linear-gradient(180deg,rgba(50,58,69,.1),rgba(50,58,69,.13),rgba(50,58,69,.23)) no-repeat padding-box;
    padding: 4px;
    border-radius: 50%;
    &:hover {
        background: linear-gradient(180deg, rgba(50, 58, 69, 0.1), rgb(8 102 230 / 24%), rgb(6 43 94 / 32%)) no-repeat padding-box;
    }
    position: relative;
    .avatar {
        cursor: pointer;
    }
}
.top-bar {
    padding: 10px 50px;
    display: flex;
    align-items: center;
    justify-content: stretch;
    flex-direction: row;
    background: rgb(255 255 255 / 70%);
    position: relative;
    height: 60px;
    border-bottom: 1px solid rgb(228 228 228 / 50%);
    border-bottom: 0;

    .site-name {
        font-weight: 600;
        font-size: 20px;
        flex-shrink: 1;
        flex-grow: 1;
        margin-right: 18px;
    }
    .user-info-wrap {
        flex-shrink: 0;
        flex-grow: 0;
        line-height: 0;
        display: flex;
        align-items: center;
        .n-skeleton.avatar-loading {
            margin-right: 20px;
        }
        .username {
            display: block;
            margin: 0 20px;
        }
        .dropdown-wrap {
            box-shadow: 0px 6px 20px rgb(61 115 212 / 9%);
            border-radius: 10px;
            // width: 38px;
            // height: 38px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .dropdown-wrap {
        position: absolute;
        top: 50px;
        right: 0;
        z-index: 99;
        user-select: none;
        width: 280px;
        .dropdown-btn {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .dropdown-popup {
            // background: #fcfdff;
            // box-shadow: 0px 6px 20px rgb(61 115 212 / 9%);
            border-radius: 10px;
            border-radius: 12px;
            // box-shadow: 0 5px 20px rgb(0 0 0 / 10%);
            border-radius: 12px;
        }
        .dropdown-menu {
            list-style: none;
            padding: 20px;
            border: 1px solid rgb(166 200 255 / 50%);
            box-shadow: 0 5px 20px rgb(0 0 0 / 10%);
            background-color: white;
            border-radius: 12px;
        }
        .dropdown-menu-item {
            border-radius: 5px;
            text-decoration: none;
            .help.menu-text-wrap {
                color: #0c0c0c;
            }
            .icon-wrap {
                margin-right: 5px;
            }
            display: flex;
            align-items: center;
            padding: 5px;
            transition: all 0.3s;
            height: 40px;
            line-height: 40px;
            cursor: pointer;
            &:hover {
                background: #e8f0ff;
            }
            &.login {
                background: rgba(201, 77, 71, 0.1);
                color: #EB6E68;
                text-align: center;
                justify-content: center;
                margin-top: 15px;
                border-radius: 5px;
            }
        }
    }
}
</style>
