import { ITemplateInfo } from '@/service/siteService'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { ref } from 'vue'

/** for saving data during site creation */
export const useCreateStore = defineStore('create', () => {

    /**
     * Reative Objects
     */
     const currentStep = ref<1|2|3|4>(1)
     const selectedTemplateId = ref<number>(0)
     const brandName = ref<string>("")
     const domain = ref<string>("")
     const selectedRecomProductIds = ref<number[]>([])
     const couponCode = ref<string>("")
     const orderId = ref<number>(0)
     const blogId = ref<number>(0)
     const isOrderPaid = ref<boolean>(false)
     const pid = ref<number>(0) // for service type order
     const totalPrice = ref<number>(0)
     const finalTotalPrice = ref<number>(0)
     const saving = ref<number>(0)
     const orderPricingOjb = ref<{
        totalPrice: number,
        finalTotalPrice: number,
        saving: number
    }>({
        totalPrice: 0,
        finalTotalPrice: 0,
        saving: 0
    })

    /**
     * Actions
     */
    const setIsOrderPaid = (payload: boolean) => {
        isOrderPaid.value = payload
    }
    const setPid = (payload: number) => {
        pid.value = payload
    }
    const setBlogId = (payload: number) => {
        blogId.value = payload
    }
    const setCurrentStep = (payload: 1|2|3|4) => {
        currentStep.value = payload
    }
    const setSelectedTemplateId = (payload:number) => {
        selectedTemplateId.value = payload
    }
    const setBrandName = (payload: string) => {
        brandName.value = payload
    }
    const setDomain = (payload: string) => {
        domain.value = payload
    }
    const setSelectedRecomProductIds = (payload: number[]) => {
        selectedRecomProductIds.value = payload
    }
    const setCouponCode = (payload: string) => {
        couponCode.value = payload
    }
    const reset = () => {
        currentStep.value = 1
        selectedTemplateId.value = 0
        brandName.value = ""
        domain.value = ""
        selectedRecomProductIds.value = []
        couponCode.value = ""
        orderId.value = 0
        blogId.value = 0
        isOrderPaid.value = false
        totalPrice.value = 0
        finalTotalPrice.value = 0
        saving.value = 0
        orderPricingOjb.value = {
            totalPrice: 0,
            finalTotalPrice: 0,
            saving: 0
        }
    }

    /**
     * Expose State API
     */
    return {
        totalPrice,
        finalTotalPrice,
        saving,
        currentStep,
        setCurrentStep,
        brandName,
        domain,
        selectedTemplateId,
        selectedRecomProductIds,
        couponCode,
        orderPricingOjb,
        orderId,
        blogId,
        pid,
        isOrderPaid,
        setIsOrderPaid,
        setPid,
        reset,
        setCouponCode,
        setBlogId,
        setSelectedRecomProductIds,
        setSelectedTemplateId,
        setBrandName,
        setDomain
    }
})
