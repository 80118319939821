<script setup lang="ts">
import { NCarousel, NIcon, NModal, NButton } from 'naive-ui'
import { useRouter } from 'vue-router';
import {Checkmark16Filled} from '@vicons/fluent'
import { ref } from 'vue';
import SnImage from '@/components/SnImage.vue'


const router = useRouter()
const showConsultPopup = ref(false)
const viewTemplate = () => {
    router.push({
        name: 'create'
    })
}

</script>

<template>

    <div class="main-stage no-website-wrap">
        <div class="consult">
            <h2 class="heading">向专家咨询</h2>
            <div class="des">不知道如何选择合适您行业的范例网站？让我们的顾问来帮助您！</div>
            <div class="feature-wrap">
                <div class="feature-item">
                    <div class="icon-wrap">
                        <n-icon size="30">
                            <Checkmark16Filled :color="'#4B93FE'"/>
                        </n-icon>
                    </div>
                    <div class="feature-text">各行业范例网站介绍</div>
                </div>
                <div class="feature-item">
                    <div class="icon-wrap">
                        <n-icon size="30">
                            <Checkmark16Filled :color="'#4B93FE'"/>
                        </n-icon>
                    </div>
                    <div class="feature-text">产品行业流量分析</div>
                </div>
                <div class="feature-item">
                    <div class="icon-wrap">
                        <n-icon size="30">
                            <Checkmark16Filled :color="'#4B93FE'"/>
                        </n-icon>
                    </div>
                    <div class="feature-text">渠道推广建议</div>
                </div>
                <div class="feature-item">
                    <div class="icon-wrap">
                        <n-icon size="30">
                            <Checkmark16Filled :color="'#4B93FE'"/>
                        </n-icon>
                    </div>
                    <div class="feature-text">网站进度建议</div>
                </div>
            </div>
            <div class="cta-wrap">
                <!-- <a href="#" @click.prevent="()=>{showConsultPopup = true}" class="btn cta-btn">联系顾问</a> -->
                <n-button @click.prevent="()=>{showConsultPopup = true}" size="large" block type="primary">联系顾问</n-button>
            </div>
        </div>
        <div class="template">
            <div class="temp-left">
                <h2 class="heading">选择独立站范例网站</h2>
                <div class="des">一个好网站，等于一张好名片，各种风格设计，满足您的需求</div>
                <div class="feature-wrap">
                    <div class="feature-item">
                        <div class="icon-wrap">
                            <n-icon size="20">
                                <Checkmark16Filled :color="'#9CDFC3'"/>
                            </n-icon>
                        </div>
                        <div class="feature-text">欧美设计原创设计</div>
                    </div>
                    <div class="feature-item">
                        <div class="icon-wrap">
                            <n-icon size="20">
                                <Checkmark16Filled :color="'#9CDFC3'"/>
                            </n-icon>
                        </div>
                        <div class="feature-text">针对行业，产品风格</div>
                    </div>
                    <div class="feature-item">
                        <div class="icon-wrap">
                            <n-icon size="20">
                                <Checkmark16Filled :color="'#9CDFC3'"/>
                            </n-icon>
                        </div>
                        <div class="feature-text">针对外国客户审美习惯</div>
                    </div>
                    <div class="feature-item">
                        <div class="icon-wrap">
                            <n-icon size="20">
                                <Checkmark16Filled :color="'#9CDFC3'"/>
                            </n-icon>
                        </div>
                        <div class="feature-text">可视化编辑，灵活调整</div>
                    </div>
                    <div class="feature-item">
                        <div class="icon-wrap">
                            <n-icon size="20">
                                <Checkmark16Filled :color="'#9CDFC3'"/>
                            </n-icon>
                        </div>
                        <div class="feature-text">手机适配一流</div>
                    </div>
                </div>
                <div class="cta-wrap">
                    <n-button @click="viewTemplate" size="large" block type="primary">浏览精美范例网站</n-button>
                </div>
            </div>
            <div class="temp-right">
                <div class="slider-wrap" style="width: 296px; margin-left: 35px;">
                    <n-carousel
                        showArrow
                        autoplay
                        dot-placement="bottom"
                        style="width: 100%; height: auto"
                    >
                        <img class="carousel-img" src="https://st-img-md.oss-cn-guangzhou.aliyuncs.com/20220505165842.png" />
                        <img class="carousel-img" src="https://st-img-md.oss-cn-guangzhou.aliyuncs.com/20220505165903.png" />
                        <img class="carousel-img" src="https://st-img-md.oss-cn-guangzhou.aliyuncs.com/20220505165939.png" />
                        <img class="carousel-img" src="https://st-img-md.oss-cn-guangzhou.aliyuncs.com/20220505165956.png" />
                    </n-carousel>
                </div>
            </div>
        </div>
        <n-modal :mask-closable=false v-model:show="showConsultPopup">
            <div class="consult-popup-inner">
                <div class="close-consult-popup-link" @click="() => {showConsultPopup = false}">
                    <n-icon size="40">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512"><path d="M289.94 256l95-95A24 24 0 0 0 351 127l-95 95l-95-95a24 24 0 0 0-34 34l95 95l-95 95a24 24 0 1 0 34 34l95-95l95 95a24 24 0 0 0 34-34z" fill="currentColor"></path></svg>
                    </n-icon>
                </div>
                <sn-image  src="https://st-img-md.oss-cn-guangzhou.aliyuncs.com/20220424141246.png" width="330" height="330" />
                <p class="consult-code-des">扫码咨询或直接联系您的业务了解详情</p>
            </div>
        </n-modal>
    </div>

</template>

<style lang="scss" scoped>
    .consult-popup-inner {
        background: #FFFFFF;
        border-radius: 20px;
        padding: 40px;
        position: relative;
        .consultant-code {
            margin: 0 auto;
        }
        .close-consult-popup-link {
            position: absolute;
            right: 15px;
            top: 15px;
            width: 30px;
            height: 30px;
            cursor: pointer;
        }
        .consult-code-des {
            font-size: 18px;
            color: #333;
            text-align: center;
            margin-top: 25px;
        }
    }
.no-website-wrap {
    grid-template-columns: 1fr 2fr !important;
    display: flex;
    align-items: center;
    max-width: 1300px;
    .cta-wrap {
        padding-top: 40px;
    }
    .icon-wrap {
        width: 40px;
        height: 40px;
        background-color: #EDF4FF;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .template {
        .icon-wrap {
            width: 28px;
            height: 28px;
            border-radius: 50%;
            background-color: #E6F8F2;
        }
    }
    .template {
        .temp-left {
            padding-right: 50px;
        }
        .temp-right {
            display: flex;
            align-items: center;
        }
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    & > div {
        padding: 35px 35px 40px 35px;
        .heading {
            font-size: 30px;
            font-weight: 600;
            color: #333333;
        }
        .des {
            color: rgba(51, 51, 51, 0.4);
            font-size: 14px;
            margin-top: 30px;
            margin-bottom: 30px;
        }
        .feature-item {
            display: flex;
            align-items: center;
            margin-bottom: 20px;
        }
        .icon-wrap {
            margin-right: 20px;
        }
        .btn.cta-btn {
            background: #3e8bff;
            border-radius: 10px;
            display: block;
            color: white;
            font-size: 20px;
            text-decoration: none;
            text-align: center;
            padding: 10px;
            margin-top: 60px;
        }
    }
}
</style>
