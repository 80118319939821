import { computed, reactive, ref } from "vue";
import { useBoolean } from "../common";
import { useStorage } from "@vueuse/core";
import MarketingService from "@/service/marketingService";
import useLoading from "../common/useLoading";

export default function useMarketing() {
  const { loading, startLoading, endLoading } = useLoading();
  const marketingOjb = useStorage("snmkt", {
    backgroundImg: "https://st-img-md.oss-cn-guangzhou.aliyuncs.com/20220906163147.png", // default settings
    title: "",
    des: "",
    ctaText: "",
    ctaLink: "",
    externalLink: false,
  });

  const refreshMaketing = async () => {
    startLoading();
    const newMkt: any = await MarketingService.getMarketing();
    marketingOjb.value = newMkt;
    endLoading();
  };

  return {
    marketingOjb,
    refreshMaketing,
    loading,
  };
}
