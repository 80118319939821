<script lang="ts" setup>
import TopBar from "@/components/TopBar.vue";
import { useWebsiteStore } from "@/stores/website";
import { useMessage, NGrid, NGi, NCheckboxGroup, NCheckbox, NSpace, NPagination, NSpin, NEmpty, NCard, NIcon, NPopover, NButton, NCarousel } from "naive-ui";
const websiteState = useWebsiteStore()
const message = useMessage()
import {onMounted, ref} from "vue";
import {RouterLink} from 'vue-router';
import AcademyService from "@/service/AcademyService";
import useOneSitePermission from "@/hooks/business/useOneSitePermission";
useOneSitePermission('营销学堂')

/**
 * State
 */
const loadingBanners = ref(false)
const loadingCourses = ref(false)
const bannerList: any = ref([])
const courseList: any = ref([])

/**
 * methods
 */
const fetchingBanners = async() => {
  loadingBanners.value = true
  try {
    const result = await AcademyService.getBannerList()
    bannerList.value = result
  } catch (e) {
    window.$message?.error('请求出错')
  }
  loadingBanners.value = false
}

const fetchingCourses = async () => {
  loadingCourses.value = true
  try {
    const result = await AcademyService.getCourseList()
    courseList.value = result
  } catch (e) {
    window.$message?.error('请求出错')
  }
  loadingCourses.value = false
}

/**
 * Hooks
 */
onMounted(() => {
  fetchingBanners()
  fetchingCourses()
})
</script>

<template>
    <div class="main-container">
        <top-bar use-brand-title custom-title="超新星营销学堂" />
      <perfect-scrollbar>
        <div class="learning-center-wrap" style="padding-bottom: 100px;">
          <div class="container">
            <template v-if="loadingBanners">
              <n-skeleton height="440px" width="100%" style="margin-bottom:30px;" />
<!--              <n-skeleton text :repeat="10" />-->
            </template>
            <template v-else>
              <div class="academy-banner-wrap">
                <template v-if="bannerList.length === 1">
                  <template v-if="bannerList[0].isLinkExternal">
                    <a :href="bannerList[0].link" target="__blank">
                      <img
                          class="carousel-img"
                          :src="bannerList[0].cover"
                      >
                    </a>
                  </template>
                  <template v-else>
                    <router-link :to="bannerList[0].link">
                      <img
                          class="carousel-img"
                          :src="bannerList[0].cover"
                      >
                    </router-link>
                  </template>
                </template>
                <template v-else>
                  <n-carousel autoplay loop>
                    <template v-for="item in bannerList" :key="item.id">
                      <template v-if="item.isLinkExternal">
                        <a :href="item.link" target="__blank">
                          <img
                              class="carousel-img"
                              :src="item.cover"
                          >
                        </a>
                      </template>
                      <template v-else>
                        <router-link :to="item.link">
                          <img
                              class="carousel-img"
                              :src="item.cover"
                          >
                        </router-link>
                      </template>
                    </template>
                  </n-carousel>
                </template>
              </div>
            </template>
<!--            <template v-if="loadingCourses">-->
<!--              <div style="margin-top: 30px;">-->
<!--                <n-skeleton text :repeat="10" />-->
<!--              </div>-->
<!--            </template>-->
<!--            <template>-->
              <div class="system-course-wrap">
                <div class="title">
                  系统课程
                </div>
                <div class="courses-wrap">
                  <template v-if="loadingCourses">
                    <n-skeleton text :repeat="8" />
                  </template>
                  <template v-else>
                    <n-grid cols="12" x-gap="30" y-gap="30">
                      <template v-for="item in courseList" :key="item.id">
                        <n-gi span="4">
                          <router-link :to="`/sacademy/${item.id}`" class="course-item-link">
                            <div class="course-item-wrap">
                              <div class="course-cover-wrap">
                                <img :src="item.cover">
                              </div>
                              <h2 class="course-title">{{ item.title }}</h2>
                            </div>
                          </router-link>
                        </n-gi>
                      </template>
                    </n-grid>
                  </template>
                </div>
              </div>
<!--            </template>-->
          </div>
        </div>
      </perfect-scrollbar>
    </div>
</template>

<style lang="scss" scoped>
.courses-wrap :deep(.n-skeleton) {
  margin-bottom: 5px;
}
.academy-banner-wrap {
  margin-bottom: 30px;
  border-radius: 10px;
  overflow: hidden;
}
.carousel-img {
  width: 100%;
  height: 448px;
  object-fit: cover;
}
.learning-center-wrap {
  padding-top: 20px;
  .title, .courses-wrap {
    font-size: 16px;
    padding: 20px;
    border-bottom: 1px solid #e5e7eb;
    line-height: 1;
  }
}
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 10px;
  box-sizing: content-box;
}
.system-course-wrap{
  background: #fcfdff;
  border-radius: 10px;
}
.section-title {
  font-size: 16px;
  padding: 20px;
  border-bottom: 1px solid #e5e7eb;
  line-height: 1;
}
.section-detail {
  padding: 20px;
}
.course-title {
  padding: 20px 10px;
  text-align: center;
  font-size: 16px;
}
.course-item-wrap {
  transition: all 0.3s;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 15px 50px rgb(12 51 109 / 6%);
}
.course-item-wrap:hover {
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 6%), 0 20px 40px 0 rgb(255 248 248 / 6%);
  transform: translate3d(0,-8px,0);
}
.courses-wrap {
  border-bottom: 0!important;
}
</style>
