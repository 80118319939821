interface IInstallmentData {
    pricePerMonth : number  // 每期费用
    feePerMonth: number  // 每期手续费
    totalPerMonth: number // 每期总数
    totalAllMonth: number // 总手续费
}

interface IHBPricing {
    3: IInstallmentData,
    6: IInstallmentData,
    12: IInstallmentData
}

const calHbByPrice = (price: number) : IHBPricing => {
    const pricingResult = {
        3: getInstallmentDetail(price, 3),
        6: getInstallmentDetail(price, 6),
        12: getInstallmentDetail(price, 12)
    }
    return pricingResult
}

const getInstallmentDetail = (price: number, installment: number): IInstallmentData => {
    const result = {
        pricePerMonth : 0,  // 每期费用
        feePerMonth: 0,  // 每期手续费
        totalPerMonth: 0, // 每期总数
        totalAllMonth: 0 // 总手续费
    }

    const fqNum = installment
    const sellerPercent = installment === 3 ? 100 : 0
    const amount = price
    let rate = 0 // depending on installment

    // user pay
    if (sellerPercent == 0) {
        switch (fqNum) {
            case 3:
                rate = 0.023
                break;
            case 6:
                rate = 0.045
                break;
            case 12:
                rate = 0.075
                break;
        }
    } else {
        switch (fqNum) {
            case 3:
                rate = 0.018
                break;
            case 6:
                rate = 0.045
                break;
            case 12:
                rate = 0.075
                break;
        }
    }

    var totalFee = amount * rate
    var roundedTotalFee = Math.round(totalFee * 100)/100 // ROUND_HALF_EVEN
    var eachFee = Math.floor(roundedTotalFee / fqNum * 100)/100 //向下取整
    var eachPrin = Math.floor(amount / fqNum * 100)/100
    let eachPrinAndFee = eachFee + eachPrin
    eachPrinAndFee = Number(eachPrinAndFee.toFixed(2)) // JS 精度问题

    // var info = '  每期费用: ' + eachPrin + ' , 每期手续费: ' + eachFee + ' , 每期总数: ' + eachPrinAndFee + ' , 总手续费: ' + roundedTotalFee
    
    result.pricePerMonth = eachPrin
    result.feePerMonth = eachFee
    result.totalPerMonth = eachPrinAndFee
    result.totalAllMonth = roundedTotalFee

    return result
}

const getShortUUID = () => {
    // I generate the UID from two parts here 
    // to ensure the random number provide enough bits.
    var firstPart = (Math.random() * 46656) | 0;
    var secondPart = (Math.random() * 46656) | 0;
    const firstPartNew = ("000" + firstPart.toString(36)).slice(-3);
    const secondPartNew = ("000" + secondPart.toString(36)).slice(-3);
    return firstPartNew + secondPartNew;
}

const toBase64 = (file: any) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

const decodeHtml = (html: any) => {
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
}

// obj to query string
const serialize = (obj: any) => {
    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    return str.join("&");
  }

export {
    IHBPricing,
    decodeHtml,
    calHbByPrice,
    getShortUUID,
    toBase64,
    serialize
}