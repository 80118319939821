<script setup lang="ts">
import { NDrawer, NScrollbar, useMessage, NSkeleton, NPopover, NCarousel, NStep, NSteps, NSpace, NGrid, NGi, NModal, NCard } from 'naive-ui'
import { useCreateStore } from '@/stores/create';
import { useTemplateStore } from '@/stores/template';
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter()
const createState = useCreateStore()
const templateState = useTemplateStore()
const activeCate = ref("all")
const actvieTemplates = computed(() => activeCate.value === 'all' ? templateState.templates :
    templateState.templates.filter(template => template.tags.includes(activeCate.value)))

const contentRef = ref<(HTMLElement) | null>(null);

const scrollMaxHeight = computed(() => {
  if (!contentRef.value) return 200;
  return window.innerHeight - contentRef.value.getBoundingClientRect()?.top - 10 - 20;
})

const changeActiveCate = (cate: string) => {
    activeCate.value = cate
}
const selectTemplate = (id: number) => {
    createState.setSelectedTemplateId(id)
    router.push({
        name: "createStep",
        params: {
            step: 2
        }
    })
}

</script>

<template>
    <div class="step step-template" v-show="createState.currentStep === 1">
        <div class="template-tab">
            <n-scrollbar x-scrollable>
                <div class="tab-wrap">
                    <div
                        class="tab-item"
                        @click="changeActiveCate('all')"
                        :class="activeCate === 'all' ? 'active' : ''"
                    >所有范例网站</div>
                    <div
                        class="tab-item"
                        @click="changeActiveCate(cate)"
                        v-for="cate in templateState.templateCates"
                        :class="activeCate === cate ? 'active' : ''"
                    >{{ cate }}</div>
                </div>
            </n-scrollbar>
        </div>
        <div class="template-content" ref="contentRef">
          <n-scrollbar :style="`max-height: ${scrollMaxHeight}px`">
            <n-grid style="padding: 0 20px;" x-gap="30" y-gap="30" :cols="4">
                  <n-gi v-for="temp in actvieTemplates">
                      <div class="template-item-wrap">
                          <div class="image-wrap">
                              <img :src="temp.screenshot" />
                          </div>
                          <div class="hover-overlay">
                              <div class="filter"></div>
                              <a :href="`https://msupernova.com/?template-preview=${temp.id}&shortcode`" target="_blank" class="preview-wrap">

                              </a>
                              <div class="cta-wrap" @click="selectTemplate(temp.id)">选用此范例网站</div>
                          </div>
                      </div>
                  </n-gi>
              </n-grid>
          </n-scrollbar>
        </div>
    </div>
</template>

<style scoped lang="scss">
.step-template :deep(.n-scrollbar) {
  &  > .n-scrollbar-rail.n-scrollbar-rail--horizontal {
    bottom: 0px;
  }
}
.step-template {
  background-color: white;
  margin-top: 30px;
  border-radius: 10px;
  border: 1px solid #f1f1f1;
  padding-bottom: 20px;

    .tab-wrap {
        display: flex;
        white-space: nowrap;
        padding-bottom: 15px;
    }
    .tab-item {
        font-size: 16px;
        color: rgba(51, 51, 51, 0.3);
        padding: 10px 15px;
        margin-right: 15px;
        cursor: pointer;

        &.active {
            background: linear-gradient(180deg, #408cff 0%, #7abcf9 100%);
            border-radius: 10px;
            color: #fcfdff;
        }
    }
    .template-tab {
        margin-bottom: 20px;
        border-bottom: 1px solid #f1f1f1;
        padding: 20px 20px 0 20px;
    }
    .hover-overlay {
        display: none;
    }
    .template-item-wrap {
        position: relative;
        max-width: 296px; // 图片最宽也就这个
        margin: 0 auto;
        padding: 8px;
        background-color: #60a7fc;
        border-radius: 10px;

        .image-wrap {
            transition: all 0.3s;
        }
        .cta-wrap {
            opacity: 0;
            transition: all 0.8s;
            left: 0;
        }
        &:hover {
            transition: all 0.3s;
            .image-wrap {
                filter: opacity(0.8) blur(2px);
            }
            .hover-overlay {
                display: block;
            }
            .cta-wrap {
                opacity: 1;
            }
        }
    }
    .filter {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .cta-wrap {
        position: absolute;
        bottom: 0;
        background: linear-gradient(180deg, #408cff 0%, #7abcf9 100%);
        border-radius: 0px 0px 20px 20px;
        width: 100%;
        height: 66px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        color: white;
        cursor: pointer;
        opacity: 0;
        transition: all 0.3s;
    }
    .preview-wrap {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -80%);
        // background: rgba(252, 253, 255, 0.2);
        background-image: url(https://sn-platform-app.oss-cn-guangzhou.aliyuncs.com/static/%E4%BA%86%E8%A7%A3%E6%9B%B4%E5%A4%9A2-%E8%93%9D.png);
        background-size: cover;
        padding: 10px;
        border-radius: 50%;
        cursor: pointer;
        width: 50px;
        height: 50px;
    }
}
</style>
