<script lang="ts" setup>

import {useCrmStore} from "@/stores/crm";
import {computed, onMounted, ref, watch} from "vue";
import {useWebsiteStore} from "@/stores/website";
import {FormInst, FormRules, NButton, useMessage, NForm, NFormItem, NInput} from "naive-ui";
import CrmService from "@/service/CrmService";
import {useLoading} from "@/hooks/common";

const crmStore = useCrmStore()
const websiteStore = useWebsiteStore()
const showModal = ref(false)
const formRef = ref<FormInst | null>(null)
const crmInfo = ref({
  tenantCompanyName: '',
  tenantPlan: '',
  tenantValueTill: '',
})
const message = useMessage()
const {loading: authLoading, startLoading: authStartLoading, endLoading: authEndLoading} = useLoading(false)

const onNextButtonClick = () => {
  crmStore.currentStep = 2
}

const onAuthClick = () => {
  showModal.value = true
}

const handleCrmAuth = async () => {
  try {
    authStartLoading()
    const authRes: any = await CrmService.authCrm(modelRef.value.email, modelRef.value.password)
    crmInfo.value.tenantCompanyName = authRes.tenantCompanyName
    crmInfo.value.tenantPlan = authRes.tenantPlan
    crmInfo.value.tenantValueTill = authRes.tenantValueTill
    crmStore.initPlatformToken = authRes.token
    crmStore.initCrmTenant = authRes.tenant
    crmStore.initCrmTenantCompanyName = authRes.tenantCompanyName
    authEndLoading()
    message.success('授权成功')
    showModal.value = false
    modelRef.value.email = ''
    modelRef.value.password = ''
  } catch (e: any) {
    message.error(e.message)
    authEndLoading()
  }
}

const onValidateClick = () => {
  formRef.value?.validate((errors) => {
    if (!errors) {
      // todo: get token
      handleCrmAuth()
    } else {
      message.error('验证失败')
    }
  })
}

watch(() => options, () => {
  if (options.value.length > 0) {
    crmStore.initSiteId = options.value[0].value
  }
})

const isNextDisable = computed(() => {
  return !(crmStore.initSiteId !== null && crmStore.initPlatformToken !== '')
})

const options = computed(() => {
  const optionArr = []
  for (const websiteItem of websiteStore.websites) {
    if (websiteItem.blogId !== 0) {
      optionArr.push({
        value: websiteItem.blogId,
        label: `${websiteItem.customDomain || websiteItem.siteurl} - (${websiteItem.name})`
      })
    }
  }
  return optionArr
})

const modelRef = ref({
  email: '',
  password: '',
})

const rules: FormRules = {
  email: [
    {
      required: true,
      message: '请输入正确的邮箱',
      pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
      trigger: ['input', 'blur']
    }
  ],
  password: [
    {
      required: true,
      message: '请输入正确的密码',
      min: 6,
      trigger: ['input', 'blur']
    }
  ],
}

</script>

<template>
<div class="step-auth-wrap">

  <n-modal v-model:show="showModal">
    <n-card
        style="width: 600px"
        :bordered="false"
        size="huge"
        role="dialog"
        aria-modal="true"
    >
      <div class="modelContent">
        <h2 class="login-title">请使用超级管理员账号授权</h2>
        <n-form ref="formRef" :model="modelRef" :rules="rules">
          <n-form-item path="email" label="用户邮箱">
            <n-input v-model:value="modelRef.email" @keydown.enter.prevent />
          </n-form-item>
          <n-form-item path="password" label="用户密码">
            <n-input
                v-model:value="modelRef.password"
                type="password"
                @keydown.enter.prevent
            />
          </n-form-item>
        </n-form>
        <n-button @click="onValidateClick" :loading="authLoading" type="primary">验证</n-button>
      </div>
    </n-card>
  </n-modal>

  <div class="auth-center-wrap">
    <div class="left">
      <div class="h2">网站</div>
      <n-select v-model:value="crmStore.initSiteId" :options="options" />
    </div>
    <div class="middle"> -> </div>
    <div class="right">
      <div class="h2">CRM</div>
      <n-button type="primary" @click="onAuthClick" v-if="!crmStore.initPlatformToken">点击授权CRM</n-button>
      <template v-if="crmStore.initPlatformToken">
        <div class="crm-info-row">
          企业: {{crmInfo.tenantCompanyName}}
        </div>
        <div class="crm-info-row">
          套餐: {{crmInfo.tenantPlan}}
        </div>
        <div class="crm-info-row">
          期限: {{crmInfo.tenantValueTill}}
        </div>
      </template>
    </div>
  </div>
    <div class="next-button-wrap">
      <n-button @click="onNextButtonClick" :disabled="isNextDisable" type="primary" size="large">下一步</n-button>
    </div>
</div>
</template>

<style lang="scss" scoped>
.step-auth-wrap {
  max-width: 800px;
  margin: 0 auto;
  padding-top: 20px;
}

.auth-center-wrap {
  display: flex;
  align-items: center;
}

.left {
  width: 40%;
}

.middle {
  width: 10%;
  text-align: center;
}

.right {
  width: 40%;
}

.h2 {
  padding-bottom: 5px;
}

.next-button-wrap {
  margin-top: 35px;
}

.login-title {
  text-align: center;
  font-size: 18px;
}
</style>
