<script setup lang="ts">

import { useWebsiteStore } from '@/stores/website';
import { getAppBase } from '@/config';

const websiteState = useWebsiteStore()

</script>

<template>
    <a :href="`${getAppBase()}snlogin/${websiteState.currentWebsiteId}/`" target="_blank" class="one site-management-wrap">
        <div class="icon-wrap">
            <div class="icon-bg"></div>
        </div>
        <h2 class="action-title">站点管理</h2>
        <div class="action-des">查看网站数据，编辑站点内容</div>
        <div class="arrow-wrap">
            
        </div>
    </a>
</template>

<style scoped lang="scss">
.bind-domain-wrap,
.site-management-wrap {
        background-size: cover;
        background-repeat: no-repeat;
    .icon-bg {
        background-image: url(https://sn-platform-app.oss-cn-guangzhou.aliyuncs.com/static/site-dark.png)
    }
    &:hover {
        background-size: cover;
        background-repeat: no-repeat;
        background-image: url('https://sn-platform-app.oss-cn-guangzhou.aliyuncs.com/static/%E7%AB%99%E7%82%B9%E7%AE%A1%E7%90%86%E8%83%8C%E6%99%AF%E8%89%B2%E5%9D%97.png');
        // background: #3c89ff;
        .action-title {
            color: #fcfdff;
        }
        .action-des {
            color: #fcfdffcc;
        }
        .arrow-img-wrap {
            background: #fcfdff;
        }
        .arrow-wrap {
            // background: rgba(252, 253, 255, 0.2);
            background-image: url(https://sn-platform-app.oss-cn-guangzhou.aliyuncs.com/static/%E4%BA%86%E8%A7%A3%E6%9B%B4%E5%A4%9A1-%E7%99%BD.png);
        }
        .icon-bg {
            background-image: url(https://sn-platform-app.oss-cn-guangzhou.aliyuncs.com/static/site-light.png)
        }
    }
    transition: all 0.3s;
    padding: 50px;
    position: relative;
    .icon-wrap {
        background: rgba(61, 115, 212, 0.08);
        border-radius: 30px;
        width: 80px;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .action-title {
        font-weight: 600;
        font-size: 30px;
        color: #333333;
        margin-top: 30px;
        margin-bottom: 10px;
    }
    .action-des {
        font-size: 16px;
        color: rgba(51, 51, 51, 0.4);
    }
    .arrow-wrap {
        background-image: url(https://sn-platform-app.oss-cn-guangzhou.aliyuncs.com/static/%E4%BA%86%E8%A7%A3%E6%9B%B4%E5%A4%9A2-%E8%93%9D.png);
        width: 52px;
        height: 52px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        position: absolute;
        right: 30px;
        bottom: 40px;
    }
    .arrow-img-wrap {
        width: 36px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #408cff;
        border-radius: 50%;
    }
}

.icon-bg {
    width: 35px;
    height: 35px;
}
</style>
