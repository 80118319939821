interface lessonResultItem {
  id: string;
  course_id: string;
  cn_custom_order: string;
  coverimg: string;
  lessontitle: string;
  videolink: string;
  lessonnotes: string;
}

interface courseResultItem {
  id: string;
  cover: string;
  title: string;
  brief: string;
  detaildes: string;
}

interface bannerResultItem {
  id: string;
  cover: string;
  link: string;
  islinkexternal: string;
}

export default class AcademyService {
  private static bannerList: Academy.BannerItem[] = [];
  private static courseList: Academy.courseItem[] = [];
  private static lessonsList: Academy.lessonItem[] = [];

  public static async getLessonDetail(
    courseId: string,
    lessonId: string
  ): Promise<Academy.lessonItem> {
    const lessonList = await AcademyService.getAllLessonList();
    const filter = lessonList.filter((item) => item.lessonId === lessonId);

    // append url based on env
    const match = filter[0];
    await AcademyService.sleep(500);
    return match;
  }

  public static async getBannerList(): Promise<Academy.BannerItem[]> {
    if (AcademyService.bannerList.length > 0) {
      return AcademyService.bannerList;
    }

    const url = "https://snj.msupernova.com/mktConfig?action=getBanner";
    const res: bannerResultItem[] = await fetch(url, {
      headers: {
        "Content-Type": "application/json",
      },
      method: "GET", // *GET, POST, PUT, DELETE, etc.
    }).then((response) => response.json());
    // todo: transform and save cache before return

    return res.map((item) => ({
      id: item.id,
      cover: item.cover,
      link: item.link,
      isLinkExternal: item.islinkexternal == "N" ? false : true,
    }));
  }

  public static async getAllLessonList(): Promise<Academy.lessonItem[]> {
    if (AcademyService.lessonsList.length > 0) {
      return AcademyService.lessonsList;
    }

    const url = "https://snj.msupernova.com/mktConfig?action=getLessonList";
    const res: lessonResultItem[] = await fetch(url, {
      headers: {
        "Content-Type": "application/json",
      },
      method: "GET", // *GET, POST, PUT, DELETE, etc.
    }).then((response) => response.json());

    const transformed = res.map((item) => ({
      lessonId: item.id,
      lessonTitle: item.lessontitle,
      coverImg: item.coverimg,
      videoLink: window.location.href.includes("localhost")
          ? `/video${item.videolink}`
          : `https://video.msupernova.com${item.videolink}`,
      lessonNotes: item.lessonnotes,
      courseId: item.course_id,
    }));

    AcademyService.lessonsList = transformed;
    return transformed;
  }

  public static async getCourseList(): Promise<Academy.courseItem[]> {
    if (AcademyService.courseList.length > 0) {
      return AcademyService.courseList;
    }

    const url = "https://snj.msupernova.com/mktConfig?action=getCourseList";
    const res: courseResultItem[] = await fetch(url, {
      headers: {
        "Content-Type": "application/json",
      },
      method: "GET", // *GET, POST, PUT, DELETE, etc.
    }).then((response) => response.json());

    const transformed = res.map((item) => ({
      id: item.id,
      cover: item.cover,
      title: item.title,
      brief: item.brief,
      detailDes: item.detaildes,
    }));

    AcademyService.courseList = transformed;
    return transformed;
  }

  public static async getCourseData(
    courseId: string
  ): Promise<Academy.courseDetail> {
    const courseList = await AcademyService.getCourseList();
    const lessonList = await AcademyService.getAllLessonList();

    const lesson = courseList.filter((item) => item.id === courseId)[0];
    const matchedLessonList = lessonList.filter(
      (item) => item.courseId === courseId
    );
    await AcademyService.sleep(500);
    return {
      ...lesson,
      lessonList: matchedLessonList.map((item) => ({
        title: item.lessonTitle,
        lessonId: item.lessonId,
      })),
    };
  }

  private static sleep(ms: number) {
    return new Promise((resolve, reject) => {
      window.setTimeout(() => resolve(true), ms);
    });
  }
}
