import HttpService from "./httpService"

export default class OrderService {
    public static async calPrice(productIds: number[], coupon: string = ""): Promise<{
        "totalPrice": number,
        "finalTotalPrice": number
    }> {
        const url = "app/order/price"

        const data: any = {
            items: productIds.map(id => ({
                id,
                count: 1
            }))
        }
        if (coupon !== "") {
            data.couponCode = coupon
        }

        const res = await HttpService.post(url, data)
        if (res.code !== 0) {
            throw new Error(res.msg) 
        }
        return res.data
    }

    public static async placeOrder(productIds: number[], templateId: number, domain: string, brandName: string = "", couponCode: string = "", blogId: number = 0)
    : Promise<number> {
        const url = "app/order"

        const data: any = {
            // blogId: "string",
            blogTitle: brandName,
            // couponCode: couponCode,
            domain: domain,
            extend: "",
            items: productIds.map(id => ({
                id,
                count: 1
            })),
            orderNotes: "",
            templateId: String(templateId)
        }
        if (couponCode !== "") {
            data.couponCode = couponCode
        }

        if (blogId && blogId !== 0) {
            data.blogId = blogId
        }

        const res = await HttpService.post(url, data)
        if (res.code !== 0) {
            throw new Error(res.msg) 
        }
        return res.data
    }

    public static async payByOid(oid: number, selectedPaymentMethod: "WX" | "ALI" | "HB", selectedHBInstallment: 3|6|12): Promise<string> {
        const url = "app/order/pay"
        let paymentType = ""
        let fqNum = 0
        switch (selectedPaymentMethod) { 
            case "WX":
                paymentType = "WXPAY_NATIVE"
                break
            case "ALI":
                paymentType = "ALIPAY_PC"
                break
            case "HB":
                paymentType = "ALIPAY_PC"
                fqNum = selectedHBInstallment
                break
            default:
                break
        }

        const res = await HttpService.get(`${url}?oid=${oid}&paymentType=${paymentType}&fq=${fqNum}`)
        if (res.code !== 0) {
            throw new Error(res.msg) 
        }
        return res.data
    }

    public static async queryOrder(oid: number) {
        const url = `app/order/${oid}`

        const res = await HttpService.get(url)
        if (res.code !== 0) {
            throw new Error(res.msg) 
        }
        return res.data
    }

    public static async listOrder(statusStr: string, page: number = 1, limit: number = 10) {
        const url = `app/order/list?status=${statusStr}&page=${page}&limit=${limit}`

        const res = await HttpService.get(url)
        if (res.code !== 0) {
            throw new Error(res.msg) 
        }
        return res.page
    }
}