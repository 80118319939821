import { ITemplateInfo } from '@/service/siteService'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { ref } from 'vue'
import {IMappingConfig} from "@/service/CrmService";

/** for saving data during site creation */
export const useCrmStore = defineStore('crm', () => {

    /**
     * Reative Objects
     */
     const currentStep = ref<1|2|3>(1)
     const initSiteId = ref<number|null>(null)
     const initPlatformToken = ref<string>('')
     const initCrmTenant = ref<string>('')
    const initCrmTenantCompanyName = ref<string>('')
    const configModel = ref<IMappingConfig[]>([])


    /**
     * Actions
     */
    const resetCrmInitCreate = () => {
        currentStep.value = 1
        initSiteId.value = 0
        initPlatformToken.value = ''
        initCrmTenant.value = ''
        initCrmTenantCompanyName.value = ''
    }

    const reset = () => {
         currentStep.value = 1
         initSiteId.value = null
         initPlatformToken.value = ''
         initCrmTenant.value = ''
         configModel.value = []
    }

    /**
     * Expose State API
     */
    return {
        initCrmTenant,
        currentStep,
        initSiteId,
        initPlatformToken,
        configModel,
        initCrmTenantCompanyName,
        reset,
        resetCrmInitCreate
    }
})
