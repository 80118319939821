import { acceptHMRUpdate, defineStore } from 'pinia'
import { ref } from 'vue'

interface IProduct {
    id: number,
    productTitle: string,
    productDetail: string,
    unitPrice: number,
    productType: 1 | 2,
    serviceMonth: number,
    status: 0 | 1
}

export const useProductStore = defineStore('product', () => {
    const defaultProductList: IProduct[] = []

    /**
     * Reative Objects
     */
    const products = ref<IProduct[]>(defaultProductList)
    const loading = ref(true)

    /**
     * Actions
     */
    const setProducts = (payload: IProduct[]) => {
        products.value = payload
        loading.value = false
    }

    const getProductByIds = (ids: number[]) => {
        return products.value.filter(product=>ids.includes(product.id))
    }

    const getRecommendedProducts = () => {
        return getProductByIds([273, 274])
    }

    const getWebsiteProduct = () => {
        return getProductByIds([272])[0]
    }

    const getAdsProduct = () => {
        return getProductByIds([273])[0]
    }

    const getExtendProduct = () => {
        return getProductByIds([275])[0]
    }

    /**
     * Expose State API
     */
    return {
        products,
        loading,
        getExtendProduct,
        getAdsProduct,
        setProducts,
        getProductByIds,
        getRecommendedProducts,
        getWebsiteProduct
    }
})
