<script lang="ts" setup>

import { computed, onMounted, watchEffect } from "vue";
import { NStep, NSteps, NSpace } from 'naive-ui'
import { useWebsiteStore } from "@/stores/website";
import { useRoute } from "vue-router";
import TopBar from "@/components/TopBar.vue";
import SiteService from "@/service/siteService";
import ProuductService from "@/service/productService";
import { useProductStore } from "@/stores/product";
import router from "@/router";
import StepTemplate from "@/components/StepTemplate.vue";
import { useTemplateStore } from "@/stores/template";
import { useCreateStore } from "@/stores/create";
import StepBrand from "@/components/StepBrand.vue";
import StepOrder from "@/components/StepOrder.vue";
import StepPayment from "@/components/StepPayment.vue";
import { Logger } from "@/logger";

const templateState = useTemplateStore()
const websiteState = useWebsiteStore()
const productState = useProductStore()
const createState = useCreateStore()

const route = useRoute()

watchEffect(() => {
    const currentStep = route.params.step
    if (currentStep) {
        createState.setCurrentStep(Number(currentStep) as 1|2|3|4)
    }
})

const fetchTemplateData = async () => {
    if (templateState.loading === false) return

    const result = await SiteService.getTemplateData()
    templateState.setTemplateCates(result.categorys)
    templateState.setTemplates(result.templates)
}

const fetchProductData = async () => {

    if (productState.loading === false) return

    const products = await ProuductService.getAllProducts()
    productState.setProducts(products)
    const mainProduct = productState.getWebsiteProduct()
    createState.orderPricingOjb.totalPrice = mainProduct.unitPrice
    createState.orderPricingOjb.finalTotalPrice = mainProduct.unitPrice
    createState.totalPrice = mainProduct.unitPrice
    createState.finalTotalPrice = mainProduct.unitPrice
}

onMounted(() => {
    if (createState.orderId !== 0 && createState.isOrderPaid) {
            router.push({
                name: "PaymentSuccess",
                query: {
                    oid: createState.orderId,
                }
            })
    }

    fetchTemplateData()
    fetchProductData()
})

watchEffect(() => {
    const state = router.options.history.state
    if (state.pid) {
        Logger.debug("Product ID", state.pid)
        createState.setBlogId(websiteState.currentWebsiteId)
        createState.setPid(Number(state.pid))
        createState.setCurrentStep(3)
    } else {
        Logger.debug("Website Product", "No PID")
        // no pid passed for current route
        createState.setBlogId(0)
        // if we have PID before, we need to reset current step
        if (createState.pid && createState.pid !== 0) {
            createState.setCurrentStep(1)
            createState.setPid(0)
        }
    }
})

const currentStepComponent = computed(() => {
    switch (createState.currentStep) {
        case 1:
            return StepTemplate
        case 2:
            return StepBrand
        case 3:
            return StepOrder
        case 4:
            return StepPayment
    }
})

const onStepClick = (step: 1|2|3|4) => {
    if (createState.currentStep === 4) return // can't go back on step 4 because you cant change anything when order is placed
    if (step >= createState.currentStep) return
    createState.setCurrentStep(step)
    router.push({
        name: "createStep",
        params: {
            step,
        }
    })
}

</script>

<template>
    <div class="main-container">
        <top-bar use-brand-title />
        <perfect-scrollbar>
            <div class="main-stage-create-site">
                <div class="step-wrap" v-if="!createState.blogId">
                    <n-space vertical>
                        <n-steps :current="createState.currentStep" :status="'process'">
                            <n-step title="选择范例网站" class="pointer" @click="onStepClick(1)" />
                            <n-step title="补全品牌信息" class="pointer" @click="onStepClick(2)" />
                            <n-step title="确认订单" class="pointer" @click="onStepClick(3)" />
                            <n-step title="支付并开通站点" class="pointer" @click="onStepClick(4)" />
                        </n-steps>
                    </n-space>
                </div>
                <keep-alive>
                    <component :is="currentStepComponent"></component>
                </keep-alive>
            </div>
        </perfect-scrollbar>
    </div>
</template>


<style lang="scss">
.main-stage-create-site {
    max-width: 1400px;
    margin: 0 auto;
    width: 100%;
    padding-bottom: 10px;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 30px;

    .step-wrap {
        padding: 25px;
        border-radius: 20px;
        background-color: white;
        border: 1px solid #f1f1f1;
    }
}
.step {
    //padding-top: 40px;
}
.step-site-info,
.step-confirm-order,
.step-payment {
    background: #ffffff;
    border-radius: 20px;
    margin-top: 25px;
    padding: 25px 30px;
    border: 1px solid #f1f1f1;
    h2 {
        font-weight: 600;
        font-size: 20px;
        color: #333;
    }
}
.step-confirm-order,
.step-payment {
    .order-wrap {
        .row-header {
            font-size: 16px;
            color: #999;
            padding: 10px 0;
            margin-top: 10px;
            border-bottom: 1px solid rgba(51, 51, 51, 0.1);
        }
    }
    .order-wrap > div {
        display: flex;
        & > div:nth-child(1) {
            width: 30%;
        }
        & > div:nth-child(2) {
            width: 50%;
            padding-left: 30px;
            padding-right: 30px;
        }
        & > div:nth-child(3) {
            width: 20%;
        }
    }
    .row-product {
        font-size: 14px;
        color: #666;
        padding: 10px 0;
    }
    .recomd-wrap {
        margin-top: 50px;
        & > div {
            display: flex;
            & > div:nth-child(1) {
                width: 30%;
            }
            & > div:nth-child(2) {
                padding-left: 30px;
                padding-right: 30px;
                width: 50%;
            }
            & > div:nth-child(3) {
                width: 20%;
            }
        }
    }
    .product-recom-label {
        color: #76d6ae;
    }
    .add-to-order.btn {
        display: inline-block;
        border: 2px solid #3e8bff;
        box-sizing: border-box;
        border-radius: 10px;
        color: #3e8bff;
        padding: 10px 15px;
    }
    .price.recom {
        margin-bottom: 10px;
    }
    .coupon-wrap {
        max-width: 450px;
        width: 100%;
        background: #edf4ff;
        border-radius: 20px;
    }
    .cta-wrap {
        display: flex;
        justify-content: space-between;
    }
    .coupon-wrap {
        padding: 30px;
    }
    .coupon-title {
        font-weight: 600;
        font-size: 16px;
        line-height: 28px;
        color: #3e8bff;
        margin-bottom: 15px;
    }
    .coupon-input-wrap {
        display: flex;
        align-items: center;
        input {
            all: unset;
            border: none;
            background-image: none;
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;
            background: white;
            border-radius: 10px;
            font-size: 12px;
            padding: 10px 15px;
            color: #262c39;
        }
        .apply-coupon {
            background: #3e8bff;
            border-radius: 10px;
            font-size: 16px;
            margin-left: 10px;
            display: inline-block;
            color: white;
            padding: 10px 20px;
            cursor: pointer;
        }
    }
    .total-discount {
        color: #3e8bff;
        font-size: 18px;
    }
    .total-price {
        font-weight: 600;
        font-size: 36px;
        color: #333;
        margin-right: 10px;
    }
    .total-price-des {
        font-size: 16px;
        color: #666;
    }
    .cta-fix-container {
        margin-top: 30px;
    }
    .confirm-order-wrap {
        margin-top: 10px;
        .btn-confirm-order {
            background: #3e8bff;
            border-radius: 10px;
            color: white;
            font-size: 16px;
            padding: 10px;
            text-align: center;
            cursor: pointer;
        }
    }
}
.step-payment {
    .cta-fix-container {
        margin-top: 20px !important;
    }
    .payment-method,
    .fq-installment {
        display: grid;
        grid-column-gap: 10px;
        grid-template-columns: 1fr 1fr 1fr;
    }
    .payment-method {
        margin-bottom: 15px;
    }
    .coupon-wrap.payment-method-wrap {
        max-width: 650px;
        background: none;
        padding: 0 !important;
    }
    .cta-wrap {
        align-items: flex-end !important;
    }
    .payment-method-title {
        font-size: 24px;
        color: #333;
        font-weight: 600;
        margin-bottom: 25px;
    }

    .payment-method-item {
        cursor: pointer;
        background: rgba(232, 240, 255, 0.6);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 30px;
        border-radius: 10px;
    }
    .installment-item-wrap {
        text-align: center;
        background: rgba(232, 240, 255, 0.6);
        border-radius: 10px;
        padding: 30px 25px;
        font-size: 18px;
        color: #3e8bff;
        .payment-detail {
            font-weight: 600;
        }
        .payment-fee {
            font-size: 12px;
            color: #333;
            text-align: center;
            margin-top: 10px;
            margin-bottom: 10px;
        }
        .payment-cta {
            border: 1px solid #3e8bff;
            box-sizing: border-box;
            border-radius: 10px;
            font-size: 12px;
            text-align: center;
            display: inline-block;
            padding: 5px 10px;
            cursor: pointer;
        }
    }
    .payment-method-item, .installment-item-wrap {
        border: 2px solid transparent;
    }
    .active {
        border: 2px solid #3E8BFF;
    }
}

.payment-popup-card-wrap {
    .wechat-title {
        text-align: center;
    }
    .payment-cta-wrap {
        color: gray;
    }
    .payment-waiting-notice {
        color: black;
        font-size: 18px;
        font-weight: 600;
    }
    .payment-cta-wrap {
        text-align: center;
    }
    .payment-cta-wra-complete {
        max-width: 200px;
        margin: 0 auto;
        background-color: #1f1f1f;
        color: white;
        border-radius: 15px;
        padding: 5px 10px;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .payment-cta-wrap-reselect {
        margin: 0 auto;
        cursor: pointer;
        display: inline-block;
    }
    .processing-alipay {
        display: flex;
        justify-content: center;
        margin-bottom: 15px;
    }
    .wechat-pay-code {
        display: flex;
        justify-content: center;
        padding: 10px;
        img {
            max-width: 300px;
        }
    }
    .wechat-title {
        color: black;
        font-size: 18px;
        font-weight: 600;
    }
}
</style>
