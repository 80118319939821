<script lang="ts" setup>

import { onMounted, ref, watchEffect, h, reactive, computed } from "vue";
import { NSpace, NInput, NEllipsis, NCard, NCheckbox, NDataTable, NModal, NButton, NTag, useMessage, useDialog, NDatePicker } from 'naive-ui'
import UserService from "@/service/userService";
import { useWebsiteStore } from "@/stores/website";
import { useRoute, useRouter } from "vue-router";
import TopBar from "@/components/TopBar.vue";
import SiteService from "@/service/siteService";
import ProuductService from "@/service/productService";
import { useTemplateStore } from "@/stores/template";
import { useProductStore } from "@/stores/product";
import { useCreateStore } from "@/stores/create";
import LeadDetail from "@/components/LeadDetail.vue";
import LeadMap from "@/components/LeadMap.vue";
import { writeFileXLSX, utils } from "xlsx";
import { Logger } from "@/logger";
import { emailDomains } from "@/emailDomains";
import { decodeHtml } from "@/utils";



const websiteState = useWebsiteStore()
const router = useRouter()
const route = useRoute()

const templateState = useTemplateStore()
const productState = useProductStore()
const createState = useCreateStore()

const fetchTemplateData = async () => {
    if (templateState.loading === false) return // already loaded
    const result = await SiteService.getTemplateData()
    templateState.setTemplateCates(result.categorys)
    templateState.setTemplates(result.templates)
}

const fetchProductData = async () => {
    if (productState.loading === false) return // means product already loaded
    const products = await ProuductService.getAllProducts()
    productState.setProducts(products)
    const mainProduct = productState.getWebsiteProduct()
    createState.orderPricingOjb.totalPrice = mainProduct.unitPrice
    createState.orderPricingOjb.finalTotalPrice = mainProduct.unitPrice
    createState.totalPrice = mainProduct.unitPrice
    createState.finalTotalPrice = mainProduct.unitPrice
}
const exportingAll = ref(false)
const highQualityUpdating = ref(false)
const selectedKeys = ref<number[]>([])
const defaultPageSize = ref(10)
const leads = ref<any[]>([])
const leadMeta = ref<any[]>([])
const pagination = reactive({
    pageSize: defaultPageSize.value,
    page: 1,
    pageCount: 1
})
const loading = ref(true)
const showLeadDetail = ref(false)
const detailleadId = ref(0)
const detailLead = computed(() => {
    const match = leads.value.filter(lead => lead.ID == detailleadId.value)[0]
    match.form.formData = match.form.formData.map((item: any) => ({
        ...item,
        value: item.value.replaceAll('&lt;br/&gt;', '</br>')
    }))
    return match
})

// todo: use hooks for these
const isSEM = computed(() => {
    if (detailleadId.value === 0) return false
    const leadObj = leads.value.filter(lead => lead.ID == detailleadId.value)[0]

    const qs = leadObj.source_url.split('?').length > 1 ? leadObj.source_url.split('?')[1] : ''
    return qs.includes('gclid')
})

const queryArr = computed(() => {
    if (!isSEM) return []

    const leadObj = leads.value.filter(lead => lead.ID == detailleadId.value)[0]

    const qs = leadObj.source_url.split('?').length > 1 ? leadObj.source_url.split('?')[1] : ''
    const searchParams: any = new URLSearchParams(qs)
    const result = []
    for (let p of searchParams) {
        result.push(p)
    }
    return result
})

const queryArrShort = computed(() => {
    const includesKeys = ['keyword', 'gclid']
    return queryArr.value.filter((item: any) => {
        return includesKeys.includes(item[0])
    })
})

const isCurrentLeadHighQuality = ref(false)
const leadNoteUpdating = ref(false)
const leadNotes = ref('')
const batchUpdatingHighQuality = ref(false)
const message = useMessage()
const dialog = useDialog()

const fetchLeadsData = async (sid: number = 1, page: number = 1, range: [number, number]) => {
    loading.value = true
    const result = await UserService.leadsPlatform(sid, page, defaultPageSize.value, range)
    leads.value = result.data.map((lead: any) => ({
        ...lead,
        key: lead.ID
    }))

    pagination.pageSize = result.pageSize
    pagination.pageCount = result.totalPage
    pagination.page = result.currentPage
    loading.value = false

}


onMounted(() => {
    const sid = route.params.sid
    if (sid) {
        websiteState.setCurrentWebsite(Number(sid))
    }

    // init data for later use
    fetchTemplateData()
    fetchProductData()

    // fetchLeadMeta(Number(sid))
    fetchLeadsData(Number(sid), 1, range.value)
})

watchEffect(() => {
    const sid = route.params.sid
    if (sid) {
        websiteState.setCurrentWebsite(Number(sid))
    }
})

const createColumns = ({ showDetail }: any) => {
    return [
        {
            type: 'selection',
            fixed: 'left'
        },
        {
            title: '日期',
            key: 'date',
            fixed: 'left'
        },
        {
            title: '邮箱',
            key: 'title',
            fixed: 'left',
            render(row: any) {
                return h(
                    LeadDetail,
                    {
                        leadObj: leads.value.filter(lead => lead.ID == row.ID)[0],
                    },
                )
            }
        },
        {
            title: '国家',
            key: 'country'
        },
        {
            title: 'IP',
            key: 'ip'
        },
        {
            title: '设备',
            key: 'device'
        },
        {
            title: '操作系统',
            key: 'os'
        },
        {
            title: '浏览器',
            key: 'browser'
        },
        {
            title: '询盘质量',
            key: 'isQuality',
            render(row: any) {
                if (row.isQuality == 1) {
                    return h(
                        NTag,
                        {
                            type: 'success'
                        },
                        '高质量'
                    )
                }
            }
        },
        {
            title: '操作',
            key: 'actions',
            fixed: 'right',
            render(row: any) {
                return h(
                    NButton,
                    {
                        size: 'small',
                        onClick: () => showDetail(row)
                    },
                    { default: () => '详情' }
                )
            }
        }
    ]
}

const columns: any = createColumns({
    showDetail(rowData: any) {
        
        showLeadDetail.value = true
        detailleadId.value = rowData.ID
        // set isHighQuality and notes
        const targetLead = leads.value.filter((lead: any) => lead.ID == rowData.ID)[0]
        isCurrentLeadHighQuality.value = targetLead.isQuality == 1
        leadNotes.value = targetLead.notes
    }
})



const handlePageChange = (currentPage: number) => {
    if (!loading.value) {
        loading.value = true
        fetchLeadsData(websiteState.currentWebsiteId, currentPage, range.value)
    }
}

const handleRowSelect = (keys: Array<any>) => {
    selectedKeys.value = [...keys]
}

/**
 * 
 * @param data object[]
 * @param fileName do not need extendsion
 */
const generateAndDownloadSheet = (data: any, fileName: string) => {
    const worksheet = utils.json_to_sheet(data);
    const wb = utils.book_new()
    utils.book_append_sheet(wb, worksheet, "Sheet1");
    writeFileXLSX(wb, `${fileName}.xls`); 
}

const transformExportedLeads = (data: any) => {
    const getForm = (form: any) => {
        const rows = form.map((formRow: any) => `${formRow.label} \n ${formRow.value}\n`)
        const rowsCombine = rows.join('\n')
        return decodeHtml(rowsCombine).replaceAll('<br/>', `\n`)
    }
    const getBriefMsg = (form: any) => {
        const matchPara = form.filter((row: any) => row.type === 'Paragraph')
        if (matchPara.length > 0) return decodeHtml(matchPara[0].value).replaceAll('<br/>', `\n`)
        return ''
    }
    const getPhone = (form: any) => {
        const match = form.filter((row: any) => row.type === 'Text' && /\d{5}/.test(row.value))
        if (match.length > 0) return match[0].value
        return ''
    }
    const isBusinessEmail = (email: string) => {
        const emailSuffix = email.split('@')[1]
        return emailDomains.includes(emailSuffix) ? '否' : '是'
    }
    const getSource = (url: string) => {
        if (url.split('?').length === 1) return ''

        const params = new URLSearchParams(url.split('?')[1])
        if (params.get('gclid') !== '') return 'SEM'
        return ''
    }
    const getSourceKd = (url: string) => {
        if (url.split('?').length === 1) return ''
        
        const params = new URLSearchParams(url.split('?')[1])
        if (params.get('keyword') !== '') return params.get('keyword')
        return ''
    }
    return data.map((lead: any) => ({
        '日期': lead.date,
        'Email': lead.title,
        'message': getBriefMsg(lead.form.formData),
        '企业邮箱': isBusinessEmail(lead.title),
        '电话': getPhone(lead.form.formData),
        '来源渠道': getSource(lead.source_url),
        '来源关键词': getSourceKd(lead.source_url),
        '表单详情': getForm(lead.form.formData),
        'IP': lead.ip,
        '国家': lead.country,
        '来源页面': lead.source_url,
        '设备': lead.device,
        '操作系统': lead.os,
        '浏览器': lead.browser,
        '询盘质量': lead.isQuality == 1 ? '高质量': '',
        '询盘备注': lead.notes
    }))
}

const handleExportSelect = () => {
    const exportedKeys = [...selectedKeys.value]
    const exportedRows = leads.value.filter((lead: any) => exportedKeys.includes(lead.ID))
    generateAndDownloadSheet(transformExportedLeads(exportedRows), 'export_leads_selected')
}

const handleExportAll = () => {

    const exportAllConfirm = async () => {
        exportingAll.value = true
        // todo: maybe use background job when export is huge
        const result = await UserService.leadsPlatform(websiteState.currentWebsiteId, 1, 1000000)
        exportingAll.value = false
        generateAndDownloadSheet(transformExportedLeads(result.data), 'export_leads_all')
    }

    dialog.warning({
        title: '全部导出',
        content: '数据量大可能会花费一定时间，是否继续？',
        positiveText: '继续',
        negativeText: '取消',
        onPositiveClick: () => {
            exportAllConfirm()
            return true
        },
    })
}

const handleMarkSelect = async () => {
    batchUpdatingHighQuality.value = true
    try {
        const result = await UserService.addHighQualityToIds([...selectedKeys.value], websiteState.currentWebsiteId)
        message.info("操作成功")

        // update state
        result.data.map((item: any) => {
            const targetIndex = leads.value.findIndex((lead: any) => lead.ID == item.remoteId)
            leads.value[targetIndex]['isQuality'] = item.isQuality
        })
    } catch (e: any) {
        message.error("操作失败，请刷新页面重试或联系管理员")
    }
    batchUpdatingHighQuality.value = false
}

const onHighQualityToggle = async (checked: boolean) => {
    highQualityUpdating.value = true
    try {
        const result = await UserService.toggleHighQuality(detailleadId.value, websiteState.currentWebsiteId)
        message.info("操作成功")
        // update state
        const {data} = result
        const targetIndex = leads.value.findIndex((lead: any) => lead.ID == data.remoteId)
        leads.value[targetIndex]['isQuality'] = data.isQuality
    } catch (e: any) {
        message.error("操作失败，请刷新页面重试或联系管理员")
    }
    highQualityUpdating.value = false
}


const range = ref<[number, number]>([new Date(2020,0, 1).getTime(), Date.now()])
const datePicker = ref<any>(null);

const rangeShortcuts: any = {
        '全部时间': () => {
            const past = new Date(2020,0, 1)
            return [past.getTime(), new Date().getTime()]
        },
        '今天': () => {
          const cur = new Date().getTime()
          return [cur, cur]
        },
        '昨天': () => {
            const yesterdayTime = new Date(new Date().getTime() - 24*60*60*1000).getTime();
            return [yesterdayTime, yesterdayTime]
        },
        '过去7天': () => {
            const pastTime = new Date(new Date().getTime() - 24*60*60*1000*7).getTime();
            return [pastTime, new Date().getTime()]
        },
        '过去30天': () => {
            const pastTime = new Date(new Date().getTime() - 24*60*60*1000*30).getTime();
            return [pastTime, new Date().getTime()]
        },
        '过去90天': () => {
            const pastTime = new Date(new Date().getTime() - 24*60*60*1000*90).getTime();
            return [pastTime, new Date().getTime()]
        },
      }

const onSaveLeadNotes = async () => {
    leadNoteUpdating.value = true
    try {
        const result = await UserService.editLeadNotes(detailleadId.value, leadNotes.value, websiteState.currentWebsiteId)
        Logger.debug('result', result)
        message.info("操作成功")
        // update state
        const targetIndex = leads.value.findIndex((lead: any) => lead.ID == detailleadId.value)
        leads.value[targetIndex]['notes'] = leadNotes.value

    } catch (e: any) {
        message.error("操作失败，请刷新页面重试或联系管理员")
    }
    leadNoteUpdating.value = false
}


const onRangeUpdate = (value: [number, number]) => {

    console.log('update value', value)
    fetchLeadsData(websiteState.currentWebsiteId, 1, [...value])
}

</script> 

<template>
    <div class="main-container">
        <top-bar />
        <perfect-scrollbar>
            <div class="main-stage">
                <div class="top-actions">
                    <n-space justify="end">
                        <n-button type="primary" @click="() => {router.back()}" >返回</n-button>
                        <n-date-picker :actions="['confirm']" @update:value="onRangeUpdate" :shortcuts="rangeShortcuts" v-model:value="range" type="daterange" :clearable="false" />
                    </n-space>
                </div>
                <lead-map :range="range"/>
                <div class="leads-wrap">
                    <h2 class="lead-management-title">询盘详情</h2>
                    <div class="table-actions-wrap">
                        <n-space>
                            <n-button @click="handleExportAll" :loading="exportingAll">导出所有询盘</n-button>
                            <n-button
                                v-if="selectedKeys.length > 0"
                                @click="handleExportSelect"
                            >导出选中询盘</n-button>
                            <n-button
                                :loading="batchUpdatingHighQuality"
                                v-if="selectedKeys.length > 0"
                                @click="handleMarkSelect"
                            >标记选中询盘为高质量</n-button>
                        </n-space>
                    </div>
                    <n-data-table
                        @update:checked-row-keys="handleRowSelect"
                        @update:page="handlePageChange"
                        :loading="loading"
                        remote
                        :columns="columns"
                        :data="leads"
                        :pagination="pagination"
                    />
                </div>
            </div>
            <n-modal v-model:show="showLeadDetail">
                <div class="lead-popup-inner">
                    <n-card
                        style="width: 800px"
                        title="询盘详情"
                        :bordered="false"
                        size="huge"
                        role="dialog"
                        aria-modal="true"
                    >
                        <div class="table-form">
                            <template v-for="item in detailLead.form.formData">
                                <div :class="`row ${item.type}`">
                                    <div class="label">{{ item.label }}</div>
                                    <div class="value" v-if="item.value.startsWith('http')">
                                        <n-ellipsis style="max-width: 500px">{{ item.value }}</n-ellipsis>
                                    </div>
                                    <div class="value" v-else>
                                        <!-- <perfect-scrollbar v-if="item.type === 'Paragraph'">
                                            <div class="content" v-html="decodeHtml(item.value).replaceAll('<br/>', `\n`)"></div>
                                        </perfect-scrollbar>
                                        <div class="content" v-else v-html="decodeHtml(item.value).replaceAll('<br/>', `\n`)"></div> -->

                                        <div class="content" v-html="decodeHtml(item.value).replaceAll('<br/>', `\n`)"></div>
                                    </div>
                                </div>
                            </template>
                            <div class="row">
                                <div class="label">来源页面</div>
                                <div class="value">
                                    <n-ellipsis style="max-width: 500px;">{{ detailLead.source_url }}</n-ellipsis>
                                </div>
                            </div>
                            <div class="row" v-if="isSEM">
                                <div class="label">来源渠道</div>
                                <div class="value">
                                    SEM
                                </div>
                            </div>
                            <div class="row" v-for="item in queryArrShort">
                                <div class="label">{{item[0]}}</div>
                                <div class="value" v-if="item[0] === 'gclid'">
                                    <n-ellipsis style="max-width: 200px">
                                        {{item[1]}}
                                    </n-ellipsis>
                                </div>
                                <div class="value" v-else>
                                    {{item[1]}}
                                </div>
                            </div>
                            <div class="row">
                                <div class="label">国家</div>
                                <div class="value">{{ detailLead.country }}</div>
                            </div>
                            <div class="row">
                                <div class="label">IP地址</div>
                                <div class="value">{{ detailLead.ip }}</div>
                            </div>
                            <div class="row">
                                <div class="label">设备</div>
                                <div class="value">{{ detailLead.device }}</div>
                            </div>
                            <div class="row">
                                <div class="label">操作系统</div>
                                <div class="value">{{ detailLead.os }}</div>
                            </div>
                            <div class="row">
                                <div class="label">浏览器</div>
                                <div class="value">{{ detailLead.browser }}</div>
                            </div>
                        </div>

                        <div class="extra-row">
                            <div class="high-quality-wrap">
                                <n-checkbox
                                    size="large"
                                    :disabled="highQualityUpdating"
                                    v-model:checked="isCurrentLeadHighQuality"
                                    @update:checked="onHighQualityToggle"
                                >标记为高质量询盘</n-checkbox>
                            </div>
                            <div class="lead-notes">
                                <n-input
                                    v-model:value="leadNotes"
                                    type="textarea"
                                    placeholder="询盘备注"
                                    rows="2"
                                />
                            </div>
                            <div class="confirm-btn-wrap" style="padding-top: 5px;">
                                <n-button type="primary" :loading="leadNoteUpdating" @click="onSaveLeadNotes">保存询盘备注</n-button>
                            </div>
                        </div>
                    </n-card>
                </div>
            </n-modal>
        </perfect-scrollbar>
    </div>
</template>


<style scoped lang="scss">

.row.Paragraph .value {
    max-height: 150px;
    overflow-y: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    &::-webkit-scrollbar {
        display: none;
    }
    .ps {
        max-height: 200px;
        overflow-x: hidden;
    }
}

.top-actions {
    background-color: transparent!important;
    padding: 20px;
}
.main-stage {
    display: block;
    h2 {
        font-size: 24px;
        border-bottom: 1px solid #d1d7dc;
        padding-bottom: 5px;
        font-weight: bold;
    }
    .lead-management-title {
        // margin-bottom: 30px;
    }
    .table-actions-wrap {
        padding: 20px 0;
    }
}
.leads-wrap {
    padding: 20px;
}
.row {
    display: flex;
    flex-wrap: wrap;
    border: 1px solid #a4bde3;
    border-bottom: none;
    &:last-child {
        border-bottom: 1px solid #a4bde3;
    }
    .label {
        font-weight: bold;
        width: 200px;
        border-right: 1px solid #a4bde3;
    }
    .value {
        flex: 1;
    }

    > div {
        padding: 5px;
    }
}
.high-quality-wrap {
    padding-top: 10px;
    padding-bottom: 10px;
}
</style>
