<script setup lang="ts">

// this component is for lead popover

import { useUserStore } from '@/stores/user';
import { computed, onMounted, ref } from 'vue'
import { NCard, NPopover, NEllipsis } from 'naive-ui';
import { decodeHtml } from '@/utils';

const props = defineProps<{ leadObj: any }>()

const count = ref(0)

const userStore = useUserStore();
const form = ref<any[]>([])

// todo: use hooks for these
const isSEM = computed(() => {
    const qs = props.leadObj.source_url.split('?').length > 1 ? props.leadObj.source_url.split('?')[1] : ''
    return qs.includes('gclid')
})

const queryArr = computed(() => {
    if (!isSEM) return []
    const qs = props.leadObj.source_url.split('?').length > 1 ? props.leadObj.source_url.split('?')[1] : ''
    const searchParams: any = new URLSearchParams(qs)
    const result = []
    for (let p of searchParams) {
        result.push(p)
    }
    return result
})

const queryArrShort = computed(() => {
    const includesKeys = ['keyword', 'gclid']
    return queryArr.value.filter((item: any) => {
        return includesKeys.includes(item[0])
    })
})

const email = computed(() => {
    if (props.leadObj.title && props.leadObj.title.includes('@')) return props.leadObj.title

    // try to search the form for email
    for (let i = 0; i < form.value.length; i++) {
        const currentItem = form.value[i]
        if (currentItem.value.includes('@')) return currentItem.value
    }

    return ''
})

onMounted(() => {
    form.value = props.leadObj.form.formData.map((item: any) => ({
        ...item,
        value: decodeHtml(item.value).replaceAll('<br/>', `\n`)
    }))
})

</script>

<template>
  <div class="lead-detail-wrap-quick">
      <n-popover trigger="hover" :width="500">
        <template #trigger>
            <div>{{email}}</div>
        </template>
            <div>
            <template v-for="item in form">
                <div :class="`row ${item.type}`">
                    <div class="label">{{item.label}}</div>
                    <div class="value" v-if="item.value.startsWith('http')">
                        <n-ellipsis style="max-width: 200px">
                            {{item.value}}
                        </n-ellipsis>
                    </div>
                    <div class="value" v-else v-html="item.value"></div>
                </div>
            </template>
            <div class="row">
                <div class="label">来源页面</div>
                <div class="value">
                    <n-ellipsis style="max-width: 200px">
                        {{props.leadObj.source_url}}
                    </n-ellipsis>
                </div>
            </div>
            <div class="row" v-if="props.leadObj.notes != ''">
                <div class="label">询盘备注</div>
                <div class="value">
                    {{props.leadObj.notes}}
                </div>
            </div>
            <div class="row" v-if="isSEM">
                <div class="label">来源渠道</div>
                <div class="value">
                    SEM
                </div>
            </div>
            <div class="row" v-for="item in queryArrShort">
                <div class="label">{{item[0]}}</div>
                <div class="value" v-if="item[0] === 'gclid'">
                    <n-ellipsis style="max-width: 200px">
                        {{item[1]}}
                    </n-ellipsis>
                </div>
                <div class="value" v-else>
                    {{item[1]}}
                </div>
            </div>
            </div>
    </n-popover>
  </div>
</template>

<style scoped lang="scss">

.row.Paragraph .value {
    max-height: 150px;
    overflow-y: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    &::-webkit-scrollbar {
        display: none;
    }
}

.lead-detail-wrap {
    padding-left: 50px;
    padding-right: 50px;
    .n-card {
        max-width: 500px;
    }
}

    .row {
        display: flex;
        flex-wrap: wrap;
        border: 1px solid #a4bde3;
        border-bottom: none;
        &:last-child {
            border-bottom: 1px solid #a4bde3;
        }
        .label {
            font-weight: bold;
            width: 150px;
            border-right: 1px solid #a4bde3;
        }
        .value {
            flex: 1;
        }

        > div {
            padding: 5px;
        }
    }


</style>
