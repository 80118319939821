import HttpService from "./httpService"

export interface ITemplateInfo {
    screenshot: string,
    name: string,
    url: string,
    tags: string[],
    id: number
}
 
export default class SiteService {
    public static async getTemplateData(): Promise<{
        categorys: string[],
        templates: ITemplateInfo[]
    }> {
        const url = "app/templates"
        const res = await HttpService.get(url)
        if (res.code !== 0) {
            throw new Error(res.msg) 
        }
        const result = {
            categorys: Object.keys(res.data[0]).map(key => res.data[0][key]),
            templates: res.data[1].map((item:any) => ({
                ...item,
                screenshot: item.screenshot[0] || "https://st-img-md.oss-cn-guangzhou.aliyuncs.com/2 22.png"
            }))
        }
        return result
    }

    public static async getReportByDomain(siteDomain: string) {
        if (!siteDomain) return
        const url = `app/user/briefReport?httpsDomain=${siteDomain.replace('http://', 'https://')}&withProxy=0`

        const res = await HttpService.get(url)
        if (res.code !== 0) {
            throw new Error(res.msg) 
        }
        return res.data
    }

    public static async updateSiteName(siteName: string, blogId: number) {
        const url = `app/user/updateSiteName?blogId=${blogId}`

        const res = await HttpService.post(url, {
            siteName,
        })
        if (res.code !== 0) {
            throw new Error(res.msg) 
        }
        // no need to return anything
        return res.data
    }
    public static async listSiteUser(blogId: number) {
        const url = `app/user/${blogId}/listUser`
        const res = await HttpService.get(url)
        if (res.code !== 0) {
            throw new Error(res.msg) 
        }
        // no need to return anything
        return res.data
    }
    public static async toggleServiceGrant(blogId: number, service: 'support' | 'marketing') {
        const url = `app/user/${blogId}/toggleService/${service}`
        const res = await HttpService.post(url, {})
        if (res.code !== 0) {
            throw new Error(res.msg) 
        }
        // no need to return anything
        // return res.data
    }
    public static async listInvitationForSite(blogId: number) {
        const url = `app/user/${blogId}/invitation`
        const res = await HttpService.post(url, {})
        if (res.code !== 0) {
            throw new Error(res.msg) 
        }
        // no need to return anything
        return res.data
    }
    public static async retreatInvitationById(invitationId: number, blogId: number) {
        const url = `app/user/${blogId}/retreatInvitation?id=${invitationId}`
        const res = await HttpService.post(url, {})
        if (res.code !== 0) {
            throw new Error(res.msg) 
        }
        // no need to return anything
    }
    public static async listStaffBySite(blogId: number) {
        const url = `app/user/listStaffBySite?sid=${blogId}`
        const res = await HttpService.post(url, {})
        if (res.code !== 0) {
            throw new Error(res.msg) 
        }
        // no need to return anything
        return res.data
    }
    public static async inviteUser(blogId: number, toEmail: string, permissions: string[]) {
        const url = `app/user/${blogId}/invite`
        const res = await HttpService.post(url, {
            toEmail,
            permissions
        })
        if (res.code !== 0) {
            throw new Error(res.msg) 
        }
        // no need to return anything
        return res.data
    }
    public static async updateUserPermissions(blogId: number, wpId: number, permissions: string[]) {
        const url = `app/user/${blogId}/updatePermission?wpid=${wpId}`
        const res = await HttpService.post(url, {
            caps: permissions
        })
        if (res.code !== 0) {
            throw new Error(res.msg) 
        }
        // no need to return anything
        // return res.data
    }

    public static async removeStaffById(blogId: number, wpId: number, wpEmail: string) {
        const url = `app/user/${blogId}/removeStaffById?wpid=${wpId}&wpEmail=${wpEmail}`
        const res = await HttpService.post(url, {})
        if (res.code !== 0) {
            throw new Error(res.msg) 
        }
    }

    public static async validateInviteToken(token: string) {
        const url = `app/user/validateInviteToken?token=${token}`
        const res = await HttpService.post(url, {})
        if (res.code !== 0) {
            throw new Error(res.msg) 
        }
        // no need to return anything
        return res.data
    }
    public static async processInvitation(token: string, toEmail: string) {
        const url = `app/user/processInvitation?token=${token}&toEmail=${toEmail}`
        const res = await HttpService.post(url, {})
        if (res.code !== 0) {
            throw new Error(res.msg) 
        }
        // no need to return anything
        return res
    }
}