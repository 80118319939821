import {computed, onMounted, ref, watch} from "vue";
import {useWebsiteStore} from "@/stores/website";
import {useRouter} from "vue-router";

export default function useOneSitePermission(msg: string) {

  const websiteState = useWebsiteStore()
  const router = useRouter();

  const checkPermission = () => {
    if (!websiteState.loading && websiteState.websites.length === 0) {
      window.$message?.error(`开通网站即可访问${msg}`)
      router.push('/create')
    }
  }

  watch(() => websiteState.loading, () => {
    checkPermission()
  })

  onMounted(() => {
    checkPermission()
  })

  return {

  };
}
