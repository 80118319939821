<script setup lang="ts">

import { NDrawer, NDrawerContent, useMessage, NSkeleton, NPopover, NCarousel, NStep, NSteps, NSpace, NGrid, NGi, NScrollbar, NModal, NCard } from 'naive-ui'
import { currencyFilter } from '@/filters';
import OrderService from '@/service/orderService';
import { useCreateStore } from '@/stores/create'
import { useProductStore } from '@/stores/product'
import { useTemplateStore } from '@/stores/template'
import { calHbByPrice, IHBPricing } from '@/utils';
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useWebsiteStore } from '@/stores/website';


const router = useRouter()
const createState = useCreateStore()
const websiteState = useWebsiteStore()
const templateState = useTemplateStore()
const productState = useProductStore()
const message = useMessage()
const selectedPaymentMethod = ref<"WX" | "ALI" | "HB">("WX")
const paymentLoading = ref<Boolean>(false)
const aliPaymentForm = ref<String>("")
const wechatPaymentLink = ref<String>("")
const showPaymentPopup = ref<boolean>(false)
const orderCompleteChecking = ref(false)

const serviceProduct = computed(() => {
    const product = productState.getProductByIds([createState.pid])
    if (product.length > 0) {
        return product[0]
    } else {
        return {} as any
    }
})

const selectedTemplateName = computed(() => {
    const currentSelectedTemplateId = createState.selectedTemplateId
    const match = templateState.templates.filter(template => template.id === currentSelectedTemplateId)
    return match.length > 0 ? match[0].name : ''
})

const websiteProductPrice = computed(() => {
    if (productState.loading === false) {
        return productState.getWebsiteProduct().unitPrice
    }
    return 0
})

const selectedRecomProducts = computed(() => {
    if (productState.loading === false) {
        return productState.getRecommendedProducts().filter(product => createState.selectedRecomProductIds.includes(product.id))
    }
    return []
})

const setPaymentMethod = (method: "WX" | "ALI" | "HB") => {
    selectedPaymentMethod.value = method
}

const selectedHBInstallment = ref<3|6|12>(3)

const setHBInstallment = (installment: 3|6|12) => {
    selectedHBInstallment.value = installment
}

const hbPricingObj = computed<IHBPricing>(() => {
    const finalPrice = createState.finalTotalPrice
    const hbPricing = calHbByPrice(finalPrice)
    return hbPricing
})

const onPayButtonClick = async () => {
    if (paymentLoading.value) return
    paymentLoading.value = true
    try {
        const paymentResult = await OrderService.payByOid(createState.orderId, selectedPaymentMethod.value, selectedHBInstallment.value)

        if (selectedPaymentMethod.value === "WX") {
            wechatPaymentLink.value = paymentResult
        } else {
            aliPaymentForm.value = paymentResult
            const newWin = window.open("about:blank")
            if (newWin) {
                newWin.document.write(paymentResult);
            }
        }

        showPaymentPopup.value = true
    } catch (e: any) {
        message.error(e.message)
        router.push({
            name: "create",
            params: {
                step: 1
            }
        })
    }
    paymentLoading.value = false

}

const onOrderCompleteClick = async () => {
    try {
        orderCompleteChecking.value = true
        const orderResult = await OrderService.queryOrder(createState.orderId)
        orderCompleteChecking.value = false
        if (orderResult.status === 1) {
            message.info("订单未支付成功")
        }
        if (orderResult.status === 2) {
            message.success("支付成功")
            createState.setIsOrderPaid(true)
            // todo: direct to create website page
            const oid = orderResult.id
            router.push({
                name: "PaymentSuccess",
                query: {
                    oid,
                    bid: createState.blogId
                }
            })
        }
    } catch (e: any) {
        orderCompleteChecking.value = false
        message.error(e.message)
    }
}

</script>

<template>
    <div class="step step-payment" v-show="createState.currentStep === 4">
        <h2>支付中心</h2>
        <div class="order-wrap" v-if="createState.blogId === 0">
            <div class="row-header">
                <div class="header-label">产品名称</div>
                <div class="header-label">商品描述</div>
                <div class="header-label">价格</div>
            </div>
            <div class="row-product">
                <div class="product-name">超新星快速建站</div>
                <div class="product-des">
                    <div class="product-detail-row">已选范例网站: {{ selectedTemplateName }}</div>
                    <div class="product-detail-row">品牌名称: {{ createState.brandName }}</div>
                    <div class="product-detail-row">网站域名: {{ `https://${createState.domain}.msupernova.com` }}</div>
                </div>
                <div class="product-price">
                    <span class="price">{{ currencyFilter(websiteProductPrice) }}</span>
                </div>
            </div>
            <!-- TODO: render recomended products here -->
            <div class="row-product" v-for="product in selectedRecomProducts">
                <div class="product-name">{{ product.productTitle }}</div>
                <div class="product-des">{{ product.productDetail }}</div>
                <div class="product-price">
                    <span class="price">{{ currencyFilter(product.unitPrice) }}</span>
                </div>
            </div>
        </div>

        <div class="order-wrap service-product" v-if="createState.blogId !== 0">
            <div class="row-header">
                <div class="header-label">产品名称</div>
                <div class="header-label">商品描述</div>
                <div class="header-label">价格</div>
            </div>
            <div class="row-product">
                <div class="product-name">
                    {{serviceProduct.productTitle}}
                </div>
                <div class="product-des">
                    <div class="product-detail-row">{{ serviceProduct.productDetail }}</div>
                    <div class="product-detail-row">站点名称: {{ websiteState.getCurrentWebsiteName() }}</div>
                    <div class="product-detail-row">站点域名: {{ websiteState.getCurrentSiteDomain() }}</div>
                </div>
                <div class="product-price">
                    <span class="price">{{ currencyFilter(serviceProduct.unitPrice) }}</span>
                </div>
            </div>
        </div>

        <div class="cta-fix-container">
            <div class="cta-wrap">
                <div class="coupon-wrap payment-method-wrap">
                    <h3 class="payment-method-title">支付方式</h3>
                    <div class="payment-method">
                        <div
                            class="payment-method-item"
                            :class="selectedPaymentMethod === 'WX' ? 'active' : ''"
                            @click="setPaymentMethod('WX')"
                        >
                            <img
                                src="https://st-img-md.oss-cn-guangzhou.aliyuncs.com/20220120155926.png"
                            />
                        </div>
                        <div
                            class="payment-method-item"
                            :class="selectedPaymentMethod === 'ALI' ? 'active' : ''"
                            @click="setPaymentMethod('ALI')"
                        >
                            <img
                                src="https://st-img-md.oss-cn-guangzhou.aliyuncs.com/支付宝logo 1.png"
                            />
                        </div>
                        <div
                            class="payment-method-item"
                            :class="selectedPaymentMethod === 'HB' ? 'active' : ''"
                            @click="setPaymentMethod('HB')"
                        >
                            <img
                                src="https://st-img-md.oss-cn-guangzhou.aliyuncs.com/20220130145643.png"
                            />
                        </div>
                    </div>
                    <div class="fq-installment" v-show="selectedPaymentMethod === 'HB'">
                        <div
                            class="installment-item-wrap"
                            :class="selectedHBInstallment === 3 ? 'active' : ''"
                        >
                            <div
                                class="payment-detail"
                            >{{ currencyFilter(hbPricingObj[3].totalPerMonth) }} X 3期</div>
                            <div class="payment-fee">免息</div>
                            <div class="payment-cta" @click="setHBInstallment(3)">选择该支付方式</div>
                        </div>
                        <div
                            class="installment-item-wrap"
                            :class="selectedHBInstallment === 6 ? 'active' : ''"
                        >
                            <div
                                class="payment-detail"
                            >{{ currencyFilter(hbPricingObj[6].totalPerMonth) }} X 6期</div>
                            <div
                                class="payment-fee"
                            >手续费：{{ currencyFilter(hbPricingObj[6].feePerMonth) }}/期</div>
                            <div class="payment-cta" @click="setHBInstallment(6)">选择该支付方式</div>
                        </div>
                        <div
                            class="installment-item-wrap"
                            :class="selectedHBInstallment === 12 ? 'active' : ''"
                        >
                            <div
                                class="payment-detail"
                            >{{ currencyFilter(hbPricingObj[12].totalPerMonth) }} X 12期</div>
                            <div
                                class="payment-fee"
                            >手续费：{{ currencyFilter(hbPricingObj[12].feePerMonth) }}/期</div>
                            <div class="payment-cta" @click="setHBInstallment(12)">选择该支付方式</div>
                        </div>
                    </div>
                </div>
                <div class="total-price-wrap">
                    <div class="total-price-wrap">
                        <span class="total-price-des">订单金额：</span>
                        <span
                            class="total-price"
                        >{{ currencyFilter(createState.finalTotalPrice) }}</span>
                        <span
                            class="total-discount"
                        >(已优惠 {{ currencyFilter(createState.saving) }})</span>
                    </div>
                    <div class="confirm-order-wrap">
                        <div
                            class="btn btn-confirm-order"
                            :class="paymentLoading ? 'disabled' : ''"
                            @click="onPayButtonClick"
                        >{{ paymentLoading ? "支付中..." : "立即支付" }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- payment status popup -->
    <n-modal :mask-closable=false v-model:show="showPaymentPopup">
        <n-card
            style="width: 450px;"
            :bordered="false"
            size="huge"
            role="dialog"
            aria-modal="true"
            class="payment-popup-card-wrap"
        >
        <div class="payment-proccesing-wrap">
            <div class="processing-alipay" v-if="selectedPaymentMethod === 'ALI' || selectedPaymentMethod === 'HB'">
                <div class="waiting-img-wrap">
                    <img src="https://st-img-md.oss-cn-guangzhou.aliyuncs.com/20220303013816.png" alt="">
                </div>
            </div>
            <div class="processing-wechat" v-if="selectedPaymentMethod === 'WX'">
                <div class="wechat-title">微信扫码支付 {{currencyFilter(createState.finalTotalPrice)}}</div>
                <div class="wechat-pay-code">
                    <div class="qrcode-img-wrap">
                        <!-- <div id="qrcodeDiv" style="width:300px; height:300px;"></div> -->
                        <img v-if=" wechatPaymentLink !== '' " :src="'https://api.qrserver.com/v1/create-qr-code/?data=' + encodeURI(wechatPaymentLink as string)" alt="">
                    </div>
                        <!-- <QRCodeVue3 :width="300" :height="300" :value="wechatPaymentLink"/> -->
                </div>
            </div>
        </div>
        <div class="payment-cta-wrap">
            <h4 class="payment-waiting-notice">等待您完成支付...</h4>
            <div class="click-cta-notice">支付完成请点击下方按钮</div>
            <div class="payment-cta-wra-complete pointer" :class="orderCompleteChecking ? 'disabled' : ''" @click="onOrderCompleteClick">
                {{orderCompleteChecking ? "查询支付状态..." : "支付完成"}}
            </div>
            <div class="payment-cta-wrap-reselect" @click="() => {showPaymentPopup = false}">
                重新选择支付方式
            </div>
        </div>
        </n-card>
    </n-modal>
</template>

<style scoped lang="scss">
</style>
