<script lang="ts" setup>

/**
 * State
 */


import UserService from '@/service/userService';
import SiteService from '@/service/SiteService';
import { onMounted, ref } from 'vue';
import { NIcon, useMessage } from 'naive-ui';
import {Loader} from '@vicons/tabler';
import { useRoute, useRouter } from 'vue-router';
const message = useMessage()
const linkRef = ref("")

const router = useRouter()

onMounted(async () => {
    const route = useRoute()
    const token = route.params.token
    
    try {
        const result = await SiteService.validateInviteToken(token as string)

        /*
        fromUserAvatar: null
        fromUserName: "Stanley"
        status: "PENDING"
        toEmail: "stanleyyylauteststaff@gmail.com"
        toEmailExist: false
        */
       if (result.toEmailExist) {
          // todo: 处理用户已注册的 case
          const tokenFromLocalDb = window.localStorage.getItem("token")
          if (tokenFromLocalDb && tokenFromLocalDb !== "") {
            // call endpoint the process invitation
            const tokenAsStr = token as string
            await SiteService.processInvitation(tokenAsStr, result.toEmail)
            message.success("已确认邀请")
            router.push({
              name: 'dashboard'
            })
          } else {
            message.info(`请先使用 Email 为 ${result.toEmail} 的账号登录`)
            router.push({
              name: 'Login'
            })
          }
       } else {
          const notice = `您的好友: ${result.fromUserName} 正在邀请您注册超新星`
          message.info(notice)
          router.push({
            name: "Register",
            state: {
              toEmail: result.toEmail,
              invitationToken: token,
            }
          })
       }

    } catch(e: any) {
        message.error(e.message)
        router.push({
          name: 'dashboard'
        })
    }
    
    
})



</script>

<template>
  <div class="invitation-wrap">
    <div class="inner">
      <div class="loader">Loading...</div>
      <h1>正在校验邀请链接...</h1>
      <!-- <a id="theLink" style="opacity: none;" :href="linkRef" no-referrer rel="referrer dofollow">link</a> -->
    </div>
  </div>

</template>

<style lang="scss">
.invitation-wrap {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    h1 {
      font-weight: 600;
      font-size: 30px;
      color: #333333;
  }
}

// loader generated from : https://projects.lukehaas.me/css-loaders/

.loader {
  margin: 100px auto;
  font-size: 25px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  -webkit-animation: load5 1.1s infinite ease;
  animation: load5 1.1s infinite ease;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
@-webkit-keyframes load5 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #3e8bff, 1.8em -1.8em 0 0em rgba(62,139,255, 0.2), 2.5em 0em 0 0em rgba(62,139,255, 0.2), 1.75em 1.75em 0 0em rgba(62,139,255, 0.2), 0em 2.5em 0 0em rgba(62,139,255, 0.2), -1.8em 1.8em 0 0em rgba(62,139,255, 0.2), -2.6em 0em 0 0em rgba(62,139,255, 0.5), -1.8em -1.8em 0 0em rgba(62,139,255, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(62,139,255, 0.7), 1.8em -1.8em 0 0em #3e8bff, 2.5em 0em 0 0em rgba(62,139,255, 0.2), 1.75em 1.75em 0 0em rgba(62,139,255, 0.2), 0em 2.5em 0 0em rgba(62,139,255, 0.2), -1.8em 1.8em 0 0em rgba(62,139,255, 0.2), -2.6em 0em 0 0em rgba(62,139,255, 0.2), -1.8em -1.8em 0 0em rgba(62,139,255, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(62,139,255, 0.5), 1.8em -1.8em 0 0em rgba(62,139,255, 0.7), 2.5em 0em 0 0em #3e8bff, 1.75em 1.75em 0 0em rgba(62,139,255, 0.2), 0em 2.5em 0 0em rgba(62,139,255, 0.2), -1.8em 1.8em 0 0em rgba(62,139,255, 0.2), -2.6em 0em 0 0em rgba(62,139,255, 0.2), -1.8em -1.8em 0 0em rgba(62,139,255, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(62,139,255, 0.2), 1.8em -1.8em 0 0em rgba(62,139,255, 0.5), 2.5em 0em 0 0em rgba(62,139,255, 0.7), 1.75em 1.75em 0 0em #3e8bff, 0em 2.5em 0 0em rgba(62,139,255, 0.2), -1.8em 1.8em 0 0em rgba(62,139,255, 0.2), -2.6em 0em 0 0em rgba(62,139,255, 0.2), -1.8em -1.8em 0 0em rgba(62,139,255, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(62,139,255, 0.2), 1.8em -1.8em 0 0em rgba(62,139,255, 0.2), 2.5em 0em 0 0em rgba(62,139,255, 0.5), 1.75em 1.75em 0 0em rgba(62,139,255, 0.7), 0em 2.5em 0 0em #3e8bff, -1.8em 1.8em 0 0em rgba(62,139,255, 0.2), -2.6em 0em 0 0em rgba(62,139,255, 0.2), -1.8em -1.8em 0 0em rgba(62,139,255, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(62,139,255, 0.2), 1.8em -1.8em 0 0em rgba(62,139,255, 0.2), 2.5em 0em 0 0em rgba(62,139,255, 0.2), 1.75em 1.75em 0 0em rgba(62,139,255, 0.5), 0em 2.5em 0 0em rgba(62,139,255, 0.7), -1.8em 1.8em 0 0em #3e8bff, -2.6em 0em 0 0em rgba(62,139,255, 0.2), -1.8em -1.8em 0 0em rgba(62,139,255, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(62,139,255, 0.2), 1.8em -1.8em 0 0em rgba(62,139,255, 0.2), 2.5em 0em 0 0em rgba(62,139,255, 0.2), 1.75em 1.75em 0 0em rgba(62,139,255, 0.2), 0em 2.5em 0 0em rgba(62,139,255, 0.5), -1.8em 1.8em 0 0em rgba(62,139,255, 0.7), -2.6em 0em 0 0em #3e8bff, -1.8em -1.8em 0 0em rgba(62,139,255, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(62,139,255, 0.2), 1.8em -1.8em 0 0em rgba(62,139,255, 0.2), 2.5em 0em 0 0em rgba(62,139,255, 0.2), 1.75em 1.75em 0 0em rgba(62,139,255, 0.2), 0em 2.5em 0 0em rgba(62,139,255, 0.2), -1.8em 1.8em 0 0em rgba(62,139,255, 0.5), -2.6em 0em 0 0em rgba(62,139,255, 0.7), -1.8em -1.8em 0 0em #3e8bff;
  }
}
@keyframes load5 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #3e8bff, 1.8em -1.8em 0 0em rgba(62,139,255, 0.2), 2.5em 0em 0 0em rgba(62,139,255, 0.2), 1.75em 1.75em 0 0em rgba(62,139,255, 0.2), 0em 2.5em 0 0em rgba(62,139,255, 0.2), -1.8em 1.8em 0 0em rgba(62,139,255, 0.2), -2.6em 0em 0 0em rgba(62,139,255, 0.5), -1.8em -1.8em 0 0em rgba(62,139,255, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(62,139,255, 0.7), 1.8em -1.8em 0 0em #3e8bff, 2.5em 0em 0 0em rgba(62,139,255, 0.2), 1.75em 1.75em 0 0em rgba(62,139,255, 0.2), 0em 2.5em 0 0em rgba(62,139,255, 0.2), -1.8em 1.8em 0 0em rgba(62,139,255, 0.2), -2.6em 0em 0 0em rgba(62,139,255, 0.2), -1.8em -1.8em 0 0em rgba(62,139,255, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(62,139,255, 0.5), 1.8em -1.8em 0 0em rgba(62,139,255, 0.7), 2.5em 0em 0 0em #3e8bff, 1.75em 1.75em 0 0em rgba(62,139,255, 0.2), 0em 2.5em 0 0em rgba(62,139,255, 0.2), -1.8em 1.8em 0 0em rgba(62,139,255, 0.2), -2.6em 0em 0 0em rgba(62,139,255, 0.2), -1.8em -1.8em 0 0em rgba(62,139,255, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(62,139,255, 0.2), 1.8em -1.8em 0 0em rgba(62,139,255, 0.5), 2.5em 0em 0 0em rgba(62,139,255, 0.7), 1.75em 1.75em 0 0em #3e8bff, 0em 2.5em 0 0em rgba(62,139,255, 0.2), -1.8em 1.8em 0 0em rgba(62,139,255, 0.2), -2.6em 0em 0 0em rgba(62,139,255, 0.2), -1.8em -1.8em 0 0em rgba(62,139,255, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(62,139,255, 0.2), 1.8em -1.8em 0 0em rgba(62,139,255, 0.2), 2.5em 0em 0 0em rgba(62,139,255, 0.5), 1.75em 1.75em 0 0em rgba(62,139,255, 0.7), 0em 2.5em 0 0em #3e8bff, -1.8em 1.8em 0 0em rgba(62,139,255, 0.2), -2.6em 0em 0 0em rgba(62,139,255, 0.2), -1.8em -1.8em 0 0em rgba(62,139,255, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(62,139,255, 0.2), 1.8em -1.8em 0 0em rgba(62,139,255, 0.2), 2.5em 0em 0 0em rgba(62,139,255, 0.2), 1.75em 1.75em 0 0em rgba(62,139,255, 0.5), 0em 2.5em 0 0em rgba(62,139,255, 0.7), -1.8em 1.8em 0 0em #3e8bff, -2.6em 0em 0 0em rgba(62,139,255, 0.2), -1.8em -1.8em 0 0em rgba(62,139,255, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(62,139,255, 0.2), 1.8em -1.8em 0 0em rgba(62,139,255, 0.2), 2.5em 0em 0 0em rgba(62,139,255, 0.2), 1.75em 1.75em 0 0em rgba(62,139,255, 0.2), 0em 2.5em 0 0em rgba(62,139,255, 0.5), -1.8em 1.8em 0 0em rgba(62,139,255, 0.7), -2.6em 0em 0 0em #3e8bff, -1.8em -1.8em 0 0em rgba(62,139,255, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(62,139,255, 0.2), 1.8em -1.8em 0 0em rgba(62,139,255, 0.2), 2.5em 0em 0 0em rgba(62,139,255, 0.2), 1.75em 1.75em 0 0em rgba(62,139,255, 0.2), 0em 2.5em 0 0em rgba(62,139,255, 0.2), -1.8em 1.8em 0 0em rgba(62,139,255, 0.5), -2.6em 0em 0 0em rgba(62,139,255, 0.7), -1.8em -1.8em 0 0em #3e8bff;
  }
}

</style>
