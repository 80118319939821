import { createApp } from 'vue'
import '@/styles/css/reset.css'
import router from "@/router";
import App from '@/App.vue'
import { createPinia } from 'pinia';
import PerfectScrollbar from 'vue3-perfect-scrollbar'
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css'
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import naive from 'naive-ui'
import VueGtag from "vue-gtag";

const gaMeasurementId = window.location.href.includes('apptest') ? 'G-WVZ7D3WP2K' : 'G-NLB9DRP2VW'
const isLocal = window.location.href.includes('localhost')

const pinia = createPinia()
const app = createApp(App)
app.use(naive)

if (!isLocal) {
    app.use(VueGtag, {
        config: { id: gaMeasurementId },
        router
    })
}

Sentry.init({
    app,
    dsn: "https://510219d2801e4956a8ae0110adf3f6eb@o1128427.ingest.sentry.io/6171203",
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ["app.msupernova.com"],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });

  app.use(router)
  .use(pinia)
  .use(PerfectScrollbar)
  .mount('#app')
