import { onMounted, onUnmounted, ref } from "vue";
import UserService from "@/service/userService";
import { getShortUUID } from "@/utils";
import { getApiBase, parseWechatQueryResult } from "@/config";
import { useRouter } from "vue-router";
import useLoading from "../common/useLoading";

export default function useWechatRegister() {
  const { loading, startLoading, endLoading } = useLoading();
  const resultForQuery = ref(false); // 是否操作成功
  const resultData = ref({
    unionId : '',
    nickname : '',
    avatarUrl : ''
  })
  const router = useRouter();
  const shortUUID = getShortUUID();
  const wechatCodeImg =
    getApiBase() +
    "app/user/generateCodeA?wechatActionType=register&rid=" +
    shortUUID;
  let intervalId: any;

  const loadImgByLink = (link: string) => {
    startLoading();
    var img = document.createElement("img");
    img.src = link;
    img.style.display = "none";
    img.onload = () => {
      endLoading();
    };
    window.document?.getElementById("body")?.appendChild(img);
  };

  const queryWx = async () => {
    const res = await UserService.getScanStatusByRid(shortUUID);
    if (res && res.startsWith('success=true')) {
      const result = parseWechatQueryResult(res)
      const token = result.token // this data is token
      if (token !== "") {
        // 用户已注册，自动登录
        // auto login
        window.$message?.info("您已注册过, 自动登录中...")
        UserService.loginByToken(token)
        // redirect
        router.push({
          name: "dashboard"
        })
      } else {
        const uid = result.unionId
        const userNickName = result.userName
        const userHeadImg = result.userImg
        resultData.value.unionId = uid
        resultData.value.nickname = userNickName
        resultData.value.avatarUrl = userHeadImg
        resultForQuery.value = true
        stopQuery()
      }
    }
  };

  const startQuery = () => {
    intervalId = setInterval(queryWx, 1000);
  };
  const stopQuery = () => {
    clearInterval(intervalId);
  };

  onMounted(async () => {
    loadImgByLink(wechatCodeImg);
    await startQuery();
  });

  onUnmounted(() => {
    stopQuery();
  });

  return {
    loading,
    wechatCodeImg,
    result: resultForQuery, // 操作结果
    resultData,
    startQuery,
    stopQuery,
  };
}
