<script setup lang="ts">

import { NModal, NSpace, NSkeleton, NIcon, NNumberAnimation } from 'naive-ui'
import SiteService from '@/service/siteService';
import { useWebsiteStore } from '@/stores/website';
import { computed, ref, watchEffect } from 'vue';
import { Chart } from '@antv/g2';
import { useRoute, useRouter } from 'vue-router';
import { getAppBase } from '@/config';


const websiteState = useWebsiteStore()
const route = useRoute()
const router = useRouter()
const sid = route.params.sid
const chartObj = ref()
const last7DaysHasNoLeads = ref(false)
const showConsultPopup = ref(false)

const loading = ref(false)

const leadStat = ref<{
    count: number,
    stats: {
        [key: string]: number
    }
}>({
    count: 0,
    stats: {"2000-01-01": 0}
})

const buyAdsService = () => {
    router.push({
        name: "create",
        state: {
            pid: 273 // 273 is ads service product is, hard coded on purpose
        }
    })
}



const leadCountForCurrentMonth = computed(() => {
    const monthKeys = Object.keys(leadStat.value.stats)
    const keyForThisMonth = monthKeys.filter((key) => new Date().getMonth() === new Date(key).getMonth())
    let countForThisMonth = 0
    keyForThisMonth.map(key => {
        countForThisMonth = countForThisMonth + Number(leadStat.value.stats[key])
    })
    return countForThisMonth
})

const useSmallScale = (data: any) => {
    return data.filter((item: any) => item.value > 4).length === 0
}

const calInterval = (data: any) => {
    const valueArr = data.map((item: any) => item.value)
    return Math.ceil(Math.max(...valueArr) / 5)
}

const renderLineChart = (stats: any) => {

    const keys = Object.keys(stats)

    const data = keys.map(key => ({
        date: key,
        value: stats[key]
    })).slice(0, 7)

    // const data = [
    // { year: '1991', value: 3 },
    // { year: '1992', value: 4 },
    // { year: '1993', value: 3.5 },
    // { year: '1994', value: 5 },
    // { year: '1995', value: 4.9 },
    // { year: '1996', value: 6 },
    // { year: '1997', value: 7 },
    // { year: '1998', value: 9 },
    // { year: '1999', value: 13 },
    // ];
    const chart = new Chart({
        container: 'chart-container',
        autoFit: true,
        height: 500,
    });

    chart.data(data);
    chart.scale({
        date: {
            range: [0, 1],
        },
        value: {
            min: 0,
            nice: true,
        },
    });

    chart.tooltip({
        showCrosshairs: true, // 展示 Tooltip 辅助线
        shared: true,
    });

    chart.line().position('date*value').label('value');
    chart.point().position('date*value');

    chart.render();

}

const renderChart = (stats: any) => {
    const keys = Object.keys(stats)

    const data = keys.map(key => ({
        date: key.replace(/\d\d\d\d-/, ''),
        value: Number(stats[key])
    })).slice(23)

    const shouldUseSmallScale = useSmallScale(data)
    const stepInterval = calInterval(data)


//    if (chartObj.value) {
//        chartObj.value.changeData(data)
//        return
//    } else {
        chartObj.value = new Chart({
            container: `chart-container-${websiteState.currentWebsiteId}`,
            autoFit: true,
            height: 420,
        });
        chartObj.value.data(data);
//    }

    const valueSale = shouldUseSmallScale ? {
        alias: '询盘数',
        nice: false,
        ticks: [0,1,2,3,4],
        tickInterval: 1, // only set this when max count is less than =5
        minTickInterval: 1,
        maxTickCount: 5
    } : {
        alias: '询盘数',
        nice: false,
        tickInterval: stepInterval,
        minTickInterval: 1,
        maxTickCount: 5
    }

    chartObj.value.scale('value', valueSale);
    chartObj.value.axis('date', {
        tickLine: null
    });

    chartObj.value.tooltip({
        showMarkers: false
    });
    chartObj.value.interaction('active-region');

    chartObj.value
        .interval()
        .position('date*value')
        .style({ radius: [20, 20, 0, 0] })
        // .style('date', val => {
        //     return {
        //         fillOpacity: 1,
        //         lineWidth: 0,
        //         stroke: '#636363',
        //         lineDash: [3, 2]
        //     };
        // });

    chartObj.value.render();
}

const fetchData = async () => {
    loading.value = true
    const result = await SiteService.getReportByDomain(websiteState.getCurrentWebsiteDomain())
    loading.value = false
    leadStat.value = {
        count: result.count,
        stats: result.stats // sampleData // stats.stats
    }
    
    const stats = result.stats
    const keys = Object.keys(stats)

    const data = keys.map(key => ({
        date: key.replace(/\d\d\d\d-/, ''),
        value: Number(stats[key])
    })).slice(23)

    let totalLeadCountLast7Days = 0
    data.map((item) => {
        totalLeadCountLast7Days = totalLeadCountLast7Days + Number(item.value)
    })

    last7DaysHasNoLeads.value = totalLeadCountLast7Days === 0 ? true : false

    if (totalLeadCountLast7Days === 0) {
        return
    }
    
    window.setTimeout(() => {
        renderChart(stats) // stats.stats // sampleData
    }, 50)
  
}

const onDetailLeadsClick = () => {
    router.push({
        name: 'leads',
        params: {
            sid: websiteState.currentWebsiteId
        }
    })
}

watchEffect(fetchData)

</script>

<template>
    <div class="four summary-wrap">
        <div class="lead-left">
            <h2 class="action-title">数据简报</h2>
            <div :class="`ads-service-active ${websiteState.isCurrentWebsiteAdsExpireSoon() ? 'expire-soon' : ''}`" v-if="websiteState.isCurrentWebsiteAdsActive()">
                <img src="https://st-img-md.oss-cn-guangzhou.aliyuncs.com/20220316152038.png"/>
                <div>
                    广告助推中
                </div> 
                <div class="expire-notice" v-if="websiteState.isCurrentWebsiteAdsExpireSoon()">
                       &nbsp;({{websiteState.getCurrentSiteRemainingExpireDaysForAds()}}天后到期，请及时续费!)
                </div>
            </div>
            <div class="number-wrap total ">
                <div v-if="loading" class="loading-wrap">
                    <n-skeleton  height="50px" circle />
                </div>
                
                <span v-else class="number">
                    <n-number-animation :from="0" :to="leadCountForCurrentMonth" />
                </span>
                <span class="number-des">当月询盘数量</span>
            </div>
            <div class="number-wrap current-user">
                <div v-if="loading" class="loading-wrap">
                    <n-skeleton  height="50px" circle />
                </div>
                <span v-else class="number">
                    <n-number-animation :from="0" :to="leadStat.count" />
                </span>
                <span class="number-des">累计询盘数量</span>
            </div>
        </div>
        <div class="lead-right" v-if="loading">
            <div class="lead-cta-wrap">
                <a :href="`${getAppBase()}leads/${websiteState.currentWebsiteId}/`" @click.prevent="onDetailLeadsClick" target="__blank" class="lead-cat-link">
                    <h3 class="action-lead-detail">详细数据</h3>
                    <div class="detail-wrap">
                        <div class="arrow-wrap">
                            <!-- <div class="arrow-img-wrap">
                                <img
                                    src="https://st-img-md.oss-cn-guangzhou.aliyuncs.com/Vector 9 (1).png"
                                />
                            </div> -->
                        </div>
                    </div>
                </a>
            </div>
            <div class="loading-wrap-box">
                <n-space vertical>
                    <n-skeleton height="40px" width="100%" />
                    <n-skeleton height="40px" width="100%" :sharp="false" />
                    <n-skeleton height="40px" width="100%" :sharp="false" />
                    <n-skeleton height="40px" width="100%" :sharp="false" />
                    <n-skeleton height="40px" width="100%" :sharp="false" />
                </n-space>
            </div>
        </div>
        <div class="lead-right" v-else >
            <div class="lead-cta-wrap">
                <a :href="`${getAppBase()}leads/${websiteState.currentWebsiteId}/`" @click.prevent="onDetailLeadsClick" target="__blank" class="lead-cat-link">
                    <h3 class="action-lead-detail">详细数据</h3>
                    <div class="detail-wrap">
                        <div class="arrow-wrap">
                            <!-- <div class="arrow-img-wrap">
                                <img
                                    src="https://st-img-md.oss-cn-guangzhou.aliyuncs.com/Vector 9 (1).png"
                                />
                            </div> -->
                        </div>
                    </div>
                </a>
            </div>
            <div v-show="last7DaysHasNoLeads && !websiteState.isCurrentWebsiteAdsActive()" class="no-leads-wrap">
                <div class="no-leads-inner">
                    <h2 class="no-leads-title">最近七天没有询盘噢</h2>
                    <p class="no-leads-des">不如让广告师一对一为您解决流量荒！</p>
                    <div class="no-leads-cta-wrap">
                        <a href="#" class="btn traffic" @click.prevent="buyAdsService()">流量套餐</a>
                        <a href="#" class="btn consult" @click.prevent="() => {showConsultPopup = true}">立即咨询</a>
                    </div>
                </div>
            </div>
            <div v-show="last7DaysHasNoLeads && websiteState.isCurrentWebsiteAdsActive()" class="no-leads-wrap">
                <div class="no-leads-inner">
                    <h2 class="no-leads-title">努力引流中～</h2>
                    <p class="no-leads-des">广告师正在努力为您解决流量荒！</p>
                </div>
            </div>
            <div v-if="!last7DaysHasNoLeads" class="chart-wrap" :id="`chart-container-${websiteState.currentWebsiteId}`">
            </div>
        </div>
    </div>
    <n-modal :mask-closable=false v-model:show="showConsultPopup">
        <div class="consult-popup-inner">
            <div class="close-consult-popup-link" @click="() => {showConsultPopup = false}">
                <n-icon size="40">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512"><path d="M289.94 256l95-95A24 24 0 0 0 351 127l-95 95l-95-95a24 24 0 0 0-34 34l95 95l-95 95a24 24 0 1 0 34 34l95-95l95 95a24 24 0 0 0 34-34z" fill="currentColor"></path></svg>
                </n-icon>
            </div>
            <img src="https://st-img-md.oss-cn-guangzhou.aliyuncs.com/20220424141246.png" alt="" class="consultant-code" style="max-width: 330px;">
            <p class="consult-code-des">扫码咨询或直接联系您的业务了解详情</p>
        </div>
    </n-modal>
</template>

<style scoped lang="scss">
.summary-wrap {
    position: relative;
    .ads-service-active {
        user-select: none;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 50%;
        color: white;
        transform: translateX(-50%);
        font-size: 14px;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        height: 55px;
        text-align: center;
        background: url('https://st-img-md.oss-cn-guangzhou.aliyuncs.com/资源 7@3x-8 1.png');
        width: 194px;
        &.expire-soon {
            background: url(https://st-img-md.oss-cn-guangzhou.aliyuncs.com/20220316150104.png);
            width: 372px;
        }
        img {
            margin-right: 10px;
        }
        .expire-notice {
            font-size: 10px;
        }
    }
}

.loading-wrap {
    display: flex;
    justify-content: center;
    padding-bottom: 13px;
}
.loading-wrap-box {
    padding-top: 50px;
}
.consult-popup-inner {
    background: #FFFFFF;
    border-radius: 20px;
    padding: 40px;
    position: relative;
    .consultant-code {
        margin: 0 auto;
    }
    .close-consult-popup-link {
        position: absolute;
        right: 15px;
        top: 15px;
        width: 30px;
        height: 30px;
        cursor: pointer;
    }
    .consult-code-des {
        font-size: 18px;
        color: #333;
        text-align: center;
        margin-top: 25px;
    }
}
.no-leads-wrap {
    height: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    .no-leads-inner {
        background: rgba(232, 240, 255, 0.5);
        border-radius: 20px;
        color: #408CFF;
        padding: 50px;
        flex: 1;
    }
    .no-leads-title {
        font-weight: 600;
        font-size: 30px;
        color: #408CFF;
    }
    .no-leads-des {
        font-size: 18px;
        margin-top: 15px;
        margin-bottom: 40px;
    }
    .no-leads-cta-wrap {
        display: flex;
        justify-content: space-around;
        .btn {
            display: block;
            padding: 12px 40px;
            color: #FFFFFF;
            font-size: 18px;
            text-decoration: none;
            border-radius: 10px;
        }
        .btn.traffic {
            background: #3E8BFF;
        }
        .btn.consult {
            border: 2px solid #3E8BFF;
            color: #3E8BFF;
        }
    } 
}
.summary-wrap {
    display: flex;
    padding: 30px 30px 30px 50px;
    .action-title {
        font-weight: 600;
        font-size: 30px;
        color: #333333;
        margin-bottom: 60px;
    }
    .number-wrap {
        display: flex;
        flex-direction: column;
        text-align: center;
        .number {
            color: #408cff;
            font-size: 50px;
        }
    }
    .lead-left {
        padding-right: 100px;
    }
    .lead-right {
        flex: 1;
        display: flex;
        flex-direction: column;
    }
    .arrow-wrap {
        background-image: url(https://sn-platform-app.oss-cn-guangzhou.aliyuncs.com/static/%E4%BA%86%E8%A7%A3%E6%9B%B4%E5%A4%9A2-%E8%93%9D.png);
        width: 52px;
        height: 52px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        transition: all 0.3s;
    }
    .arrow-img-wrap {
        width: 36px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #408cff;
        border-radius: 50%;
        transition: all 0.3s;
    }
    .lead-cta-wrap {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: flex-end;
        .lead-cat-link {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            text-decoration: none;
            color: #408cff;
            &:hover {
                .arrow-img-wrap {
                    background: #d5e5ff;
                }
                .arrow-wrap {
                    // background-image: url(https://sn-platform-app.oss-cn-guangzhou.aliyuncs.com/static/%E4%BA%86%E8%A7%A3%E6%9B%B4%E5%A4%9A1-%E7%99%BD.png);
                }
            }
        }
    }
    .action-lead-detail {
        margin-right: 15px;
    }
    .summary-wrap {
        display: flex;
        padding: 30px 30px 30px 50px;
    }
    .number-wrap.total {
        margin-bottom: 50px;
    }
    .chart-wrap {
        padding-top: 30px;
    }
}
</style>
