import { serialize } from "@/utils";
import { resolve } from "path";
import HttpService from "./httpService";

export default class MarketingService {
  public static async getMarketing() {
    const url = "https://snj.msupernova.com/mktConfig?action=mktConfig";

    const res = await fetch(url, {
      headers: {
        "Content-Type": "application/json",
      },
      method: "GET", // *GET, POST, PUT, DELETE, etc.
    }).then((response) => response.json());

    return {
      backgroundImg: res[0].backgroundimg,
      title: res[0].title,
      des: res[0].des,
      ctaText: res[0].ctatext,
      ctaLink: res[0].ctalink,
      externalLink: res[0].externallink === "N" ? false : true,
    };
  }
}
