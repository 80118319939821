<script lang="ts" setup>

import {useRouter} from "vue-router";
import {useCrmStore} from "@/stores/crm";
import {useWebsiteStore} from "@/stores/website";

const router = useRouter()
const crmStore = useCrmStore()
const websiteStore = useWebsiteStore()

const onBackClick = () => {
  websiteStore.setupInit(crmStore.initSiteId as number, crmStore.configModel, crmStore.initPlatformToken, crmStore.initCrmTenantCompanyName, crmStore.initCrmTenant)
  router.push('/crm')
  crmStore.reset()
}

</script>

<template>
  <div class="init-success">
    <n-result status="success" title="对接成功" description="恭喜，已成功完成CRM系统对接。">
      <template #footer>
        <n-button @click="onBackClick" type="primary" size="large">返回</n-button>
      </template>
    </n-result>
  </div>
</template>

<style lang="scss" scoped>
.init-success {
  padding-top: 20px;
}
</style>
