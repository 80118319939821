<script lang="ts" setup>
import { onMounted, reactive, ref, toRefs, watchEffect } from "vue";
import {
  NButton,
  FormRules,
  FormInst,
  NSpace,
  NSkeleton,
  NImage,
} from "naive-ui";
import LoginAgreement from "./LoginAgreement.vue";

import { useThemeVars } from "naive-ui";
import useSmsCode from "@/hooks/business/useSmsCode";
import { formRules, getConfirmPwdRule } from "@/util/form";
import useWechatRegister from "@/hooks/business/useWechatRegister";
import { useRouter } from "vue-router";
import UserService from "@/service/userService";
const themeVars = useThemeVars();
/**
 * State
 */
const registerLoading = ref(false);
const currentStep = ref<"code" | "form">("code");
const { label, isCounting, loading: smsLoading, getSmsCode } = useSmsCode();
const formRef = ref<(HTMLElement & FormInst) | null>(null);
const model = reactive({
  nickname: "",
  email: "",
  phone: "",
  phoneCode: "",
  password: "",
  confirmPwd: "",
  unionId: "", // todo: 这里和之前不一样了，要检查后端的注册接口，看怎么处理 null 的，要把 “” 也当成 null
  avatarUrl: "",
  invitationToken: "",
  invitationToEmail: "",
});
const agreement = ref(false);
const rules: FormRules = {
  nickname: formRules.nickName,
  email: formRules.email,
  phone: formRules.phone,
  phoneCode: formRules.code,
  password: formRules.pwd,
  confirmPwd: getConfirmPwdRule(toRefs(model).password),
};

/**
 * Hooks
 */
const { loading, wechatCodeImg, result, resultData, stopQuery } =
  useWechatRegister();
const router = useRouter();

// listen scan result
watchEffect(() => {
  if (result.value) {
    model.unionId = resultData.value.unionId;
    model.nickname = resultData.value.nickname;
    model.avatarUrl = resultData.value.avatarUrl;
    currentStep.value = "form";
  }
});

onMounted(() => {
  const state = router.options.history.state;

  if (state.invitationToken && state.invitationToken !== "") {
    model.invitationToken = state.invitationToken as string;
    model.email = state.toEmail as string;
    model.invitationToEmail = state.toEmail as string;
  }

  if (state.unionId && state.unionId !== "") {
    model.unionId = state.unionId as string;
    model.nickname = state.userName as string;
    model.avatarUrl = state.userImg as string;
    currentStep.value = "form";
    // 手动 stop
    stopQuery();
  }
});

/** Handler */
const onNextStep = () => {
  stopQuery();
  currentStep.value = "form";
};

const handleRegister = async () => {
  if (model.invitationToken !== "" && model.invitationToEmail !== model.email) {
    return window.$message?.error(
      `需要以受邀 Email: ${model.invitationToEmail} 注册`
    );
  }
  try {
    // login
    registerLoading.value = true;
    const result = await UserService.registerUser(
      model.phone,
      model.phoneCode,
      model.password,
      model.nickname,
      model.email,
      model.unionId,
      model.avatarUrl,
      model.invitationToken
    );
    loading.value = false;
    window.$message?.success("注册成功");
    window.$message?.info("自动登录中...");
    await UserService.login(model.phone, model.password);
    window.$message?.success("登录成功");

    if (router.options.history.state.fromUrl) {
      router.push({
        path: router.options.history.state.fromUrl as string,
        query: router.options.history.state.query as any,
      });
    } else {
      router.push({
        name: "dashboard",
      });
    }
    // todo: auto login
  } catch (e: any) {
    window.$message?.error(e.message);
    registerLoading.value = false;
  }
};

function handleSubmit(e: any) {
  if (!formRef.value) return;
  e.preventDefault();

  formRef.value.validate((errors) => {
    if (!errors) {
      if (!agreement.value) {
        window.$message?.info("请先勾选用户协议!")
        return
      };
      handleRegister();
    } else {
      // window.$message?.error("验证失败!");
    }
  });
}

const handleSmsCode = () => {
  getSmsCode(model.phone);
};
</script>

<template>
  <div class="snRegisterWrap">
    <div class="snRegister">
      <h2 class="snRegister__title">欢迎注册超新星</h2>
      <div class="snRegister__stepCode" v-if="currentStep === 'code'">
        <div class="snRegister__stepCodeCodeImg">
          <n-skeleton
            v-if="loading"
            height="240px"
            width="240px"
            style="margin: 0 auto"
          />
          <n-image
            v-else
            width="240"
            height="240"
            :src="wechatCodeImg"
            preview-disabled
          />
        </div>
        <div class="snRegister__stepCodeTitle">微信扫码快速注册</div>
        <div class="snRegister__stepCodeDes">
          随时查看网站数据，扫码后请点击登录
        </div>
        <n-button type="primary" class="snRegister__nextBtn" @click="onNextStep"
          >下一步</n-button
        >
      </div>
      <div class="snRegister__stepForm" v-if="currentStep === 'form'">
        <n-form
          ref="formRef"
          :model="model"
          :rules="rules"
          size="large"
          :show-label="false"
        >
          <n-form-item path="nickname">
            <n-input
              v-model:value="model.nickname"
              placeholder="昵称"
              @keyup.enter.native="handleSubmit"
            />
          </n-form-item>
          <n-form-item path="email">
            <n-input
              v-model:value="model.email"
              placeholder="邮箱"
              @keyup.enter.native="handleSubmit"
            />
          </n-form-item>
          <n-form-item path="phone">
            <n-input
              v-model:value="model.phone"
              placeholder="手机号码"
              @keyup.enter.native="handleSubmit"
            />
          </n-form-item>
          <n-form-item path="phoneCode">
            <div class="flex-y-center w-full">
              <n-input
                v-model:value="model.phoneCode"
                placeholder="验证码"
                @keyup.enter.native="handleSubmit"
              />
              <div class="w-18px"></div>
              <n-button
                size="large"
                :disabled="isCounting"
                :loading="smsLoading"
                @click="handleSmsCode"
              >
                {{ label }}
              </n-button>
            </div>
          </n-form-item>
          <n-form-item path="password">
            <n-input
              type="password"
              show-password-on="mousedown"
              v-model:value="model.password"
              @keyup.enter.native="handleSubmit"
              placeholder="密码"
            />
          </n-form-item>
          <n-form-item path="confirmPwd">
            <n-input
              type="password"
              show-password-on="mousedown"
              v-model:value="model.confirmPwd"
              @keyup.enter.native="handleSubmit"
              placeholder="确认密码"
            />
          </n-form-item>
          <n-space :vertical="true" :size="18">
            <login-agreement v-model:value="agreement" />
            <n-button
              type="primary"
              size="large"
              :block="true"
              :round="true"
              :loading="registerLoading"
              :diable="registerLoading"
              @click="handleSubmit"
              @keyup.enter.native="handleSubmit"
              >创建账号</n-button
            >
          </n-space>
        </n-form>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.snRegisterWrap {
  padding-top: 100px;
}
.snRegister {
  width: 550px;
  background: #ffffff;
  box-shadow: 0px 15px 50px rgb(12 51 109 / 6%);
  border-radius: 10px;
  min-height: 520px;
  margin: 0 auto;

  .snRegister__title {
    height: 75px;
    border-bottom: 1px solid #e5e6eb;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 20px;
  }

  .snRegister__stepForm,
  .snRegister__stepCode {
    padding-top: 40px;
  }

  .snRegister__stepCode {
    text-align: center;
  }

  .snRegister__stepCodeTitle {
    margin-top: 30px;
  }

  .snRegister__stepCodeDes {
    font-size: 12px;
    color: gray;
    margin-top: 5px;
    margin-bottom: 20px;
  }
}

.snRegister__stepForm {
  padding-left: 80px;
  padding-right: 80px;
  padding-bottom: 30px;
}

.flex-y-center.w-full {
  display: flex;
  width: 100%;
}

.w-18px {
  margin-right: 18px;
}
</style>
