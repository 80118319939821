<script setup lang="ts">

import { useMessage, NEllipsis } from 'naive-ui';
import { onMounted, ref, watchEffect } from 'vue';
import UserService from '@/service/UserService';
import { Logger } from '@/logger';
import { useWebsiteStore } from '@/stores/website';
import { useNotificationStore } from '@/stores/notification';
import router from '@/router';

const message = useMessage()
const websiteState = useWebsiteStore()
const notificationState = useNotificationStore()

const fetchNotificationData = async () => {
    try {
        if (notificationState.loading === false) return // already loaded
        const result = await UserService.getNotifications()
        notificationState.setNotification(result.slice(0, 4))
        Logger.debug('notification result', result)
    } catch (e:any) {
        message.error(e.message)
    }
}

watchEffect(() => {
    // todo: check site expire date when site change
})

const onExtendAdsClick = () => {
    router.push({
        name: "create",
        state: {
            pid: 273 // 273 is ads service product is, hard coded on purpose
        }
    })
}

onMounted(() => {
    fetchNotificationData()
})


</script>

<template>
    <div class="six sys-notification">
        <h2 class="setting-title">系统提醒<span class="site-expire-time">(站点有效至: <span class="expire-time">{{websiteState.getCurrentWebsiteActiveUntil() || "未知"}})</span></span></h2>
        <div class="notification-wrap">
            <a class="notification expire-notification pointer" v-if="websiteState.isCurrentWebsiteAdsExpireSoon()" @click="onExtendAdsClick">
                广告到期时间: <span class="expire-time">{{websiteState.getCurrentWebsiteAdsActiveUntil() || "未知"}}</span>
            </a>
            <!-- <a class="notification expire-notification">
                站点到期时间: <span class="expire-time">{{websiteState.getCurrentWebsiteActiveUntil() || "未知"}}</span>
            </a> -->
            <!-- todo: site expire notification here -->
            <a v-for="item in notificationState.notification" :index="item.id" :href="item.notificationLink" target="_blank" class="notification">
                <n-ellipsis>
                    {{item.notificationTitle}}
                </n-ellipsis>
            </a>
        </div>
    </div>
</template>

<style scoped lang="scss">
.site-expire-time {
    font-size: 14px;
    color: #999999;
    padding-left: 5px;
}
.notification.expire-notification {
    user-select: none;
    // background: #e8f0ff;
    border-radius: 10px;
}
.more-settings,
.sys-notification {
    padding: 20px 30px !important;
}
.notification-wrap {
    padding-top: 20px;
    .notification {
        display: block;
        text-decoration: none;
        color: #666666;
        margin-bottom: 5px;
        padding: 5px 15px;
        transition: all 0.3s;
        &:hover {
            background: #e8f0ff;
            border-radius: 10px;
        }
    }
}
</style>
