<script lang="ts" setup>
import TopBar from "@/components/TopBar.vue";
import ContentService from "@/service/contentService";
import { useWebsiteStore } from "@/stores/website";
import { useMessage, NGrid, NGi, NCheckboxGroup, NCheckbox, NSpace, NPagination, NSpin, NEmpty, NCard, NIcon, NPopover } from "naive-ui";
import { nextTick, onMounted, ref, watch } from "vue";
import useOneSitePermission from "@/hooks/business/useOneSitePermission";
const websiteState = useWebsiteStore()
const message = useMessage()
useOneSitePermission('文案库')

const taxOjb = ref<any>({
    tone: [],
    text_type: [],
    page_type: [],
    industry: [],
    block_type: []
})
const industry = ref<number[]>([])
const tone = ref<number[]>([])
const text_type = ref<number[]>([])
const page_type = ref<number[]>([])
const block_type = ref<number[]>([])
const contentItems = ref<any[]>([])
const loadingContentItems = ref<boolean>(true)
const pageSize = ref<number>(5)
const pageCount = ref<number>(1)
const currentPage = ref<number>(1)
const scroll: any = ref(null) // dom ref

onMounted(async () => {
    try {
        const tax = await ContentService.getTax()
        taxOjb.value = tax
    } catch (e: any) {
        message.error('获取数据出错')
    }
})

onMounted(() => {
    listItems({
        posts_per_page: pageSize.value,
        paged: currentPage.value
    })
    // document!.querySelector('.site-item.active')!.classList.remove('active')
})

const listItems = async (requestOjb: any) => {
    loadingContentItems.value = true
    try {
        const res: any = await ContentService.list(requestOjb)
        contentItems.value = res.items
        pageCount.value = res.max_num_pages
        currentPage.value = res.current_page
        await nextTick() // wait for DOM update
        scroll.value.update() // update scroll

    } catch (e: any) {

    }
    loadingContentItems.value = false
}

const copyToClipboard = (text: string) => {
    var input = document.createElement('input');
    input.setAttribute('value', text);
    document.body.appendChild(input);
    input.select();
    var result = document.execCommand('copy');
    document.body.removeChild(input);
    return result;
}

const copyContent = (content: string) => {
    copyToClipboard(content)
    message.success('复制成功')
}

watch([industry, tone, text_type, page_type, block_type, pageSize, currentPage], async (val: any, prevVal: any) => {
    const requestOjb: any = {
        posts_per_page: pageSize.value,
        paged: currentPage.value
    }
    if (val[0].length != prevVal[0].length && industry.value.length > 0) {
        requestOjb.industry = industry.value.join(',')
        requestOjb.paged = 1
    }
    if (val[1].length != prevVal[1].length && tone.value.length > 0) {
        requestOjb.tone = tone.value.join(',')
        requestOjb.paged = 1
    }
    if (val[2].length != prevVal[2].length && text_type.value.length > 0) {
        requestOjb.text_type = text_type.value.join(',')
        requestOjb.paged = 1
    }
    if (val[3].length != prevVal[3].length && page_type.value.length > 0) {
        requestOjb.page_type = page_type.value.join(',')
        requestOjb.paged = 1
    }
    if (val[4].length != prevVal[4].length && block_type.value.length > 0) {
        requestOjb.block_type = block_type.value.join(',')
        requestOjb.paged = 1
    }
    // if paged size has change
    if (val[5] != prevVal[5] || val[6] != prevVal[6]) {
        requestOjb.paged = val[5] != prevVal[5] ? 1 : requestOjb.paged
        // todo: refactor below code
        if (industry.value.length > 0) {
            requestOjb.industry = industry.value.join(',')
        }
        if (tone.value.length > 0) {
            requestOjb.tone = tone.value.join(',')
        }
        if (text_type.value.length > 0) {
            requestOjb.text_type = text_type.value.join(',')
        }
        if (page_type.value.length > 0) {
            requestOjb.page_type = page_type.value.join(',')
        }
        if (block_type.value.length > 0) {
            requestOjb.block_type = block_type.value.join(',')
        }
    }
    listItems(requestOjb)
})

</script>

<template>
    <div class="main-container">
        <top-bar use-brand-title use-copy-title />
        <perfect-scrollbar ref="scroll">
            <div class="content-lib-wrap">
                <n-grid x-gap="12" :cols="12">
                    <n-gi span="3">
                        <n-card class="filter-side-card">
                            <div class="tax-fiter-side">
                                <div class="dimension-wrap">
                                    <h2>按行业筛选</h2>
                                    <n-checkbox-group v-model:value="industry">
                                        <n-space item-style="display: flex;">
                                            <template v-for="item in taxOjb.industry" :key="item.name">
                                                <n-checkbox :value="item.id" :label="item.name" />
                                            </template>
                                        </n-space>
                                    </n-checkbox-group>
                                </div>
                                <div class="dimension-wrap">
                                    <h2>按语言风格筛选</h2>
                                    <n-checkbox-group v-model:value="tone">
                                        <n-space item-style="display: flex;">
                                            <template v-for="item in taxOjb.tone" :key="item.name">
                                                <n-checkbox :value="item.id" :label="item.name" />
                                            </template>
                                        </n-space>
                                    </n-checkbox-group>
                                </div>
                                <div class="dimension-wrap">
                                    <h2>按文本类型筛选</h2>
                                    <n-checkbox-group v-model:value="text_type">
                                        <n-space item-style="display: flex;">
                                            <template v-for="item in taxOjb.text_type" :key="item.name">
                                                <n-checkbox :value="item.id" :label="item.name" />
                                            </template>
                                        </n-space>
                                    </n-checkbox-group>
                                </div>
                                <div class="dimension-wrap">
                                    <h2>按页面筛选</h2>
                                    <n-checkbox-group v-model:value="page_type">
                                        <n-space item-style="display: flex;">
                                            <template v-for="item in taxOjb.page_type" :key="item.name">
                                                <n-checkbox :value="item.id" :label="item.name" />
                                            </template>
                                        </n-space>
                                    </n-checkbox-group>
                                </div>
                                <div class="dimension-wrap">
                                    <h2>按页面板块筛选</h2>
                                    <n-checkbox-group v-model:value="block_type">
                                        <n-space item-style="display: flex;">
                                            <template v-for="item in taxOjb.block_type" :key="item.name">
                                                <n-checkbox :value="item.id" :label="item.name" />
                                            </template>
                                        </n-space>
                                    </n-checkbox-group>
                                </div>
                            </div>
                        </n-card>

                    </n-gi>
                    <n-gi span="9">
                        <n-card class="sentence-card">
                            <div class="content-main">
                                <div class="content-items-wrap">
                                    <div v-if="loadingContentItems" class="content-loading-wrap">
                                        <n-spin size="large" />
                                    </div>
                                    <n-empty v-if="loadingContentItems === false && contentItems.length === 0"
                                        description="无匹配数据，请重新选择搜索条件" />
                                    <template v-for="item in contentItems" :key="item.postId">
                                            <div class="content-items">
                                                <div class="copy-icon-wrap">
                                                    <n-popover trigger="hover">
                                                    <template #trigger>
                                                        <n-icon size="40" @click="copyContent(item.contentDetail)">
                                                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 448 512"><path d="M320 448v40c0 13.255-10.745 24-24 24H24c-13.255 0-24-10.745-24-24V120c0-13.255 10.745-24 24-24h72v296c0 30.879 25.121 56 56 56h168zm0-344V0H152c-13.255 0-24 10.745-24 24v368c0 13.255 10.745 24 24 24h272c13.255 0 24-10.745 24-24V128H344c-13.2 0-24-10.8-24-24zm120.971-31.029L375.029 7.029A24 24 0 0 0 358.059 0H352v96h96v-6.059a24 24 0 0 0-7.029-16.97z" fill="currentColor"></path></svg>
                                                        </n-icon>
                                                        </template>
                                                        <span>点击复制文案</span>
                                                    </n-popover>

                                                </div>
                                                <div class="content-wrap">
                                                    <div class="content-label">
                                                        文案
                                                    </div>
                                                    <div class="content-content">
                                                        {{ item.contentDetail }}
                                                    </div>
                                                </div>
                                                <div class="content-source" style="display: none;">
                                                    <div class="content-label">
                                                        来源
                                                    </div>
                                                    <div class="content-content">
                                                        <a :href="item.source" target="__blank">{{ item.source }}</a>
                                                    </div>
                                                </div>
                                                <div class="content-translation">
                                                    <div class="content-label">
                                                        翻译
                                                    </div>
                                                    <div class="content-content">
                                                    {{ item.contentTranslation ? item.contentTranslation : '暂无翻译' }}
                                                    </div>
                                                </div>
                                                <div class="content-analysis">
                                                    <div class="content-label">
                                                        点评
                                                    </div>
                                                    <div class="content-content">
                                                    {{ item.contentAnalysis ? item.contentAnalysis : '暂无点评' }}
                                                    </div>
                                                </div>
                                            </div>
                                    </template>
                                </div>
                                <div v-if="contentItems.length > 0" class="content-pagination">
                                    <n-pagination v-model:page="currentPage" v-model:page-size="pageSize"
                                        :page-count="pageCount" show-size-picker :page-sizes="[5, 10, 20]"
                                        :disabled="loadingContentItems" />
                                </div>
                            </div>
                        </n-card>
                    </n-gi>
                </n-grid>
            </div>
        </perfect-scrollbar>
    </div>
</template>

<style lang="scss" scoped>

.content-lib-wrap {
    padding: 0 50px;
    padding-bottom: 50px;
    padding-top: 50px;
}

.content-lib-wrap :deep(.sentence-card) {
  background-color: transparent!important;
  border: none;
  .n-card__content {
    padding-top: 5px!important;
  }
}

.content-lib-wrap :deep(.filter-side-card) {
  background: #fcfdff;
  box-shadow: 0 15px 50px #0c336d0f;
  border-radius: 20px;
  border: 0;
}

.content-items {
    position: relative;
    margin-bottom: 25px;
    padding: 8px 60px 20px 20px;
    background: #fcfdff;
    border-radius: 15px;
    box-shadow: none;
}

.content-pagination {
    display: flex;
    justify-content: flex-end;
}

.dimension-wrap {
    margin-bottom: 20px;

    h2 {
        font-weight: 500;
        padding: 5px 0;
        border-bottom: 1px solid #edf4ff;
        margin-bottom: 10px;
    }
}

.content-items-wrap {
    position: relative;

    .content-loading-wrap {
        z-index: 999;
        position: absolute;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #edf4ff7d;
    }
}

.content-items > div {
    display: flex;
}

.content-label {width: 38px;}

.content-content {width: 100%;}

.copy-icon-wrap {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;

    svg {
        color: #5075d0;
    }
}

.content-label {
    width: 38px;
    font-weight: 500;
    user-select: none;
}
.content-content {
    user-select: none;
     a {
        color: gray;
        text-decoration: none;
    }
}
.content-analysis, .content-translation {
    .content-content {
        color: gray;
    }
}

.content-items > div {
    display: flex;
    margin: 7px 0;
}

.content-wrap .content-content {
    border: 1px dotted #5276d0;
    border-style: dashed;
    padding: 15px;
    border-radius: 5px;
}

.content-wrap .content-label {
    display: none;
}
.n-card.n-card--bordered.filter-side-card {
    position: sticky;
    top: 15px;
}
</style>
