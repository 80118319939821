<script setup lang="ts">
import { useWebsiteStore } from '@/stores/website';
import { useRoute } from 'vue-router';
import { onMounted, ref, watchEffect } from 'vue';
import UserService from '@/service/userService';
import { countryToCodeMap, countryToCoodinates } from '@/config'
import { computed } from '@vue/reactivity';
import { Logger } from '@/logger';
import { range } from 'lodash';

const props = defineProps<{
    range: [number, number]
}>()

const metaResult = ref<any>([])
const metaInit = ref(false)

watchEffect(() => {
    Logger.debug('range change', props.range)
    const sid = route.params.sid
    fetchDataAndInitMap(sid as string)
})

const computedMetaResult = computed(() => {
    const time1 = props.range[0]
    const time2 = props.range[1]
    if (!metaResult || !metaResult.value) return []

    return metaResult.value.filter((meta: any) => {
        const metaDate = new Date(meta.date.split(" ")[0]).getTime()
        if (time1 === time2 && time2 === metaDate) return true
        if(time1 < metaDate && metaDate < time2) return true
        return false
    })
})

/**
 * State
 */
const websiteState = useWebsiteStore()
const route = useRoute()
const loadingLeadMeta = ref(true)
const bubble_map = ref<any>(null)

const getCountryLeadCountMap = (data: any[]) => {
    const result: any = {}
    data.map(item => {
        if (result[item.country]) {
            result[item.country] = result[item.country] + 1
        } else {
            result[item.country] = 1
        }
    })
    return result
}

const findScale = (smin: number, smax: number, acutalmin: number, actualMax: number, target: number) => {
    const diffS = smax - smin
    const diffA = actualMax - acutalmin
    const diffRatio = diffS / diffA
    const targetS = target * diffRatio
    return Math.ceil(targetS) + smin
}

const generateBubbleArr = (countryLeadCountMap: any) => {
    const countries: any = Object.keys(countryLeadCountMap)
    let max = 0
    for (const country of countries) {
        if (countryLeadCountMap[country] > max) {
            max = countryLeadCountMap[country]
        }
    }

    const bubbleArr: any = []
    for (const country of countries) {
        const bubbleConfig: any = {
            radius: findScale(5, 30, 1, max, countryLeadCountMap[country]),
            leads: countryLeadCountMap[country],
            // centered: "BRA",
            country: country,
            fillKey: "BUBBLE",
            // latitude: 22.302711,
            // longitude: 114.177216
        }
        // try to get center
        if (countryToCodeMap[country]) {
            bubbleConfig.centered = countryToCodeMap[country]
            bubbleArr.push(bubbleConfig)
            continue
        }
        // get coodinates
        if (countryToCoodinates[country]) {
            bubbleConfig.latitude = countryToCoodinates[country].latitude
            bubbleConfig.longitude = countryToCoodinates[country].longitude
            bubbleArr.push(bubbleConfig)
            continue
        }
    }
    return bubbleArr
}

const fetchDataAndInitMap = async (siteId: string) => {
    loadingLeadMeta.value = true
    if (!metaInit.value) {
        const result = await UserService.leadsMetaPlatform(Number(siteId))
        metaResult.value = result
        metaInit.value = true
    }
    loadingLeadMeta.value = false
    const countryLeadCountMap = getCountryLeadCountMap(computedMetaResult.value)
    Logger.debug('countryLeadCountMap', countryLeadCountMap)
    const bubbleArr = generateBubbleArr(countryLeadCountMap)
     Logger.debug('bubbleArr', bubbleArr)

    // var bubble_map = new window.Datamap({
    //     element: document.getElementById("container"),
    //     geographyConfig: {
    //         popupOnHover: false,
    //         highlightOnHover: false,
    //         borderWidth: 0.5,
    //         borderColor: "#6a6f73",
    //     },
    //     bubblesConfig: {
    //         borderWidth: 1,
    //         borderColor: '#d1d7dc',
    //         highlightOnHover: false,
    //         highlightFillColor: '#FC8D59'
    //     },
    //     fills: {
    //         MAJOR: "#306596",
    //         MEDIUM: "#0fa0fa",
    //         MINOR: "#bada55",
    //         BUBBLE: "#76c5d6",
    //         defaultFill: "#f7f9fa",
    //     }
    // });
    Logger.debug('[bubble_map.value before]', bubble_map.value)
    if (bubble_map.value !== null) {
        Logger.debug('[bubble_map.value]', bubble_map.value)
        bubble_map.value.bubbles(
            bubbleArr,
            {
                popupTemplate: function (geo: any, data: any) {
                    let left = ""
                    if (data.country === 'New Zealand') {
                        left = 'left: 1130px;'
                    }
                    return (
                        `<div style="${left} color: white; background-color: black; width: 100%;" class="hoverinfo">国家:` +
                        data.country +
                        " </br> " +
                        " 询盘数: " +
                        data.leads +
                        ""
                    );
                }
            }
        );
    }

}

/**
 * Handler
 */
onMounted(() => {
    const sid = route.params.sid
    if (sid) {
        bubble_map.value = new window.Datamap({
            element: document.getElementById(`container-${sid}`),
            geographyConfig: {
                popupOnHover: false,
                highlightOnHover: false,
                borderWidth: 0.5,
                borderColor: "#6a6f73",
            },
            bubblesConfig: {
                borderWidth: 1,
                borderColor: '#d1d7dc',
                highlightOnHover: false,
                highlightFillColor: '#FC8D59'
            },
            fills: {
                MAJOR: "#306596",
                MEDIUM: "#0fa0fa",
                MINOR: "#bada55",
                BUBBLE: "#408cff", // #408cff // #76c5d6
                defaultFill: "#f7f9fa",
            }
        });
        Logger.debug('onMounted about  to fetchData and initmap', 'hihi')
        fetchDataAndInitMap(sid as string)
    }
})


</script>

<template>
    <div class="lead-world-map-wrap" style="padding: 20px; margin-bottom: 30px;">
        <h2>询盘分布</h2>
        <div :id="`container-${route.params.sid}`" style="position: relative; height: 550px; width: 100%;"></div>
    </div>
</template>

<style scoped lang="scss">
h2 {
    font-size: 24px;
    border-bottom: 1px solid #d1d7dc;
    padding-bottom: 5px;
    font-weight: bold;
}
</style>
