import { acceptHMRUpdate, defineStore } from 'pinia'
import { ref } from 'vue'
import {IMappingConfig} from "@/service/CrmService";

interface IWebsite {
    blogId: number,
    name: string,
    customName: string | null,
    domain: string,
    siteurl: string,
    favicon: string,
    customfavicon: string | null,
    customDomain: string
    isOwner: boolean,
    activeUntil: string,
    adsActiveUntil: string,
    hasInit?: boolean,
    initCreatedUid?: number,
    isInitOwner?: boolean,
    initTenant?: string,
    initKey?: string,
    initTenantCompany?: string,
    initConfig?: string,
}

export const useWebsiteStore = defineStore('website', () => {
    const defaultWebsiteList: IWebsite[] = []

    /**
     * Reative Objects
     */
    const websites = ref<IWebsite[]>(defaultWebsiteList)
    const loading = ref(true)
    const currentWebsiteId = ref<number>(0)
    const currentWebsiteName = ref<string>()

    /**
     * Actions
     */
    const setWebsites = (payload: IWebsite[]) => {
        const excludeMainBlog = payload.filter(website => website.blogId !== 1)
        websites.value = excludeMainBlog
        loading.value = false
        if (excludeMainBlog.length !== 0) {
            currentWebsiteId.value = excludeMainBlog[0].blogId
            currentWebsiteName.value = excludeMainBlog[0].name
        }
    }
    const resetForLogout = () => {
        websites.value = []
        loading.value = true
    }
    const setCurrentWebsite = (sid: number | null) => {
        if (sid === null) {
            currentWebsiteId.value = 0
            return
        }
        const match = websites.value.filter(site => site.blogId === sid)
        if (match.length > 0) {
            currentWebsiteId.value = match[0].blogId
            currentWebsiteName.value = match[0].name
        }
    }
    const getCurrentWebsiteDomain = () => {
        const matchWebsite = websites.value.filter(site => site.blogId === currentWebsiteId.value)[0] || {}
        return matchWebsite.siteurl
    }
    const updateFavicon = (favicon: string) => {
        for (const item of websites.value) {
            if (item.blogId === currentWebsiteId.value) {
                item.customfavicon = favicon
            }
        }
    }
    // respect custom name
    const getCurrentWebsiteName = () => {
        const match = websites.value.filter(website=>website.blogId === currentWebsiteId.value)[0] || {}
        return match.customName ? match.customName : match.name
    }
    const updateWebsiteName = (name: string) => {
        for (const item of websites.value) {
            if (item.blogId === currentWebsiteId.value) {
                item.customName = name
            }
        }
    }
    const getCurrentSiteFavicon = () => {
        const match = websites.value.filter(website=>website.blogId === currentWebsiteId.value)[0]
        return match.customfavicon ? match.customfavicon : match.favicon
    }
    const getCurrentSiteCustomDomain = () => {
        const match = websites.value.filter(website=>website.blogId === currentWebsiteId.value)[0] || {}
        return match.customDomain
    }
    const getCurrentSiteDefaultDomain = () => {
        const match = websites.value.filter(website=>website.blogId === currentWebsiteId.value)[0] || {}
        return match.siteurl
    }
    const getCurrentSiteDomain = () => {
        // custom domain -> siteurl
        const domain = (getCurrentSiteCustomDomain() || getCurrentSiteDefaultDomain())
        return domain
    }
    const getCurrentSitePureDomain = () => {
        const match = websites.value.filter(website=>website.blogId === currentWebsiteId.value)[0]
        return match.domain
    }
    const updateCustomDomainForCurrentSite = (customDomain: string) => {
        for (const item of websites.value) {
            if (item.blogId === currentWebsiteId.value) {
                item.customDomain = customDomain
            }
        }
    }
    const isOwnerForCurrentSite = () => {
        const match = websites.value.filter(website=>website.blogId === currentWebsiteId.value)[0]
        return match.isOwner
    }
    const getCurrentWebsiteActiveUntil = () => {
        const match = websites.value.filter(website=>website.blogId === currentWebsiteId.value)[0]
        return match.activeUntil;
    }
    const getCurrentWebsiteAdsActiveUntil = () => {
        const match = websites.value.filter(website=>website.blogId === currentWebsiteId.value)[0]
        return match.adsActiveUntil;
    }
    const isCurrentWebsiteAdsActive = () => {
        const match = websites.value.filter(website=>website.blogId === currentWebsiteId.value)[0]
        const dateStr = match.adsActiveUntil
        if (dateStr === '') return false
        return new Date(dateStr) > new Date()
    }

    const isCurrentWebsiteAdsExpireSoon = () => {
        const match = websites.value.filter(website=>website.blogId === currentWebsiteId.value)[0]
        const dateStr = match.adsActiveUntil

        if (dateStr === '') return false
        if (new Date(dateStr) < new Date()) return false

        const futureDay = new Date();
        futureDay.setDate(futureDay.getDate() + 7);
        return futureDay > new Date(dateStr)
    }

    const getCurrentSiteRemainingExpireDaysForAds = () => {
        const match = websites.value.filter(website=>website.blogId === currentWebsiteId.value)[0]
        const dateStr = match.adsActiveUntil

        if (dateStr === '') return 0
        if (new Date(dateStr) < new Date()) return 0

        const timeDiff:any = new Date(dateStr) as any - (new Date() as any)
        const dayDiff = Math.ceil(timeDiff / (1000 * 3600 * 24))
        return dayDiff
    }

    const getValidIntegrations = (): IWebsite[] => {
        const match = websites.value.filter(website=>website.hasInit)
        if (match === null) return []
        return match
    }

    const updateMapping = (siteId: number, mapping: IMappingConfig[]) => {
        for (const item of websites.value) {
            if (item.blogId === siteId) {
                item.initConfig = JSON.stringify(mapping)
            }
        }
    }

    const setupInit = (siteId: number, mapping: IMappingConfig[], platformKey: string, companyName: string, tenantId: string) => {
        for (const item of websites.value) {
            if (item.blogId === siteId) {
                item.initConfig = JSON.stringify(mapping)
                item.initKey = platformKey
                item.initTenant = tenantId
                item.initTenantCompany = companyName
                item.hasInit = true
                item.isInitOwner = true
            }
        }
    }

    /**
     * Expose State API
     */
    return {
        websites,
        currentWebsiteId,
        currentWebsiteName,
        loading,
        setupInit,
        updateMapping,
        isCurrentWebsiteAdsActive,
        isCurrentWebsiteAdsExpireSoon,
        isOwnerForCurrentSite,
        getCurrentWebsiteActiveUntil,
        updateFavicon,
        getCurrentSiteRemainingExpireDaysForAds,
        getCurrentSitePureDomain,
        getCurrentWebsiteAdsActiveUntil,
        getCurrentSiteFavicon,
        getCurrentWebsiteDomain,
        getCurrentWebsiteName,
        getCurrentSiteDomain,
        getCurrentSiteDefaultDomain,
        updateCustomDomainForCurrentSite,
        getCurrentSiteCustomDomain,
        updateWebsiteName,
        setWebsites,
        resetForLogout,
        setCurrentWebsite,
        getValidIntegrations
    }
})
