<script setup lang="ts">
import { currencyFilter } from '@/filters';
import { Logger } from '@/logger';
import OrderService from '@/service/orderService';
import { useCreateStore } from '@/stores/create';
import { useProductStore } from '@/stores/product';
import { useTemplateStore } from '@/stores/template';
import { useWebsiteStore } from '@/stores/website';
import { useMessage } from 'naive-ui';
import { computed, onMounted, ref, watchEffect } from 'vue';
import { useRouter } from 'vue-router';


const router = useRouter()
const createState = useCreateStore()
const websiteState = useWebsiteStore()
const templateState = useTemplateStore()
const productState = useProductStore()
const message = useMessage()
const placingOrder = ref<boolean>(false)

const serviceProduct = computed(() => {
    const product = productState.getProductByIds([createState.pid])
    if (product.length > 0) {
        return product[0]
    } else {
        return {} as any
    }
})

const selectedTemplateName = computed(() => {
    const currentSelectedTemplateId = createState.selectedTemplateId
    const match = templateState.templates.filter(template => template.id === currentSelectedTemplateId)
    return match.length > 0 ? match[0].name : ''
})

const websiteProductPrice = computed(() => {
    if (productState.loading === false) {
        return productState.getWebsiteProduct().unitPrice
    }
    return 0
})

const recomdProuducts = computed(() => {
    if (productState.loading === false) {
        const allProducts = productState.getRecommendedProducts()
        return allProducts.filter(product => !createState.selectedRecomProductIds.includes(product.id))
    }
    return []
})

const selectedRecomProducts = computed(() => {
    if (productState.loading === false) {
        return productState.getRecommendedProducts().filter(product => createState.selectedRecomProductIds.includes(product.id))
    }
    return []
})

const addRecomProduct = (productId: number) => {
    const newSelectedRecomProductIds = [...createState.selectedRecomProductIds, productId]
    createState.setSelectedRecomProductIds(newSelectedRecomProductIds)
}

const calPriceWithCoupon = async (allProductId: number[], couponCode: string) => {
    try {
        const calPriceResult = await OrderService.calPrice(allProductId, couponCode)
        createState.orderPricingOjb.totalPrice = calPriceResult.totalPrice
        createState.orderPricingOjb.finalTotalPrice = calPriceResult.finalTotalPrice
        createState.orderPricingOjb.saving = calPriceResult.totalPrice - calPriceResult.finalTotalPrice  
        
        createState.totalPrice = calPriceResult.totalPrice
        createState.finalTotalPrice = calPriceResult.finalTotalPrice
        createState.saving = calPriceResult.totalPrice - calPriceResult.finalTotalPrice  

        message.success(`已优惠: ${currencyFilter(createState.saving)}`)
    } catch (e: any) {
        message.error(e.message)
    }
}

const onApplyCoupon = () => {
    if (createState.couponCode.length < 6) {
        return;
    }
    if (createState.blogId !== 0) {
        // service product
        const allProductId = [createState.pid]
        calPriceWithCoupon(allProductId, createState.couponCode)
    } else {
        // website product
        const mainProductId = productState.getWebsiteProduct().id
        const allProductId = [mainProductId, ...createState.selectedRecomProductIds]
        calPriceWithCoupon(allProductId, createState.couponCode)
    }
}

const removeRecomProduct = (productId: number) => {
    const newSelectedRecomProductIds = createState.selectedRecomProductIds.filter(id => id!== productId)
    createState.setSelectedRecomProductIds(newSelectedRecomProductIds)
}

const onConfirmOrderClick = async () => {
    if (
        (!createState.blogId) &&
        (!createState.selectedTemplateId || !createState.domain || !createState.brandName)
        ) {
        message.error("订单信息不完整, 请重新下单")
        return router.push({
            name: 'create',
            params: {
                step: 1
            }
        })
        
    }

    // todo: validation here
    if (placingOrder.value) return // avoid duplicate clicks
    // todo: place order here
    let allProductId = []
    
    if (createState.blogId !== 0) {
        // service product
        allProductId = [createState.pid]
    } else {
        // website product
        const mainProductId = productState.getWebsiteProduct().id
        allProductId = [mainProductId, ...createState.selectedRecomProductIds]
    }
    
    try {
        placingOrder.value = true
        const orderIdFromServer = await OrderService.placeOrder(
            allProductId,
            createState.selectedTemplateId,
            createState.domain,
            createState.brandName,
            createState.couponCode,
            createState.blogId,
        )
        placingOrder.value = false
        createState.orderId = orderIdFromServer
        // currentStep.value = 4

        router.push({
            name: "createStep",
            params: {
                step: 4
            }
        })
    } catch (e: any) {
        message.error(e.message)
        placingOrder.value = false
    }
}

const calTotalPriceByProductIds = (allProductId: number[]): number => {
    const products = productState.getProductByIds(allProductId)
    let sum = 0
    for (const product of products) {
        sum = sum + product.unitPrice
    }
    return sum
}

const calPrice = async (allProductId: number[]) => {
    try {
        // use client side calculated pricing first to optimize UI
        const clientSideCalculatedPrice = calTotalPriceByProductIds(allProductId)
        createState.totalPrice = clientSideCalculatedPrice
        createState.finalTotalPrice = clientSideCalculatedPrice

        const calPriceResult = await OrderService.calPrice(allProductId)
        createState.orderPricingOjb.totalPrice = calPriceResult.totalPrice
        createState.orderPricingOjb.finalTotalPrice = calPriceResult.finalTotalPrice
        createState.orderPricingOjb.saving = calPriceResult.totalPrice - calPriceResult.finalTotalPrice  
        
        createState.totalPrice = calPriceResult.totalPrice
        createState.finalTotalPrice = calPriceResult.finalTotalPrice
        createState.saving = calPriceResult.totalPrice - calPriceResult.finalTotalPrice  


        Logger.debug("createState.orderPricingOjb.totalPrice", createState.orderPricingOjb.totalPrice)
        Logger.debug("createState.orderPricingOjb.finalTotalPrice", createState.orderPricingOjb.finalTotalPrice)
        Logger.debug("createState.orderPricingOjb.saving", createState.orderPricingOjb.saving)
        Logger.debug("calPriceResult", calPriceResult)
    } catch (e: any) {
        message.error(e.message)
    }
}

watchEffect(() => {
    if (productState.loading === true) {
        return
    }
    if (createState.blogId && createState.blogId !== 0) {
        // means service product
        calPrice([createState.pid])
    } else {
        // means website product
        const mainProductId = productState.getWebsiteProduct().id
        const allProductId = [mainProductId, ...createState.selectedRecomProductIds]
        calPrice(allProductId)
    }
})

</script>

<template>
    <div class="step step-confirm-order" v-show="createState.currentStep === 3">
        <h2>请确认订单</h2>
        <div class="order-wrap" v-if="createState.blogId === 0" >
            <div class="row-header">
                <div class="header-label">产品名称</div>
                <div class="header-label">商品描述</div>
                <div class="header-label">价格</div>
            </div>
            <div class="row-product">
                <div class="product-name">超新星快速建站</div>
                <div class="product-des">
                    <div class="product-detail-row">已选范例网站: {{ selectedTemplateName }}</div>
                    <div class="product-detail-row">品牌名称: {{ createState.brandName }}</div>
                    <div class="product-detail-row">网站域名: {{ `https://${createState.domain}.msupernova.com` }}</div>
                </div>
                <div class="product-price">
                    <span class="price">{{ currencyFilter(websiteProductPrice) }}</span>
                </div>
            </div>
            <!-- TODO: render recomended products here -->
            <div class="row-product" v-for="product in selectedRecomProducts">
                <div class="product-name">{{ product.productTitle }}</div>
                <div class="product-des">{{ product.productDetail }}</div>
                <div class="product-price">
                    <span class="price">{{ currencyFilter(product.unitPrice) }}</span>
                    <span class="delete-order" @click="removeRecomProduct(product.id)">删除</span>
                </div>
            </div>
        </div>
        <div class="recomd-wrap" v-if="createState.blogId === 0 && recomdProuducts.length !== 0">
            <h2 class="recom-title">搭配购买更实惠</h2>
            <div class="row-product" v-for="product in recomdProuducts">
                <div class="product-name">
                    <div class="product-title">{{ product.productTitle }}</div>
                    <div class="product-recom-label">(推荐购买)</div>
                </div>
                <div class="product-des">{{ product.productDetail }}</div>
                <div class="product-price">
                    <div class="price recom">{{ currencyFilter(product.unitPrice) }}</div>
                    <div class="add-to-order btn pointer" @click="addRecomProduct(product.id)">加入订单</div>
                </div>
            </div>
        </div>
        <div class="order-wrap service-product" v-if="createState.blogId !== 0">
            <div class="row-header">
                <div class="header-label">产品名称</div>
                <div class="header-label">商品描述</div>
                <div class="header-label">价格</div>
            </div>
            <div class="row-product">
                <div class="product-name">
                    {{serviceProduct.productTitle}}
                </div>
                <div class="product-des">
                    <div class="product-detail-row">{{ serviceProduct.productDetail }}</div>
                    <div class="product-detail-row">站点名称: {{ websiteState.getCurrentWebsiteName() }}</div>
                    <div class="product-detail-row">站点域名: {{ websiteState.getCurrentSiteDomain() }}</div>
                </div>
                <div class="product-price">
                    <span class="price">{{ currencyFilter(serviceProduct.unitPrice) }}</span>
                </div>
            </div>
        </div>
        <div class="cta-fix-container">
            <div class="cta-wrap">
                <div class="coupon-wrap">
                    <div class="coupon-title">优惠券：</div>
                    <div class="coupon-input-wrap">
                        <input v-model="createState.couponCode" type="text" placeholder="请输入优惠券号" />
                        <div
                            @click="onApplyCoupon"
                            class="buton apply-coupon"
                            :class="createState.couponCode.length < 6 ? 'disabled' : ''"
                        >立即使用</div>
                    </div>
                </div>
                <div class="total-price-wrap">
                    <div class="total-price-wrap">
                        <span class="total-price-des">订单金额：</span>
                        <span
                            class="total-price"
                        >{{ currencyFilter(createState.finalTotalPrice) }}</span>
                        <span
                            class="total-discount"
                        >(已优惠 {{ currencyFilter(createState.saving) }})</span>
                    </div>
                    <div class="confirm-order-wrap">
                        <div
                            class="btn btn-confirm-order"
                            @click="onConfirmOrderClick"
                        >{{ placingOrder ? "正在下单..." : "提交订单" }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
.step-confirm-order {
    .delete-order {
        display: inline-block;
        border-radius: 10px;
        border: 1px solid #a5a0a0;
        padding: 3px 5px;
        margin-left: 10px;
        font-size: 12px;
        cursor: pointer;
        position: relative;
        top: -3px;
    }
}
.step-payment {
    .cta-fix-container {
        margin-top: 20px !important;
    }
    .payment-method,
    .fq-installment {
        display: grid;
        grid-column-gap: 10px;
        grid-template-columns: 1fr 1fr 1fr;
    }
    .payment-method {
        margin-bottom: 15px;
    }
    .coupon-wrap.payment-method-wrap {
        max-width: 650px;
        background: none;
        padding: 0 !important;
    }
    .cta-wrap {
        align-items: flex-end !important;
    }
    .payment-method-title {
        font-size: 24px;
        color: #333;
        font-weight: 600;
        margin-bottom: 25px;
    }

    .payment-method-item {
        cursor: pointer;
        background: rgba(232, 240, 255, 0.6);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 30px;
        border-radius: 10px;
    }
    .installment-item-wrap {
        text-align: center;
        background: rgba(232, 240, 255, 0.6);
        border-radius: 10px;
        padding: 30px 25px;
        font-size: 20px;
        color: #3e8bff;
        .payment-detail {
            font-weight: 600;
        }
        .payment-fee {
            font-size: 12px;
            color: #333;
            text-align: center;
            margin-top: 10px;
            margin-bottom: 10px;
        }
        .payment-cta {
            border: 1px solid #3e8bff;
            box-sizing: border-box;
            border-radius: 10px;
            font-size: 12px;
            text-align: center;
            display: inline-block;
            padding: 5px 10px;
            cursor: pointer;
        }
    }
    .payment-method-item, .installment-item-wrap {
        border: 2px solid transparent;
    }
    .active {
        border: 2px solid #3E8BFF;
    }
}
</style>
