<script lang="ts" setup>

import CrmService, {ICrmField, IMappingConfig, IWebsiteField} from "@/service/CrmService";
import {computed, onMounted, ref} from "vue";
import {useMessage} from "naive-ui";
import {useLoading} from "@/hooks/common";
import {useCrmStore} from "@/stores/crm";

const props = defineProps<{
  config?: IMappingConfig[] | null
}>()

const crmFields = ref<ICrmField[]>()
const websiteFields = ref<IWebsiteField[]>()
const message = useMessage()
const {loading, startLoading, endLoading} = useLoading(true)
const crmStore = useCrmStore()


const fieldsOptions = computed(() => {
  if (!crmFields.value) return []
  return crmFields.value.map((item) => ({
    label: item.label,
    value: item.field
  }))
})

const websiteFieldsOptions = computed(() => {
  if (!websiteFields.value) return []
  return websiteFields.value.map((item) => ({
    label: item.label,
    value: item.field,
    disabled: item.disabled
  }))
})

onMounted(() => {
  calDots()
})

const dots = ref('.')


const calDots = () => {
  if (!loading.value) {return}

  if (dots.value !== '...') {
    dots.value = dots.value + '.'
  } else {
    dots.value = ''
  }

  window.setTimeout(calDots, 500)
}

const showDebug = ref(false)

onMounted(() => {
  scanFields()
})

const scanFields = async () => {
  startLoading()
  try {
    const [ websiteFieldsRes, crmFieldsRes ] = await Promise.all([
      CrmService.getWebsiteFields(crmStore.initSiteId as number),
      CrmService.getCrmFields(crmStore.initCrmTenant, crmStore.initPlatformToken)
    ])

    websiteFields.value = websiteFieldsRes
    crmFields.value = crmFieldsRes

    const getFromFieldByToField = (toField: string) => {
      if (!props.config) return null;
      const match = props.config.filter((field: IMappingConfig) => field.toField == toField)
      if (match.length === 0) return null
      return match[0].fromField
    }

    for (const crmField of crmFieldsRes) {
      crmStore.configModel.push({
        fromField: getFromFieldByToField(crmField.field),
        toField: crmField.field
      })
    }
  } catch (e: any) {
    message.error(e.message)
  }
  endLoading()
}

</script>

<template>
<div class="field-map-inner">
  <template v-if="loading">
    <div class="field-loading">
      <n-spin size="large" />
      <div class="loading-title">正在扫描可用字段{{dots}}</div>
    </div>
  </template>
  <div class="field-map-row label" v-if="!loading">
    <div class="website-field-label">
      网站字段
    </div>
    <div class="arrow"></div>
    <div class="website-field-label">
      CRM字段
    </div>
  </div>
  <template v-if="!loading" v-for="(item, index) in crmFields">
    <div class="field-map-row">
      <n-select clearable :options="websiteFieldsOptions" v-model:value="crmStore.configModel[index]['fromField']"></n-select>
      <div class="arrow"> -></div>
      <n-select disabled :options="fieldsOptions" :value="item.field"></n-select>
    </div>
  </template>
  <template v-if="showDebug">
    <pre>{{ JSON.stringify(crmStore.configModel, null, 2) }}</pre>
  </template>
</div>
</template>

<style lang="scss" scoped>
.field-map-row {
  display: flex;
  align-items: center;
  padding: 10px 0;
}

.arrow {
  padding: 0 10px;
  width: 60px;
  text-align: center;
}

.fieldMapping-wrap {
  max-width: 800px;
  margin: 0 auto;
  padding-top: 20px;
}

.field-loading {
  text-align: center;
  padding-top: 50px;
}

.loading-title {
  font-size: 18px;
  margin-top: 18px;
  color: #202225;
  font-weight: 500;
}

.next-button-wrap {
  padding-top: 35px;
}

.website-field-label {
  flex: 1;
}

.label .arrow {
  width: 40px;
}

.field-map-row.label {
  padding-bottom: 0;
  font-size: 16px;
  font-weight: bold;
}
</style>
