<script lang="ts" setup>
import TopBar from "@/components/TopBar.vue";
import { useWebsiteStore } from "@/stores/website";
import { NButton, NGi, NGrid, NSkeleton, NSpace, useMessage } from "naive-ui";
import {computed, onMounted, onUnmounted, ref, watch} from "vue";
import Player from "xgplayer";
import { RouterLink, useRouter } from "vue-router";
import AcademyService from "@/service/AcademyService";
import useVideoHeight from "@/hooks/business/useVideoHeight";
import useOneSitePermission from "@/hooks/business/useOneSitePermission";

const websiteState = useWebsiteStore();
const message = useMessage();
useOneSitePermission('营销学堂')

/**
 * config
 */
interface Props {
  courseId: string;
  lessonId: string;
}

const props = defineProps<Props>();

/**
 * State
 */
const loadingForLessonDetail = ref(true);
const loadingForCourseDetail = ref(true);
const loadingForSwitching = ref(false);
const lessonDetail: any = ref({});
const courseDetail: any = ref({});
const videoDomRef: any = ref<null>(null);
const { videoHeight } = useVideoHeight(videoDomRef);
const playOjbRef = ref<Player | null>(null);
const router = useRouter();
const prevAndNextLink = computed(() => {
  const defaultResult = {
    prevLink: '',
    nextLink: ''
  }
  if (loadingForCourseDetail.value) return defaultResult;
  const lessonList = [...courseDetail.value.lessonList];
  const currentLessonId = props.lessonId;
  for (let i = 0; i < lessonList.length; i++) {
    const currentItem = lessonList[i];
    if (String(currentItem.lessonId) === currentLessonId) {
      if (i - 1 >= 0) {
        const prevItem = lessonList[i - 1];
        defaultResult.prevLink = `/sacademy/${props.courseId}/${prevItem.lessonId}`;
      }
      if (i + 1 < lessonList.length) {
        const nextItem = lessonList[i + 1];
        defaultResult.nextLink = `/sacademy/${props.courseId}/${nextItem.lessonId}`;
      }
      return defaultResult
    }
  }
  return defaultResult;
});

/**
 * Methods
 */
const fetchLessonDetail = async () => {
  loadingForLessonDetail.value = true;
  try {
    const result = await AcademyService.getLessonDetail(
      props.courseId,
      props.lessonId
    );
    lessonDetail.value = result;
  } catch (e: any) {
    window.$message?.error(e.message);
  }
  loadingForLessonDetail.value = false;
};

const fetchCourseDetail = async () => {
  loadingForCourseDetail.value = true;
  try {
    const result = await AcademyService.getCourseData(props.courseId);
    courseDetail.value = result;
  } catch (e: any) {
    window.$message?.error(e.message);
  }
  loadingForCourseDetail.value = false;
};

const loadVideo = () => {
  const coverImg = lessonDetail.value.coverImg
  const videoLink = lessonDetail.value.videoLink
  if (playOjbRef.value === null) {
    playOjbRef.value = new Player({
      id: "mse",
      lang: "zh-cn",
      url: videoLink,
      playbackRate: [1, 1.25, 1.5, 2],
      fluid: true,
      playsinline: true,
      poster: coverImg,
      // pip: true,
      defaultPlaybackRate: 1,
      videoInit: true,
      width: "100%",
      height: "0px",
      fitVideoSize: "auto",
    });
  } else {
    playOjbRef.value.src = videoLink;
    // @ts-ignore
    playOjbRef.value.poster = coverImg;
  }
};

function destroyXgPlayer() {
  playOjbRef.value?.destroy();
}

const onLessonSwitch = async () => {
  loadingForSwitching.value = true;
  loadingForLessonDetail.value = true;
  if (playOjbRef.value) {
    playOjbRef.value.pause()
  }
  try {
    const result = await AcademyService.getLessonDetail(
      props.courseId,
      props.lessonId
    );
    lessonDetail.value = result;
    loadVideo();
  } catch (e: any) {
    window.$message?.error(e.message);
  }
  loadingForLessonDetail.value = false;
  loadingForSwitching.value = false;
};

/**
 * Hooks
 */

onMounted(async () => {
  try {
    await fetchLessonDetail();
    loadVideo();
    await fetchCourseDetail();
  } catch (e) {
    window.$message?.error("请求出错");
    router.push("/sacademy");
  }
});

onUnmounted(() => {
  destroyXgPlayer();
});

watch(
  () => props.lessonId,
  () => {
    console.log("props.lessonId", props.lessonId);
    onLessonSwitch();
  }
);
</script>

<template>
  <div class="main-container">
    <top-bar use-brand-title custom-title="超新星营销学堂" />
    <perfect-scrollbar>
      <div class="learning-center-wrap">
        <div class="sn-player-wrap">
          <div class="container" ref="videoDomRef" :style="`min-height: ${videoHeight}`">
            <template v-if="loadingForLessonDetail">
              <n-skeleton width="100%" :height="videoHeight" />
            </template>
            <div id="mse" v-show="loadingForSwitching === false"></div>
          </div>
        </div>
        <div class="main-section-wrap">
          <div class="container">
            <div class="lesson-info-wrap">
              <!--                <n-skeleton text repeat="3" width="100%" v-if="loadingForLessonDetail" class="lesson-info-wrap" />-->
              <div class="info-wrap">
                <div class="lesson-name">
                  <n-skeleton
                    text
                    width="200px"
                    v-if="loadingForLessonDetail"
                  />
                  <template v-else>
                    {{ lessonDetail.lessonTitle }}
                  </template>
                </div>
                <div class="source-name">
                  <n-skeleton text v-if="loadingForCourseDetail" />
                  <template v-else>
                    <router-link :to="`/sacademy/${courseDetail.id}`">{{
                      courseDetail.title
                    }}</router-link>
                  </template>
                </div>
              </div>
              <div class="action-wrap">
                <n-skeleton text width="100px" v-if="loadingForCourseDetail" />
                <n-space v-else>
                  <router-link
                    :to="prevAndNextLink.prevLink"
                    v-if="prevAndNextLink.prevLink !== ''"
                  >
                    <n-button
                      :disabled="loadingForSwitching"
                      type="primary"
                      size="small"
                      >上一课</n-button
                    >
                  </router-link>
                  <router-link
                    :to="prevAndNextLink.nextLink"
                    v-if="prevAndNextLink.nextLink !== ''"
                  >
                    <n-button
                      :disabled="loadingForSwitching"
                      type="primary"
                      size="small"
                      >下一课</n-button
                    >
                  </router-link>
                </n-space>
              </div>
            </div>

            <div class="lesson-detail-wrap">
              <n-grid :cols="12" :x-gap="20">
                <n-gi :span="9">
                  <div class="lesson-notes">
                    <div class="section-title">本课重点</div>
                    <div class="section-detail">
                      <n-skeleton
                        text
                        :repeat="8"
                        v-if="loadingForLessonDetail"
                      />
                      <div
                        v-else
                        class="renderHtml"
                        v-html="lessonDetail.lessonNotes"
                      ></div>
                    </div>
                  </div>
                </n-gi>
                <n-gi :span="3">
                  <div class="course-list">
                    <div class="section-title">视频列表</div>
                    <div class="section-detail" style="padding: 0">
                      <template v-if="loadingForCourseDetail">
                        <div style="padding: 20px">
                          <n-skeleton text :repeat="8" />
                        </div>
                      </template>
                      <n-list
                        v-else
                        hoverable
                        clickable
                        class="lesson-list-wrap"
                      >
                        <router-link
                          class="lesson-item"
                          v-for="item in courseDetail.lessonList"
                          :key="item.lessonId"
                          :class="
                            String(item.lessonId) === props.lessonId
                              ? 'active'
                              : ''
                          "
                          :to="`/sacademy/${props.courseId}/${item.lessonId}`"
                        >
                          {{ item.title }}
                        </router-link>
                      </n-list>
                    </div>
                  </div>
                </n-gi>
              </n-grid>
            </div>
          </div>
        </div>
      </div>
    </perfect-scrollbar>
  </div>
</template>

<style lang="scss" scoped>
.lesson-list-wrap {
}

.lesson-item {
  display: block;
  padding: 15px 10px;
  border-bottom: 1px solid #e5e7eb;
  transition: all 0.3s;

  &:last-child {
    border-bottom: 0;
  }

  &:hover,
  &.active {
    background-color: #f2f7ff;
  }
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 10px;
  box-sizing: content-box;
}

.sn-player-wrap {
  background-color: #111827;
}

.learning-center-wrap {
  background-color: #f4f6fa;
}

.source-name {
  font-size: 16px;
  margin-top: 5px;
}

.lesson-info-wrap {
  margin: 20px 0;
  //border: 1px solid #e5e7eb;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fcfdff;
  border-radius: 5px;
}

.lesson-detail-wrap {
  padding-bottom: 50px;
}

.lesson-notes,
.course-list {
  //background-color: white;
  //border: 1px solid #e5e7eb;
  background: #fcfdff;
  border-radius: 5px;
}

.section-title {
  font-size: 16px;
  padding: 20px;
  border-bottom: 1px solid #e5e7eb;
  line-height: 1;
}

.section-detail {
  padding: 20px;
}
</style>
