<script setup lang="ts">
import { useCreateStore } from '@/stores/create';
import { computed, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { NInput, NInputGroup, NInputGroupLabel } from 'naive-ui';
import UserService from '@/service/userService';
import {getShortUUID} from "@/utils";


const router = useRouter()
const route = useRoute()
const createState = useCreateStore()

const isDomainValid = ref(false)
const checkingDomain = ref(false)
const domainErrorText = ref("")

const isSiteInfoOk = computed(() => {
    // todo: need to validate domain
    return createState.brandName !== "" && createState.domain !== "" && isDomainValid.value === true && domainErrorText.value === ""
})
const onSiteInfoNextBtnClick = () => {
    // todo: validation here
    if (!isSiteInfoOk.value) return
    router.push({
        name: "createStep",
        params: {
            step: 3
        }
    })
}

const generateRandomDomain = () => {
  const uuid = getShortUUID()
  createState.setDomain(uuid.toLowerCase())
  onDomainUpdate()
}

const throttle = (fn: any, timing: any) => {
    let timer: any = null

    return function(arg: any) {
        if (timer !== null) {
            window.clearTimeout(timer)
        }
        timer = window.setTimeout(() => {
            fn.call(null, ...arguments)
        }, timing)
    }
}

const checkDomainError = throttle(async (domain: string) => {
    if (domain === "") {
        isDomainValid.value = false
        domainErrorText.value = ''
        checkingDomain.value = false
      return
    }
    // validate format
    if (!/^[a-z0-9]{3,16}$/.test(domain)) {
        isDomainValid.value = false
        domainErrorText.value = '域名格式错误'
        checkingDomain.value = false
      return
    }
    // validate taken status
    checkingDomain.value = true
    const data = await UserService.checkDomain(domain)
    checkingDomain.value = false
    if (!data.is_success) {
        isDomainValid.value = false
        domainErrorText.value = '域名校验出错'
        checkingDomain.value = false
      return
    }
    if (data.data.is_taken) {
        isDomainValid.value = false
        domainErrorText.value = '域名已被注册'
        checkingDomain.value = false
      return
    }
    // user not updated domain during checking
    if (domain === createState.domain) {
      isDomainValid.value = true
      domainErrorText.value = ''
    }
}, 800)

const onDomainUpdate = () => {
    console.log('domain updated', createState.domain)
    const newDomain = createState.domain
    isDomainValid.value = false
    if (newDomain === "") {
        isDomainValid.value = false
        checkingDomain.value = false
        domainErrorText.value = ''
        return
    }
    // validate format
    if (!/^[a-z0-9]{3,16}$/.test(newDomain)) {
        console.log('格式错误')
        isDomainValid.value = false
        checkingDomain.value = false
        domainErrorText.value = '域名格式错误'
        return
    }
    checkDomainError(newDomain)
}

onMounted(() => {
    const template_id = route.query.template_id
    if (template_id) {
        createState.setSelectedTemplateId(Number(template_id))
    }
    // check domain valid on mount
    checkDomainError(createState.domain)
})

</script>

<template>
    <div class="step step-site-info" v-show="createState.currentStep === 2">
        <h2 class="new-site-info">新站点信息</h2>
        <div class="new-site-form-wrap">
            <div class="form-row">
                <div class="form-left">
                    <h3 class="form-input-label">您的企业/品牌名</h3>
                    <div class="form-input-wrap brand">
                        <n-input
                                v-model:value="createState.brandName"
                                size="large"
                                type="text"
                                :round="false"
                                placeholder="企业/品牌名"
                        />
                    </div>
                </div>
                <div class="form-right">
                    <h3 class="form-input-label">站点域名</h3>
                    <div class="form-input-wrap domain">
                        <div class="domain-input-wrap">


                            <div class="input-wrap">

                              <n-input-group size="large" round>
                                <n-input-group-label size="large" round>https://</n-input-group-label>
                                <n-input
                                    round
                                    size="large"
                                    :style="{ width: '80%' }"
                                    v-model:value="createState.domain"
                                    type="text"
                                    :status="createState.domain === '' ? 'success' : (isDomainValid ? 'success' : 'error')"
                                    placeholder="临时域名，后续可更改"
                                    @keyup="onDomainUpdate"
                                    :loading="checkingDomain"
                                />
                                <n-input-group-label size="large" round>.msupernova.com</n-input-group-label>
                                <n-button text size="large" @click="generateRandomDomain" style="padding-left: 10px; margin-left:10px">生成随机</n-button>
                              </n-input-group>

<!--                                <n-input-->
<!--                                v-model:value="createState.domain"-->
<!--                                type="text"-->
<!--                                :status="createState.domain === '' ? 'success' : (isDomainValid ? 'success' : 'error')"-->
<!--                                placeholder="域名"-->
<!--                                @keyup="onDomainUpdate"-->
<!--                                :loading="checkingDomain"-->
<!--                            />-->
                            </div>
                            <div class="msg-wrap">
                                <span v-show="domainErrorText !== '' && checkingDomain === false">{{domainErrorText}}</span>
                            </div>
                        </div>
<!--                        <span class="post-fix">.msupernova.com</span>-->
                    </div>
                </div>
            </div>
            <div class="form-btn">
              <n-button type="primary" size="large" block :disabled="!isSiteInfoOk" @click="onSiteInfoNextBtnClick">
                下一步
              </n-button>
<!--                <div-->
<!--                    class="complete-info btn next pointer"-->
<!--                    :class="isSiteInfoOk ? '' : 'disabled'"-->
<!--                    @click="onSiteInfoNextBtnClick"-->
<!--                >下一步</div>-->
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
.step-site-info {
    .new-site-info {
        font-weight: 600;
        font-size: 30px;
        color: #333;
    }
    .form-row {
        display: flex;
        max-width: 950px;
    }
    .form-input-label {
        font-size: 16px;
        margin-bottom: 20px;
        color: #333;
    }
    .new-site-info {
        margin-bottom: 45px;
    }

    .form-left {
        max-width: 400px;
        width: 100%;
    }

    .form-input-wrap {
        position: relative;

        &.brand {
            max-width: 200px;
        }
        .n-input {
            // all: unset;
            border: none;
            // background-image: none;
            // -webkit-box-shadow: none;
            // -moz-box-shadow: none;
            box-shadow: none;
            background: rgba(239, 243, 245, 0.7);
            border-radius: 10px;
            font-size: 12px;
            // padding: 10px 15px;
            color: #262c39;
        }

        &.domain {
            display: flex;
            .msg-wrap {
                height: 19px;
                padding: 2px;
                display: flex;
                span {
                    font-size: 10px;
                    color: #d03050;
                }
            }
        }
        .post-fix {
            font-size: 20px;
            color: #333;
        }
        input {
            all: unset;
            border: none;
            background-image: none;
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;
            background: rgba(239, 243, 245, 0.7);
            border-radius: 10px;
            font-size: 12px;
            padding: 10px 15px;
            color: #262c39;
        }
    }
    .complete-info.btn.next.pointer {
        background: #3e8bff;
        border-radius: 10px;
        text-align: center;
        color: #ffffff;
        font-size: 16px;
        padding: 13px;
    }
    .form-btn {
        max-width: 380px;
        width: 100%;
        margin-top: 60px;
    }
}
</style>
