<template>
  <div class="no-init-wrap">
    <n-empty size="large" description="暂无对接CRM">
      <template #extra>
        <n-button type="primary" @click="onInitClick" size="large">立即对接</n-button>
      </template>
    </n-empty>
  </div>
</template>

<script lang="ts" setup>

import {useRouter} from "vue-router";

const router = useRouter()

const onInitClick = () => {
  router.push({
    name: "CrmNew",
  })
}

</script>
<style lang="scss" scoped>

.no-init-wrap {
  background-color: white;
  padding: 50px;
  border-radius: 10px;
}

</style>
