import { acceptHMRUpdate, defineStore } from 'pinia'
import { ref } from 'vue'

export const useNewsStore = defineStore('news', () => {

    /**
     * Reative Objects
     */
    const news = ref<any[]>([])
    const loading = ref(true)

    /**
     * Actions
     */
    const setNews = (payload: any[]) => {
        news.value = payload
        loading.value = false
    }

    /**
     * Expose State API
     */
    return {
        loading,
        news,
        setNews
    }
})
