<script setup lang="ts">

import SiteService from '@/service/siteService';
import UserService from '@/service/userService';
import { useWebsiteStore } from '@/stores/website';
import { NDrawer, NDrawerContent, NInput, NInputGroup, NUpload, NButton, NModal, useMessage, NCollapse, NCollapseItem, NImageGroup, NSpace, NImage, NCard } from 'naive-ui';
import { computed, ref, watchEffect } from 'vue';
import { useRoute } from 'vue-router'

const websiteState = useWebsiteStore()
const message = useMessage()
const showDomainSetting = ref(false)
const customDomain = ref("") // 表单的
const validCustomDomain = ref("") // 点击检验之后，通过域名检查的域名
const showModal = ref(false)
const bindingCustomDomain = ref(false)
const route = useRoute()
const disableDetectBtn = computed(() => {
    return customDomain.value === websiteState.getCurrentSiteCustomDomain()
})
const disabledConfirmBtn = computed(() => {
    if (websiteState.getCurrentSiteCustomDomain() === customDomain.value) return true // domain not change, dont allow

    // todo: 校验域名，域名不对不给点
    return /^(?!\-)(?:[a-zA-Z\d\-]{0,62}[a-zA-Z\d]\.){1,126}(?!\d+)[a-zA-Z\d]{1,63}$/.test(customDomain.value) === false

    // return false // pass all condition, allow click
})

const bindCustomDomain = async () => {
    // todo: validate this is a domain first
    bindingCustomDomain.value = true
    try {
        await UserService.bindCustomDomain(websiteState.getCurrentSiteDefaultDomain(), customDomain.value, websiteState.currentWebsiteId)
        message.success("操作成功")
        websiteState.updateCustomDomainForCurrentSite(customDomain.value)
        // userState.user.name = nickName.value
    } catch (e: any) {
        message.error(e.message)
    }
    bindingCustomDomain.value = false
}

const iframeLink = computed(() => {
    return `https://app.closte.com/api/client/domainsiframewpultimo?apikey=Jf%3DmfR%2C4Z8kY3g%21%21MAJr619f55cf0ccc4cafc60abcad&domains=${websiteState.getCurrentSiteCustomDomain()}`
})

const handleDomainStatusClick = () => {
    if (websiteState.getCurrentSiteCustomDomain() === "") return
    showModal.value = true
}

watchEffect(() => {
    customDomain.value = websiteState.getCurrentSiteCustomDomain()
    const bindDomain = route.query.bindDomain
    if (Number(bindDomain) === 1) {
        showDomainSetting.value = true
    }
})

</script>

<template>
    <a href="#" @click.prevent="showDomainSetting = true" class="two bind-domain-wrap">
        <div class="icon-wrap">
            <div class="icon-bg"></div>
        </div>
        <h2 class="action-title">绑定域名</h2>
        <div class="action-des">轻松关联你的企业域名</div>
        <div class="arrow-wrap">

        </div>
    </a>

    <n-drawer v-model:show="showDomainSetting" :width="600" placement="right" class="domain-setting-drawer">
        <n-drawer-content>
            <perfect-scrollbar class="ps-domain-setting">
            <div class="drawer-inner">
                <div class="drawer-header">
                    <h2 class="drawer-title">域名绑定</h2>
                </div>
                <div class="drawer-main">
                    <div class="domain-step-wrap">
                        <div class="step-item">
                            <div class="step-label-wrap">
                                <div class="step-label">
                                    <span class="label-text">步骤一</span>
                                    <span class="triangle-right label-triangle"></span>
                                </div>
                                <div class="step-line"></div>
                            </div>
                            <div class="step-main-wrap">
                                <div class="step-main-inner">
                                    <div class="instruction">在您的域名解析处添加一条A记录，指向IP地址：<span class="multisite-ip">35.236.40.86</span>  示例如下:</div>
                                    <div class="example-wrap">
                                        <n-collapse arrow-placement="right">
                                            <n-collapse-item title="示例一：阿里云" name="1">
                                                <div class="example-inner-item">
                                                    <n-image-group>
                                                        <n-space>
                                                            <n-image
                                                                src="https://st-img-md.oss-cn-guangzhou.aliyuncs.com/20220506132231.png"
                                                            />
                                                        </n-space>
                                                    </n-image-group>
                                                </div>
                                            </n-collapse-item>
                                            <n-collapse-item title="示例二：Godaddy" name="2">
                                                <div class="example-inner-item">
                                                    <n-image-group>
                                                        <n-space>
                                                            <n-image
                                                                src="https://st-img-md.oss-cn-guangzhou.aliyuncs.com/20220506132314.png"
                                                            />
                                                        </n-space>
                                                    </n-image-group>
                                                </div>
                                            </n-collapse-item>
                                        </n-collapse>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="step-item">
                            <div class="step-label-wrap">
                                <div class="step-label">
                                    <span class="label-text">步骤二</span>
                                    <span class="triangle-right label-triangle"></span>
                                </div>
                                <div class="step-line"></div>
                            </div>
                            <div class="step-main-wrap">
                                <div class="step-main-inner">
                                    <div class="instruction">完成步骤一之后，在下方填入域名：</div>
                                    <div class="domain-wrap">
                                        <n-input-group>
                                            <n-input
                                                v-model:value="customDomain"
                                                type="text"
                                                placeholder="请输入域名"
                                            />
                                            <!-- <n-button :disabled="disableDetectBtn" type="primary" ghost>
                                                检测
                                            </n-button> -->
                                        </n-input-group>
                                        <n-button @click="bindCustomDomain" :loading="bindingCustomDomain" :disabled="disabledConfirmBtn" class="changeDomainBtn" type="primary" block size="large">
                                            确认
                                        </n-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="step-item">
                            <div class="step-label-wrap">
                                <div class="step-label">
                                    <span class="label-text">步骤三</span>
                                    <span class="triangle-right label-triangle"></span>
                                </div>
                                <div class="step-line"></div>
                            </div>
                            <div class="step-main-wrap">
                                <div class="step-main-inner">
                                    <div class="instruction">平台将自动生成 HTTPS 证书并重定向，预计在1小时内生效，期间您可检查
                                        <a v-if="websiteState.getCurrentSiteCustomDomain() !== ''" style="cursor: pointer;" @click.prevent="handleDomainStatusClick" class="domain-status">[域名状态]</a>
                                        <a v-else style="cursor: not-allowed;" class="domain-status">[域名状态]</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </perfect-scrollbar>
        </n-drawer-content>
    </n-drawer>
    <n-modal
     v-model:show="showModal">
        <!-- <n-card
        style="width: 600px;"
        title="模态框"
        :bordered="false"
        size="huge"
        role="dialog"
        aria-modal="true"
        >
         <h1>hihihi</h1>
        </n-card> -->
         <div>
            <!-- <h2>域名解析状态</h2> -->
            <iframe frameborder="0" hspace="0" allowtransparency="true" :src="iframeLink" id="TB_iframeContent" name="TB_iframeContent33" style="width:829px;height:712px;">这个功能需要iframe的支持。您可能禁止了iframe的显示，或您的浏览器不支持此功能。</iframe>
         </div>
          </n-modal>
</template>

<style scoped lang="scss">
@import '@/styles/global-vars.scss';


:deep(.n-drawer-body) {
    background-color: $color4;
}

// for better scrolling
.n-drawer-body-content-wrapper {
    padding: 0!important;
}
.ps.ps-domain-setting {
    height: 100%;
}

.domain-step-wrap {
    .changeDomainBtn {
        margin-top: 5px;
        border-radius: 5px;
    }
    .domain-status {
        color: #3E8BFF;
        text-decoration: none;
        font-weight: 600;
    }
    .example-wrap {
        padding-top: 20px;
    }
    .multisite-ip {
        color: #2e62b1;
    }
    .instruction {
        font-size: 14px;
        color: #333;
    }
    .step-main-wrap {
        padding-left: 115px;
        padding-top: 10px;
    }
    .step-main-inner {
        background: #FCFDFF;
        box-shadow: 0px 15px 30px rgb(60 137 255 / 5%);
        border-radius: 20px;
        padding: 40px 50px;
    }
    .step-label-wrap {
        display: flex;
        align-items: center;
    }
    .step-line {
        width: 100%;
        height: 2px;
        background: rgba(62, 139, 255, 0.15);
    }
    .step-label {
        display: flex;
        margin-right: 20px;
        .label-text {
            font-weight: 600;
            font-size: 16px;
            background: #3E8BFF;
            color: white;
            height: 40px;
            padding: 10px;
            box-sizing: border-box;
            display: inline-block;
            line-height: 20px;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            width: 75px;
        }
    }
    // geneeated here https://triangle.designyourcode.io/
    .triangle-right {
        display: inline-block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 20px 0 20px 20px;
        border-color: transparent transparent transparent #3E8BFF;
    }
}

.drawer-header {
    padding-top: 10px;
    text-align: center;
    margin-bottom: 20px;
    .drawer-title {
        font-size: 25px;
        font-weight: 600;
    }
    .drawer-des {
        color: #999999;
        font-size: 18px;
    }
    .row-label {
        color: #333333;
        font-weight: 600;
    }
}

.two.bind-domain-wrap {
    background-size: cover;
    background-image: url(https://sn-platform-app.oss-cn-guangzhou.aliyuncs.com/static/%E5%9F%9F%E5%90%8D%E7%BB%91%E5%AE%9A%E8%83%8C%E6%99%AF%E8%89%B2%E5%9D%97.png);
}
.bind-domain-wrap,
.site-management-wrap {
    .icon-bg {
        background-image: url(https://sn-platform-app.oss-cn-guangzhou.aliyuncs.com/static/%E5%9F%9F%E5%90%8D%E7%BB%91%E5%AE%9A.png)
    }
    &:hover {
        background-size: cover;
        background-repeat: no-repeat;
        background-image: url('https://sn-platform-app.oss-cn-guangzhou.aliyuncs.com/static/%E7%AB%99%E7%82%B9%E7%AE%A1%E7%90%86%E8%83%8C%E6%99%AF%E8%89%B2%E5%9D%97.png');

        .action-title {
            color: #fcfdff;
        }
        .action-des {
            color: #fcfdffcc;
        }
        .arrow-img-wrap {
            background: #fcfdff;
        }
        .arrow-wrap {
            // background: rgba(252, 253, 255, 0.2);
            background-image: url(https://sn-platform-app.oss-cn-guangzhou.aliyuncs.com/static/%E4%BA%86%E8%A7%A3%E6%9B%B4%E5%A4%9A1-%E7%99%BD.png);
        }
        .icon-bg {
            background-image: url(https://sn-platform-app.oss-cn-guangzhou.aliyuncs.com/static/bind-domain-light.png)
        }
    }
    transition: all 0.3s;
    padding: 50px;
    position: relative;
    .icon-wrap {
        background: rgba(61, 115, 212, 0.08);
        border-radius: 30px;
        width: 80px;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .action-title {
        font-weight: 600;
        font-size: 30px;
        color: #333333;
        margin-top: 30px;
        margin-bottom: 10px;
    }
    .action-des {
        font-size: 16px;
        color: rgba(51, 51, 51, 0.4);
    }
    .arrow-wrap {
        background-image: url(https://sn-platform-app.oss-cn-guangzhou.aliyuncs.com/static/%E4%BA%86%E8%A7%A3%E6%9B%B4%E5%A4%9A2-%E8%93%9D.png);
        width: 52px;
        height: 52px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        position: absolute;
        right: 30px;
        bottom: 40px;
    }
    .arrow-img-wrap {
        width: 36px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #408cff;
        border-radius: 50%;
    }
}
.icon-bg {
    width: 35px;
    height: 35px;
    background-size: cover;
    background-repeat: no-repeat;
}
</style>
