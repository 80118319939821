import { watch } from "vue";
import {useElementSize, useStorage} from "@vueuse/core";

export default function useVideoHeight(videoDomRef: any) {

  const { height } = useElementSize(videoDomRef)
  const videoHeight = useStorage("videoHeight", '500px');

  watch(height, async (newHeight, oldHeight) => {
      if (newHeight !== oldHeight && newHeight !== 0) {
        videoHeight.value = `${newHeight}px`
      }
})

  return {
    videoHeight
  };
}
