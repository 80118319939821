<script lang="ts" setup>
import TopBar from "@/components/TopBar.vue";
import { useWebsiteStore } from "@/stores/website";
import { useMessage, NGrid, NGi, NCheckboxGroup, NCheckbox, NSpace, NPagination, NSpin, NEmpty, NCard, NIcon, NPopover, NButton, NCarousel, NTag, NTabs, NTabPane, NList, NSkeleton } from "naive-ui";
const websiteState = useWebsiteStore()
const message = useMessage()
import {onMounted, ref} from "vue";
import Player from 'xgplayer';
import {RouterLink, useRouter} from 'vue-router';
import AcademyService from '@/service/AcademyService'
import useOneSitePermission from "@/hooks/business/useOneSitePermission";
useOneSitePermission('营销学堂')

/**
 * Config
 */
interface Props {
  /** 是否加载 */
  courseId: string;
}
const props =defineProps<Props>();


/**
 * State
 */
const loadingCourseData = ref(false);
const courseData: any = ref();

/**
 * Methods
 */
const fetchData = async () => {
  loadingCourseData.value = true
  try {
    const result = await AcademyService.getCourseData(props.courseId)
    courseData.value = result
  } catch (e: any) {
    window.$message?.error(e.message)
    router.push('/sacademy') // go back to course list page when error
  }
  loadingCourseData.value = false
}

/**
 * State
 */

/**
 * Hooks
 */
const router = useRouter();
onMounted(() => {
  // todo: validate courseId
  if (props.courseId === '') {
    window.$message?.error('非法请求')
    router.push('/sacademy') // go back to course list page when error
    return
  }
  fetchData()
})
</script>

<template>
    <div class="main-container">
        <top-bar use-brand-title custom-title="超新星营销学堂" />
      <perfect-scrollbar>
        <div class="learning-center-wrap" style="padding-bottom: 100px;">
          <div class="container">
            <div class="course-brief-wrap">
              <template v-if="loadingCourseData">
                <n-skeleton width="100%" height="300px" />
              </template>
              <n-grid v-else cols="12" x-gap="20">
                <n-gi span="4">
                  <div class="cover-wrap">
                    <img :src="courseData.cover" class="cover">
                  </div>
                </n-gi>
                <n-gi span="8">
                  <div class="info-wrap">
                    <h2 class="course-title">
                      <n-tag type="info">
                        系统课
                      </n-tag>
                      <span style="margin-left: 5px;">{{courseData.title}}</span>
                    </h2>
                    <div class="course-stats">
                      <span class="course-num">共 {{ courseData.lessonList.length }} 节课时</span>
                    </div>
                    <div class="course-des">{{courseData.brief}}</div>
                    <div class="cta-wrap">
                      <router-link :to="`/sacademy/${courseData.id}/${courseData.lessonList[0].lessonId}`">
                        <n-button type="primary" size="large">立即学习</n-button>
                      </router-link>
                    </div>
                  </div>
                </n-gi>
              </n-grid>
            </div>
            <div class="course-detail-wrap">
              <n-tabs size="large" default-value="detail" type="line" animated>
                <n-tab-pane name="detail" tab="详情">
                  <template v-if="loadingCourseData">
                    <n-skeleton text width="100%" :repeat="8" />
                  </template>
                  <div v-else class="detail-html" v-html="courseData.detailDes"></div>
                </n-tab-pane>
                <n-tab-pane name="lessonList" tab="目录">
                  <template v-if="loadingCourseData">
                    <n-skeleton text width="100%" :repeat="8" />
                  </template>
                  <n-list v-else hoverable clickable class="lesson-list-wrap">
                    <router-link class="lesson-item" v-for="item in courseData.lessonList" :key="item.lessonId" :to="`/sacademy/${courseData.id}/${item.lessonId}`" >
                      {{ item.title }}
                    </router-link>
                  </n-list>
                </n-tab-pane>
              </n-tabs>
            </div>
          </div>
        </div>
      </perfect-scrollbar>
    </div>
</template>

<style lang="scss" scoped>
.course-detail-wrap {
  padding-top: 6px!important;
}
.cover-wrap {
  border-radius: 5px;
  overflow: hidden;
}
.course-brief-wrap, .course-detail-wrap {
  padding: 20px;
  //background-color: white;
  //border: 1px solid #e5e7eb;
  background: #fcfdff;
  border-radius: 10px;
}
.course-brief-wrap {
  margin-bottom: 30px;
}
.learning-center-wrap {
  padding-top: 20px;
  .title, .courses-wrap {
    font-size: 16px;
    padding: 20px;
    border-bottom: 1px solid #e5e7eb;
    line-height: 1;
  }
}
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 10px;
  box-sizing: content-box;
}
.info-wrap {
  height: 100%;
  display: flex;
  flex-direction: column;
  .course-title {
    font-size: 22px;
  }
  .course-stats {
    padding: 10px 0;
    .course-num {
      font-size: 12px;
      color: gray;
    }
  }
  .course-des {
    padding-bottom: 15px;
    color: gray;
    flex: 1;
  }
}
.course-title {
  display: flex;
  align-items: center;
}
.lesson-list-wrap {
  //border: 1px solid #e5e7eb;
}
.lesson-item {
  display: block;
  padding: 15px 10px;
  border-bottom: 1px solid #e5e7eb;
  transition: all .3s;
  &:last-child {
    //border-bottom: 0;
  }
  &:hover {
    background-color: #f2f7ff;
  }
}
</style>
