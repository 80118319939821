<script lang="ts" setup>
import {NSteps, NStep, NScrollbar} from "naive-ui";
import {computed, ref} from "vue";
import StepAuth from "@/views/crm/StepAuth.vue";
import StepFieldMap from "@/views/crm/StepFieldMap.vue";
import StepLast from "@/views/crm/StepLast.vue";
import {useCrmStore} from "@/stores/crm";

const crmStore = useCrmStore()

const currentStepComponent = computed(() => {
  switch (crmStore.currentStep) {
    case 1:
      return StepAuth
    case 2:
      return StepFieldMap
    case 3:
      return StepLast
    default:
      return StepAuth
  }
})

const contentRef = ref<(HTMLElement) | null>(null);
const scrollMaxHeight = computed(() => {
  if (!contentRef.value) return 500;
  return window.innerHeight - contentRef.value.getBoundingClientRect()?.top - 150;
})

</script>


<template>
  <div class="crm-new-wrap">
    <div class="steps-wrap">
        <n-steps :current="crmStore.currentStep" :status="'process'">
          <n-step title="授权访问CRM" />
          <n-step title="配置字段映射" />
          <n-step title="对接完成" />
        </n-steps>
    </div>
    <div class="main-wrap" ref="contentRef">
      <n-scrollbar :style="`max-height: ${scrollMaxHeight}px`">
        <component :is="currentStepComponent"></component>
      </n-scrollbar>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.crm-new-wrap {
    background-color: white;
    padding: 50px;
    border-radius: 10px;
}
.steps-wrap {
  position: relative;
  left: 125px;
}
.steps-wrap {
  padding-bottom: 30px;
}
</style>
