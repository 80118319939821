<script lang="ts" setup>

import {computed, onMounted, ref} from "vue";
import {useCrmStore} from "@/stores/crm";
import CrmService, {ICrmField, IMappingConfig, IWebsiteField} from "@/service/CrmService";
import {useMessage} from "naive-ui";
import {useLoading} from "@/hooks/common";
import FieldMapping from "@/views/crm/FieldMapping.vue";

const message = useMessage()
const settingUpIntegration = ref(false)

const setupIntegration = async () => {
  settingUpIntegration.value = true
  try {
      await CrmService.setupIntegration(crmStore.initSiteId, crmStore.initCrmTenant, crmStore.initPlatformToken, crmStore.configModel, crmStore.initCrmTenantCompanyName)
      message.success('配置成功')
      crmStore.currentStep = 3
  } catch (e: any) {
    message.error(e.message)
  }
  settingUpIntegration.value = false
}

const crmStore = useCrmStore()
const nextBtnClick = () => {
  setupIntegration()
}

</script>

<template>
  <div class="fieldMapping-wrap">
    <FieldMapping />
    <div class="next-button-wrap">
      <n-button v-if="crmStore.configModel.length > 0" @click="nextBtnClick" type="primary" size="large" :loading="settingUpIntegration">下一步</n-button>
    </div>
  </div>
</template>

<style lang="scss" scoped>

.field-map-row {
  display: flex;
  align-items: center;
  padding: 10px 0;
}

.arrow {
  padding: 0 10px;
  width: 60px;
  text-align: center;
}

.fieldMapping-wrap {
  max-width: 800px;
  margin: 0 auto;
  padding-top: 20px;
}

.field-loading {
  text-align: center;
  padding-top: 50px;
}

.loading-title {
  font-size: 18px;
  margin-top: 18px;
  color: #202225;
  font-weight: 500;
}

.next-button-wrap {
  padding-top: 35px;
}

.website-field-label {
  flex: 1;
}

.label .arrow {
  width: 40px;
}

.field-map-row.label {
  padding-bottom: 0;
  font-size: 16px;
  font-weight: bold;
}
</style>
