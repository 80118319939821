import { getWpBase } from "@/config"
import HttpService from "./httpService"

export default class UserService {
    private static instance: UserService
    public static getInstance() {
        if (UserService.instance === undefined) {
            UserService.instance = new UserService()
        }
        return UserService.instance
    }
    public static async resetPass(phone: string, phoneCode: string, password: string) {
        const url = "app/user/reset"

        const data: any = {
            phone,
            phoneCode,
            password
        }

        const res = await HttpService.post(url, data)
        if (res.code !== 0) {
            throw new Error(res.msg) 
        }
        return res.data
    }
    public static async registerUser(phone: string, phoneCode: string, password: string, nickname: string|null, email: string|null, unionId: string|null, avatarUrl: string |null, token: string | null) {
        const url = "app/user/register"

        const data: any = {
            phone,
            phoneCode,
            password,
            nickname,
            email,
            unionId,
            avatarUrl,
            invitationToken: token
        }

        const res = await HttpService.post(url, data)
        if (res.code !== 0) {
            throw new Error(res.msg) 
        }
        return res.data
    }
    public static async getSmsCode(phone: string) {
        const url = `app/user/code/${phone}`
        const res = await HttpService.get(url)
        if (res.code !== 0) {
            throw new Error(res.msg) 
        }
        return res.data
    }
    public static async login(user: string, pass: string) {
        const url = "app/user/login"
        const res = await HttpService.post(url, {
            "password": pass,
            "phone": user
        })
        if (res.code !== 0) {
            throw new Error(res.msg) 
        }
        const token = res.token
        window.localStorage.setItem("token", token)
        // save token to local storage
        return token
    }
    public static async updateNickName(nickName: string) {
        const url = "app/user/updateUserNickName"
        const res = await HttpService.post(url, {
            "newNickName": nickName,
        })
        if (res.code !== 0) {
            throw new Error(res.msg) 
        }
    }
    public static loginByToken(token: string) {
        window.localStorage.setItem("token", token)
    }
    public static async getUserInfo() {
        const url = "app/userInfo"
        const res = await HttpService.get(url)
        if (res.code !== 0) {
            throw new Error(res.msg) 
        }
        return res.user
    }
    public static async getMyWebsites() {
        const url = "app/user/websites"
        const res = await HttpService.get(url)
        if (res.code !== 0) {
            throw new Error(res.msg) 
        }
        res.data = res.data.filter((item: any) => item.domain !== 'msupernova.com')
        return res.data
    }
    public static async getScanStatusByRid(rid: string) {
        const url = `app/user/queryWx?rid=${rid}`
        const res = await HttpService.get(url)
        if (res.code !== 0) {
            throw new Error(res.msg) 
        }
        return res.data
    }
    public static async getBase64ForAction(rid: string, action: 'bind'|'unbind') {
        const url = `app/user/generateCode?rid=${rid}&wechatActionType=${action}`
        const res = await HttpService.post(url, {})
        if (res.code !== 0) {
            throw new Error(res.msg) 
        }
        const base64 = res.data
        // save token to local storage
        return base64
    }
    public static async getOneTimeLoginLink(siteId: string) {
        const url = `app/user/wplogin/${siteId}/`
        const res = await HttpService.get(url)
        if (res.code !== 0) {
            throw new Error(res.msg) 
        }
        return res.data
    }
    public static async bindCustomDomain(defaultDomain: string, customDomain: string, currentSiteId: number) {
        const url = `app/user/${currentSiteId}/bindCustomDomain`
        const res = await HttpService.post(url, {
            defaultDomain,
            customDomain
        })
        if (res.code !== 0) {
            throw new Error(res.msg) 
        }
        // no need to return anything when success
    }
    public static async checkDomain(domain: string) {
        const url = `${getWpBase()}wp-json/sn/v1/domain/${domain}`

        const res = await fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
          })
        .then(response => response.json())

        return res
    }
    public static async getNotifications() {
        const url = 'https://japi.msupernova.com/jeecg-boot/snpbiz/snNotification/all'

        const res = await fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
          })
        .then(response => response.json())

        if (res.success !== true) {
            throw new Error("DS: 获取系统通知出错") 
        }
        return res.result
    }
    public static async getNews() {
        const url = 'https://japi.msupernova.com/jeecg-boot/snpbiz/snNews/all'

        const res = await fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
          })
        .then(response => response.json())

        if (res.success !== true) {
            throw new Error("DS: 获取 news 出错") 
        }
        return res.result
    }
    public static async getLogsBySite(currentSiteId: number, page: number = 1, limit: number = 10) {
        const url = `app/user/${currentSiteId}/listLogs?page=${page}&limit=${limit}`
        const res = await HttpService.get(url)
        if (res.code !== 0) {
            throw new Error(res.msg) 
        }
        return res.page
        /** data shape 
         * 
        "totalCount": 4,
        "pageSize": 2,
        "totalPage": 2,
        "currPage": 2,
        "list": [{
            "id": 38,
            "username": "Stanley",
            "operation": "列出用户",
            "method": "io.renren.modules.app.controller.UserController.listUserForSite()",
            "params": null,
            "wblogId": "38",
            "message": "xxx 正在列出用户",
            "snUid": 44,
            "time": 548,
            "ip": "0:0:0:0:0:0:0:1",
            "createDate": "2022-02-28 15:37:13"
        }]
        */
    }
    public static async leadsPlatform(currentSiteId: number, page: number = 1, pageSize: number = 20, range: [number, number] = [1577808000000, new Date().getTime()]) {
        const date1 = new Date(range[0])
        const date2 = new Date(range[1])
        const year = date1.getFullYear()
        const byear = date2.getFullYear()
        const month = date1.getMonth() + 1
        const bmonth = date2.getMonth() + 1
        const day = date1.getDate()
        const bday = date2.getDate()
        const url = `app/user/leadsPlatform?blogId=${currentSiteId}&page=${page}&pageSize=${pageSize}&year=${year}&month=${month}&day=${day}&byear=${byear}&bmonth=${bmonth}&bday=${bday}`
        const res = await HttpService.post(url, {})

        const { platformData, data } = res

        // conbine platform if needed
        if (platformData) {
            platformData.map((item: any) => {
               const {remoteId, isQuality, notes} = item
               const foundIndex = data.findIndex((itemInData: any) => itemInData.ID == remoteId)
                data[foundIndex]['isQuality'] = isQuality
                data[foundIndex]['notes'] = notes
            })

            res['data'] = data
            delete res['platformData']
        }
        return res
    }

    public static async leadsMetaPlatform(currentSiteId: number) {
        const url = `app/user/leadsMetaPlatform?blogId=${currentSiteId}`
        const res = await HttpService.post(url, {})
        return res
    }

    public static async toggleHighQuality(leadId: number, blogId: number) {
        const url = `app/user/toggleHighQuality/${leadId}/${blogId}`
        const res = await HttpService.post(url, {})
        return res
    }

    public static async editLeadNotes(leadId: number, note: string, blogId: number) {
        const url = `app/user/editLeadNotes/${leadId}/${blogId}`
        const res = await HttpService.post(url, {
            notes: note
        })
        return res
    }

    public static async addHighQualityToIds(leadIds: number[], blogId: number) {
        const url = 'app/user/addHighQualityToIds'
        const res = await HttpService.post(url, {
            leadIds: leadIds,
            blogId: blogId
        })
        return res
    }

    public static async getMarketingData() {
        return new Promise((resolve, reject) => {
            window.setTimeout(() => resolve({
                title: '精品呈现精品呈现精',
                des: '追加谷歌套餐更有史诗级优惠噢让你的模版效益发挥到极致',
                ctaText: '立即浏览',
                ctaLink: 'https://www.baidu.com'
            }), 2000)
        })
    }
}
